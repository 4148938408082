import styled from '@emotion/styled';
import { Label } from '~/features/products/components/ProductCard/components/Label/Label';
import { breakpoints } from '~/theme';

export const StyledCampaignTimeframe = styled(Label)(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-end',
	flex: '1 0 auto',
	gridColumn: 'span 2',
	fontSize: theme.fontSizes.xs,
	color: theme.colors.grey50,

	[breakpoints.md]: {
		marginTop: theme.space['2'],
	},
}));
