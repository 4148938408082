import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { GtmVirtualPageViewEvent } from '~/features/tracking/model';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { usePage } from '~/templates/pages/hooks/usePage';

type HandleRouteChangeComplete = (asPath: string) => void;

let isInitiallyTracked = false;
let prevPageUrl: string;

export const useVirtualPageViewTracking = () => {
	const page = usePage();
	const router = useRouter();
	const handler = useRef<HandleRouteChangeComplete>();

	const handleRouteChangeComplete = useCallback<HandleRouteChangeComplete>(
		(asPath) => {
			// we want to exclude query params from the url before comparing,
			// in order to prevent same page tracking.
			const currentPageUrl = window.location.href.split('?')[0];

			if (prevPageUrl === currentPageUrl) {
				return;
			}

			pushToDataLayer<GtmVirtualPageViewEvent>({
				event: 'virtualPageview',
				pageUrl: window.location.href,
				pagePath: asPath,
				pageType: page.type,
			});

			prevPageUrl = currentPageUrl;
		},
		[page.type, prevPageUrl],
	);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		handler.current = handleRouteChangeComplete;
	}, [handleRouteChangeComplete]);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		const _handleRouteChangeComplete = (asPath: string) => {
			handler.current?.(asPath);
		};

		if (!isInitiallyTracked) {
			_handleRouteChangeComplete(router.asPath);
			isInitiallyTracked = true;
		}

		router.events.on('routeChangeComplete', _handleRouteChangeComplete);

		return () => {
			router.events.off('routeChangeComplete', _handleRouteChangeComplete);
		};
	}, []);
};
