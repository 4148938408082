import { IOrderDraftResponse, IShippingDetailsResponse } from '~/lib/data-contract';

export const orderDraftShippingAddressUpdate = (orderDraft: IOrderDraftResponse | undefined, response: IShippingDetailsResponse): IOrderDraftResponse | undefined => {
	if (!orderDraft?.data) {
		return;
	}
	return {
		...orderDraft,
		data: {
			...orderDraft.data,
			servicePointId: response.data.servicePointId,
			shippingAddress: response.data.address,
			billingAddressAsShipping: !response.data.address,
			shippingOptions: response.data.shippingOptions || [],
		},
		fieldErrors: {
			...orderDraft.fieldErrors,
			shippingAddress: {},
		},
	};
};
