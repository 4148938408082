import type { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';
import { Icon } from '../Icon/Icon';
import { StyledButton, StyledButtonContent } from './styled';

export type ButtonProps = {
	variant?: 'filled' | 'outline' | 'plain';
	shade?: 'dark' | 'light';
	translucent?: boolean;
	small?: boolean;
	disabled?: boolean;
	icon?: ReactNode;
	iconAlignment?: 'start' | 'end';
	textAlignment?: 'left' | 'center';
	iconLabel?: string;
	iconOffsetEdge?: number;

	children?: ReactNode;
	className?: string;
	onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

type PolymorphicButton = ForwardRefComponent<typeof StyledButton, ButtonProps>;

/**
 * Handles all the button styles across the site.
 *
 * The proper way to use as link is `as={Link}`. This hooks into the router and performance optimisations.
 */
export const Button = forwardRef(({
	as,
	variant = 'filled',
	shade = 'dark',
	icon,
	iconAlignment = 'end',
	textAlignment = 'center',
	iconLabel,
	iconOffsetEdge = 0,
	children,
	disabled,
	tabIndex,
	role,
	...restProps
}, ref) => {
	const asTagName = (as?.name ? as.name : as?.toString()) || '';
	const supportsDisabledAttr = ['button', 'input', 'select'].includes(asTagName);

	const ButtonIcon = (
		<Icon
			label={iconLabel}
			offset={{
				left: iconAlignment === 'start' ? iconOffsetEdge : undefined,
				right: iconAlignment === 'end' ? iconOffsetEdge : undefined,
			}}
			children={icon}
		/>
	);

	return (
		<StyledButton
			ref={ref}
			as={as}
			variant={variant}
			shade={shade}
			disabled={disabled}
			aria-disabled={disabled && !supportsDisabledAttr ? 'true' : undefined}
			tabIndex={disabled && !supportsDisabledAttr ? -1 : tabIndex}
			role={disabled && !supportsDisabledAttr ? 'button' : role}
			textAlignment={textAlignment}
			{...restProps}
		>
			<StyledButtonContent>
				{!!icon && iconAlignment === 'start' && ButtonIcon}
				{children}
				{!!icon && iconAlignment === 'end' && ButtonIcon}
			</StyledButtonContent>
		</StyledButton>
	);
}) as PolymorphicButton;
