import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - position rule in ifProp breaks TS. String value is no good apparently
export const StyledMainMenuToggleWrapper = styled.div<{ isSubLevel?: boolean }>(
	{
		alignSelf: 'center',
	},
	ifProp(
		'isSubLevel',
		{
			display: 'none',

			[breakpoints.lg]: {
				display: 'block',
				position: 'absolute',
				top: themeBase.space[2],
				right: themeBase.space[2],
			},
		},
		{
			[breakpoints.lg]: {
				display: 'none',
			},
		},
	),
);

export const StyledMainMenuToggleLine = styled.span({
	position: 'absolute',
	left: themeBase.space[1],
	right: themeBase.space[1],
	display: 'block',
	height: '1px',
	background: themeBase.colors.black,
	transformOrigin: 'center center',
});

export const StyledMainMenuToggleButton = styled(StyledResetButton)<{ isOpen?: boolean }>(
	{
		'--button-size': '24px',

		position: 'relative',
		boxSizing: 'border-box',
		display: 'block',
		width: 'var(--button-size)',
		height: 'var(--button-size)',
		padding: themeBase.space[1],
		transformOrigin: '50% 50%',
		willChange: 'transform, top',

		[`${StyledMainMenuToggleLine}:nth-of-type(1)`]: {
			top: `calc(50% - ${themeBase.space[1]})`,
			transform: `translate(0, -50%) rotate(0)`,
			transition: `top ${themeBase.animations.timingMedium} ${themeBase.animations.timingShort}, transform ${themeBase.animations.timingShort}`,
		},
		[`${StyledMainMenuToggleLine}:nth-of-type(2)`]: {
			top: '50%',
			transform: 'translate(0, -50%) scaleX(1)',
			transition: `transform 0s ${themeBase.animations.timingShort}`,
		},
		[`${StyledMainMenuToggleLine}:nth-of-type(3)`]: {
			top: `calc(50% + ${themeBase.space[1]})`,
			transform: `translate(0, -50%) rotate(0)`,
			transition: `top ${themeBase.animations.timingMedium} ${themeBase.animations.timingShort}, transform ${themeBase.animations.timingShort}`,
		},
	},
	ifProp('isOpen', {
		[`${StyledMainMenuToggleLine}:nth-of-type(1)`]: {
			top: '50%',
			transform: `translate(0, -50%) rotate(45deg)`,
			transition: `top ${themeBase.animations.timingMedium}, transform ${themeBase.animations.timingShort} ${themeBase.animations.timingShort}`,
		},
		[`${StyledMainMenuToggleLine}:nth-of-type(2)`]: {
			transform: 'translate(0, -50%) scaleX(0)',
		},
		[`${StyledMainMenuToggleLine}:nth-of-type(3)`]: {
			top: '50%',
			transform: `translate(0, -50%) rotate(-45deg)`,
			transition: `top ${themeBase.animations.timingMedium}, transform ${themeBase.animations.timingShort} ${themeBase.animations.timingShort}`,
		},
	}),
);
