import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { breakpoints } from '~/theme';

export const StyledBrand = styled.div(({ theme }) => ({
	fontSize: theme.fontSizes['3xs'],
	textTransform: 'uppercase',
	color: theme.colors.black,
	marginBottom: theme.space['1'],
	letterSpacing: theme.letterSpacing.xs,
	fontWeight: theme.fontWeights.medium,
	lineHeight: theme.lineHeights.single,

	[breakpoints.md]: {
		position: 'absolute',
		top: `calc(-18px - ${theme.fontSizes.xs})`,
		zIndex: theme.productCard.zIndexBrandLabel,
		fontSize: theme.fontSizes.xs,
		fontWeight: theme.fontWeights.bold,
		marginBottom: '0',
	},
}));

export type BrandProps = PropsWithChildren<unknown>;
export const Brand = ({ children }: BrandProps) => <StyledBrand>{children}</StyledBrand>;
