import { ReactElement } from 'react';
import { StyledDetails, StyledDetailsLeft, StyledDetailsRight } from './styled';

export type DetailsProps = {
	left: ReactElement | ReactElement[];
	right: ReactElement | ReactElement[] | null;
	picker?: ReactElement | null;
};

export const Details = ({ left, right, picker = null }: DetailsProps) => (
	<StyledDetails>
		{picker}
		<StyledDetailsLeft>{left}</StyledDetailsLeft>
		<StyledDetailsRight>{right}</StyledDetailsRight>
	</StyledDetails>
);
