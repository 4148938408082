import { IValidatedPurchaseOrder } from '~/lib/data-contract';
import { IMarket } from '~/shared/utils/market/model/marketModel';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { postRequest } from '~/shared/utils/request/utils/postRequest';

export const createOrder = async (market: IMarket) => {
	const url = buildURL('/api/proxy/checkout/order/create', {
		...market,
	});

	const request = postRequest<IValidatedPurchaseOrder>(url, undefined);

	const response = await request();

	if (!response.redirectUrl) {
		throw Error('Unable to receive redirectUrl from order creation');
	}

	return response;
};
