import { RaptorCarouselClickEvent } from '../model';
import { pushToDataLayer } from './pushToDataLayer';

export const trackRaptorCarouselClickEvent = (raptorModuleSource: string) => {
	pushToDataLayer<RaptorCarouselClickEvent>({
		event: 'gaEvent',
		eventCategory: 'ClickproductInRaptorSpot',
		eventAction: raptorModuleSource,
		eventLabel: window.document.location.pathname,
	});
};
