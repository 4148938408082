import { memo } from 'react';
import { useWishlist } from '~/features/wishlist/hooks/useWishlist';
import Heart from '~/icons/heart.svg';
import Pin from '~/icons/pin.svg';
import { useNavigation } from '~/shared/utils/navigation/hooks/useNavigation';
import { StyledNavigationHotLinksLink, StyledNavigationHotLinksWrapper } from './NavigationHotLinks.styled';

const NavigationHotLinks = () => {
	const {
		data: { header: { favorites, findStores } = {} },
	} = useNavigation();
	const { data: { items: itemsWishList = [] } = {} } = useWishlist();

	return (
		<StyledNavigationHotLinksWrapper>
			{favorites ? (
				<StyledNavigationHotLinksLink
					href={favorites.url}
					title={favorites.title}
				>
					<Heart />
					{`${favorites.text} (${itemsWishList.length})`}
				</StyledNavigationHotLinksLink>
			) : null}

			{findStores ? (
				<StyledNavigationHotLinksLink
					href={findStores.url}
					title={findStores.title}
					isCustomStyle
				>
					<Pin />
					{findStores.text}
				</StyledNavigationHotLinksLink>
			) : null}
		</StyledNavigationHotLinksWrapper>
	);
};

export default memo(NavigationHotLinks);
