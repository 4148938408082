import styled from '@emotion/styled';

export const StyledTableWrapper = styled.div({
	width: '100%',
	minWidth: '100%',
	overflowX: 'auto',
});

export const StyledTable = styled.table({
	borderCollapse: 'collapse',
	textAlign: 'center',
});

export const StyledTableCell = styled.td(({ theme }) => ({
	...theme.textStyles.body,
	verticalAlign: 'middle',
	color: theme.colors.grey60,
	padding: `${theme.space[4]} ${theme.space[3]}`,
	border: `1px solid ${theme.colors.grey60}`,
}));

export const StyledTableHeadCell = styled.th(({ theme }) => ({
	...theme.textStyles.body,
	whiteSpace: 'nowrap',
	verticalAlign: 'middle',
	padding: theme.space[1],
}));

export const StyledTableBody = styled.tbody(({ theme }) => ({
	border: `1px solid ${theme.colors.grey60}`,
}));
