import { IValidatedBasket } from '~/lib/data-contract';
import { IMarket } from '~/shared/utils/market/types';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { putRequest } from '~/shared/utils/request/utils/putRequest';
import { BasketError } from './BasketError';
import { BASKET_LINE_URL } from './endpoints';

export async function updateItem(sku: string, quantity: number, market: IMarket, abortSignal?: AbortSignal) {
	const url = buildURL(`${BASKET_LINE_URL}/${sku}`, { ...market, quantity });
	const request = await putRequest<IValidatedBasket | null>(url, null, abortSignal);
	const response = await request();

	// response is null when failing.
	if (!response?.success) {
		throw new BasketError(response?.validationMessages?.[0]);
	}

	return response;
}
