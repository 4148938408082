import { useMutation, useQueryClient } from 'react-query';
import { useWishlistKey } from '~/features/wishlist/hooks/useWishlistKey';
import { IVariationDetails, IWishlist } from '~/lib/data-contract';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { addToWishlist } from '../service/addToWishlist';

type UseAddToWishlistMutationArgs = {
	variation: IVariationDetails;
};

export const useAddToWishlist = () => {
	const { market, culture } = useMarket();
	const { queryKey, mutationKeyAdd } = useWishlistKey();

	const queryClient = useQueryClient();

	return useMutation<IWishlist, Error, UseAddToWishlistMutationArgs>({
		mutationKey: mutationKeyAdd,
		mutationFn: ({ variation }) => addToWishlist(variation.sku, { market, culture }),
		onMutate: async ({ variation }) => {
			await queryClient.cancelQueries(queryKey);

			queryClient.setQueryData<IWishlist | undefined>(queryKey, (prev) => {
				if (prev) {
					return {
						...prev,
						items: [
							...prev.items,
							{
								id: `mutation-wishlist-${variation.sku}`,
								variation,
							},
						],
					};
				}
			});
		},
		onSuccess: async (data) => {
			await queryClient.cancelQueries(queryKey);
			queryClient.setQueryData<IWishlist>(queryKey, data);
		},
		onError: () => queryClient.invalidateQueries(queryKey),
	});
};
