import styled from '@emotion/styled';
import { Close } from '@radix-ui/react-dialog';
import { useRouter } from 'next/router';
import { FormEvent, useCallback, useState } from 'react';
import { Button } from '~/shared/components/Button/Button';
import { AltSelector } from '~/shared/components/FormElements/components/AltSelector/AltSelector';
import { LoadingCircle } from '~/shared/components/Loading/LoadingCircle';
import { RawHtml } from '~/shared/components/RawHtml/RawHtml';
import { useRouteTransition } from '~/shared/utils/route-transition/hooks/useRouteTransition';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { isUrl } from '~/shared/utils/url/isUrl';
import { useMarketSelectorControl } from '../../hooks/useMarketSelectorControl';
import { StyledForm } from './styled';

export const StyledMarketSelectorRichText = styled(RawHtml)(({ theme }) => ({
	color: theme.colors.negative,
	marginTop: theme.space[2],

	'p, ol, ul, pre, table, li': {
		...theme.textStyles.bodyXs,
	},
}));

export const MarketSelector = () => {
	const { marketOptions, selectedMarketOption, isSameMarketAndCulture, selectedMarketCultureUrl, onMarketOptionChange, localeOptions, selectedLocaleOption, onLocaleOptionChange, currentMarketId } =
		useMarketSelectorControl();
	const router = useRouter();
	const { loading: transitionLoading } = useRouteTransition();
	const { translate } = useTranslation();
	const [changingMarket, setChangingMarket] = useState(false);

	const handleSubmit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (isSameMarketAndCulture || transitionLoading) {
				return;
			}

			if (isUrl(selectedMarketCultureUrl)) {
				window.location.href = selectedMarketCultureUrl;
			} else {
				router.push(selectedMarketCultureUrl, undefined, { scroll: true });
			}
		},
		[router, isSameMarketAndCulture, transitionLoading, selectedMarketCultureUrl],
	);

	return (
		<div>
			{changingMarket ? <StyledMarketSelectorRichText html={translate('market-selector.market-change-warning')} /> : null}
			<StyledForm onSubmit={handleSubmit}>
				<AltSelector
					label={translate('market-selector.country')}
					value={selectedMarketOption}
					options={marketOptions}
					onChange={(newValue) => {
						setChangingMarket(newValue?.value !== currentMarketId);
						onMarketOptionChange(newValue);
					}}
				/>
				<AltSelector
					label={translate('market-selector.language')}
					value={selectedLocaleOption}
					options={localeOptions}
					onChange={onLocaleOptionChange}
				/>
				{isSameMarketAndCulture ? (
					<Close asChild>
						<Button type="button">{translate('market-selector.continue')}</Button>
					</Close>
				) : (
					<Button type="submit">
						{translate('market-selector.continue')}
						{transitionLoading ? <LoadingCircle /> : null}
					</Button>
				)}
			</StyledForm>
		</div>
	);
};
