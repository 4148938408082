import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const rotationFrames = keyframes({
	'0%': { transform: 'rotate(0deg)' },
	'100%': { transform: 'rotate(360deg)' },
});

const StyledLoadingWrapper = styled.div<{ scale?: number }>(({ scale = 1 }) => ({
	display: 'block',
	height: `calc(16px * ${scale})`,
	width: `calc(16px * ${scale})`,
	animation: `${rotationFrames} 2.5s linear infinite`,
}));

const StyledLoadingInner = styled.span<{ scale?: number }>(({ scale = 1 }) => ({
	display: 'block',
	position: 'absolute',
	top: '0',
	left: '0',
	bottom: '0',
	right: '0',
	margin: 'auto',
	height: `calc(16px * ${scale})`,
	width: `calc(16px * ${scale})`,
	clip: `rect(calc(8px * ${scale}), calc(16px * ${scale}), calc(16px * ${scale}), 0)`,
	animation: `${rotationFrames} 1s cubic-bezier(0.77, 0, 0.175, 1) infinite`,

	'&:after': {
		boxSizing: 'border-box',
		content: '""',
		display: 'block',
		position: 'absolute',
		top: '0',
		left: '0',
		bottom: '0',
		right: '0',
		margin: 'auto',
		height: `calc(16px * ${scale})`,
		width: `calc(16px * ${scale})`,
		border: '1px solid currentColor',
		borderRadius: ' 50%',
	},
}));

type LoadingCircleSize = 'sm' | 'md' | 'lg';

const getScale = (size: LoadingCircleSize) => {
	switch (size) {
		case 'lg':
			return 2;
		case 'md':
			return 1.5;
		default:
			return 1;
	}
};

type LoadingCircleProps = {
	className?: string;
	size?: LoadingCircleSize;
};

/**
 * Loading circle. Suitable for placing where an async request is being awaited (ex: inside a submit button)
 */
export const LoadingCircle = ({ className, size = 'sm' }: LoadingCircleProps) => {
	const scale = getScale(size);
	return (
		<StyledLoadingWrapper
			scale={scale}
			className={className}
		>
			<StyledLoadingInner scale={scale}></StyledLoadingInner>
		</StyledLoadingWrapper>
	);
};
