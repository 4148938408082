import { useEffect, useMemo } from 'react';
import { getNavigationQueryKey } from '~/shared/utils/navigation/utils/getFrameQueryKey';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { useGetRequest } from '~/shared/utils/request/hooks/useGetRequest';
import { usePage } from '~/templates/pages/hooks/usePage';
import { requestDefaults } from '../../request/utils/requestDefaults';
import {IGlobalMenu} from "~/lib/data-contract";

let previousNavigation: IGlobalMenu = {culture: "", id: "", market: ""};


export const useNavigation = () => {
	const { culture, market } = usePage();
	const queryKey = getNavigationQueryKey(culture, market);

	const { data } = useGetRequest<IGlobalMenu>('/api/proxy/content/navigation', {
		params: { culture, market },
		queryKey,
		keepPreviousData: true,
		staleTime: requestDefaults.cacheTime, // Never stale until cache expires
		cacheTime: requestDefaults.cacheTime,
		enabled: !isSSR,
	});

	useEffect(() => {
		if (data && previousNavigation !== data) {
			previousNavigation = data;
		}
	}, [data]);

	const dataOrPrevious = data ?? previousNavigation;
	return useMemo(() => ({
		data: dataOrPrevious,
	}), [dataOrPrevious.id, dataOrPrevious.culture, dataOrPrevious.market]);
}
