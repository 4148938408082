import dynamic, { LoaderComponent } from 'next/dynamic';
import { IPages, IP10SearchPage } from '~/lib/data-contract';
import type { P100CheckoutPageProps } from '../../P100CheckoutPage/P100CheckoutPage';
import type { P110OrderConfirmationPageProps } from '../../P110OrderConfirmationPage/P110OrderConfirmationPage';
import type { P140NotFoundPageProps } from '../../P140NotFoundPage/components/P140NotFoundPage';
import type { P40ProductListPageProps } from '../../P40ProductListPage/P40ProductListPage';
import type { P50ProductDetailPageProps } from '../../P50ProductDetailPage/components/P50ProductDetailPage';
import type { P60ModulePageProps } from '../../P60ModulePage/components/P60ModulePage';
import type { P70FindStoresPageProps } from '../../P70FindStoresPage/components/P70FindStoresPage';
import type { P80FavoritePageProps } from '../../P80FavoritePage/P80FavoritePage';
import type { P90BasketPageProps } from '../../P90BasketPage/P90BasketPage';

const P40ProductListPage = dynamic(() => import('../../P40ProductListPage/P40ProductListPage').then((module) => module.P40ProductListPage) as LoaderComponent<P40ProductListPageProps>, { ssr: false });

const P50ProductDetailPage = dynamic(
	() => import('../../P50ProductDetailPage/components/P50ProductDetailPage').then((module) => module.P50ProductDetailPage) as LoaderComponent<P50ProductDetailPageProps>,
	{ ssr: false }
);

const P100CheckoutPage = dynamic(() => import('../../P100CheckoutPage/P100CheckoutPage').then((module) => module.P100CheckoutPage) as LoaderComponent<P100CheckoutPageProps>, { ssr: false });

const P10SearchPage = dynamic(() => import('../../P10SearchPage/P10SearchPage').then((module) => module.P10SearchPage) as LoaderComponent<IP10SearchPage>, { ssr: false });

const P60ModulePage = dynamic(() => import('../../P60ModulePage/components/P60ModulePage').then((module) => module.P60ModulePage) as LoaderComponent<P60ModulePageProps>, { ssr: false });

const P70FindStoresPage = dynamic(() => import('../../P70FindStoresPage/components/P70FindStoresPage').then((module) => module.P70FindStoresPage) as LoaderComponent<P70FindStoresPageProps>, { ssr: false });

const P80FavoritePage = dynamic(() => import('~/templates/pages/components/P80FavoritePage/P80FavoritePage').then((module) => module.P80FavoritePage) as LoaderComponent<P80FavoritePageProps>, { ssr: false });

const P90BasketPage = dynamic(() => import('../../P90BasketPage/P90BasketPage').then((module) => module.P90BasketPage) as LoaderComponent<P90BasketPageProps>, { ssr: false });

const P110OrderConfirmationPage = dynamic(
	() => import('../../P110OrderConfirmationPage/P110OrderConfirmationPage').then((module) => module.P110OrderConfirmationPage) as LoaderComponent<P110OrderConfirmationPageProps>,
	{ ssr: false }
);

const P140NotFoundPage = dynamic(() => import('../../P140NotFoundPage/components/P140NotFoundPage').then((module) => module.P140NotFoundPage) as LoaderComponent<P140NotFoundPageProps>, { ssr: false });

const UnhandledPageFallback = dynamic(() => import('./UnhandledPageFallback').then((module) => module.UnhandledPageFallback) as LoaderComponent);

export const LazyPageComponent = ({ page }: { page: IPages }) => {
	switch (page.type) {
		case 'p35CategoryDetailPage':
		case 'p40ProductListPage':
			return <P40ProductListPage {...page} />;

		case 'p50ProductDetailPage':
			return <P50ProductDetailPage {...page} />;

		case 'p60ModulePage':
		case 'p20FrontPage':
		case 'p170VerticalPage':
		case 'p150ErrorPage':
		case 'p180MarketingConfirmationPage':
		case 'p181MarketingUpdatePage':
			return <P60ModulePage {...page} />;

		case 'p100CheckoutPage':
			return <P100CheckoutPage {...page} />;
		case 'p140NotFoundPage':
			return <P140NotFoundPage {...page} />;
		case 'p90BasketPage':
			return <P90BasketPage {...page} />;
		case 'p110OrderConfirmationPage':
			return <P110OrderConfirmationPage {...page} />;
		case 'p10SearchPage':
			return <P10SearchPage {...page} />;
		case 'p70FindStoresPage':
			return <P70FindStoresPage {...page} />;
		case 'p80FavoritePage':
			return <P80FavoritePage {...page} />;

		/*
		 - Unmapped pages that got out of scope:
		 'p160ArticlePage'
		 'p30CategoryListPage'
		 */
	}
	return <UnhandledPageFallback />; // TODO: Define what the static page for catastrophic error will look like
};
