import { UpdateShippingAddressErrors } from '../model/checkoutModel';

export class UpdateShippingAddressError extends Error {
	data: UpdateShippingAddressErrors;

	constructor(data: UpdateShippingAddressErrors) {
		super('UpdatePaymentAddressError');
		this.data = data;
	}
}
