import { GetServerSidePropsContext } from 'next';
import { getCMSData } from '~/lib/api';
import { IPages } from '~/lib/data-contract';
import { Translations } from '~/shared/utils/translation/model/translationModel';
import { getTranslationQueryKey } from '~/shared/utils/translation/utils/getTranslationQueryKey';
import { ssrQueryClient } from './ssrQueryClient';

export const translationResolver = (context: GetServerSidePropsContext, page: IPages) => {
	const { req } = context;
	const culture = page.culture;
	const queryKey = getTranslationQueryKey(culture);
	const fetchPromise = ssrQueryClient.fetchQuery(queryKey, () =>
		getCMSData<Translations>(`api/content/translations`, { culture }, req).then(({ status, data }) => {
			if (status !== 200) {
				throw new Error(`Failed to fetch translations ${culture}`);
			}
			return data;
		}),
	);
	const hasPreviousData = !!ssrQueryClient.getQueryState(queryKey)?.data;
	return { fetchPromise, hasPreviousData, queryKey };
};
