import { GetServerSidePropsContext } from 'next';
import { getCMSData } from '~/lib/api';
import { IFrame, IPages } from '~/lib/data-contract';
import { getFrameQueryKey } from '~/shared/utils/frame/utils/getFrameQueryKey';
import { ssrQueryClient } from './ssrQueryClient';

export const frameResolver = (context: GetServerSidePropsContext, page: IPages) => {
	const { req, resolvedUrl } = context;
	const queryKey = getFrameQueryKey(page.culture, page.market, page.verticalId);
	const fetchPromise = ssrQueryClient.fetchQuery(queryKey, () =>
		getCMSData<IFrame>(`api/content/frame`, { url: resolvedUrl }, req).then(({ status, data }) => {
			if (status !== 200) {
				throw new Error(`Failed to fetch frame ${resolvedUrl}`);
			}
			return data;
		}),
	);
	const hasPreviousData = !!ssrQueryClient.getQueryState(queryKey)?.data;
	return { fetchPromise, hasPreviousData, queryKey };
};
