import { IncomingMessage } from 'http';
import fetch, { Response, Headers, HeadersInit } from 'node-fetch';
import { URL } from 'url';
import { isDevelopment } from '~/shared/utils/platform';
import { getToCMSRequestHeaders } from '~/templates/pages/utils/getToCMSRequestHeaders';

type Fetch<T> = Omit<Response, 'json'> & {
	json: () => Promise<T>;
};

type ParamType = {
	[key: string]: string | string[] | undefined;
};

const API_URL = process.env.API_URL;

/**
 * Helper method for fetching content from the API
 *
 * @param path The API path without endpoint
 * @param params Additional params for the url
 * @param headers Headers to append
 */
async function fetchAPI<T>(path: string, params: ParamType = {}, headers: HeadersInit = {}): Promise<Fetch<T>> {
	// Build url
	const url = new URL(path, API_URL);

	// Add query params
	Object.entries(params).forEach(([key, value]) => {
		url.searchParams.append(key, String(value));
	});

	if (isDevelopment) {
		console.info('Querying', url.href, headers);
	}

	const h = new Headers(headers);
	h.append('Content-Type', 'application/json; charset=utf-8');

	const response = await fetch(url.href, {
		method: 'GET',
		headers: h,
	});

	const { status, statusText } = response;

	if (status >= 500) {
		console.error(`Failed to fetch API with url:${url}`);
		console.error(statusText);
	}

	return response;
}

/**
 * Helper method for getting SSR data from the CMS (page, frame, translations)
 *
 * @param url The URL for the data
 * @param headers Additional headers for the request. For example x-forwarded-for
 */
export async function getCMSData<T>(
	url: string,
	params: ParamType | undefined,
	incomingRequest: IncomingMessage,
): Promise<{
	headers: Headers;
	data: T;
	status: number;
}> {
	const requestHeaders = getToCMSRequestHeaders(incomingRequest.headers);
	const response = await fetchAPI<T>(url, params, requestHeaders);
	let responseText = '';
	try {
		responseText = await response.text();
		return {
			status: response.status,
			headers: response.headers,
			data: JSON.parse(responseText),
		};
	} catch (err) {
		throw new Error(`GET ${url}\nFailed to parse JSON response:\n ${responseText}`);
	}
}
