import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';
import { StyledIcon, StyledMainMenuNavigationLink } from './../../MainMenuNavigationLink/MainMenuNavigationLink.styled';

export const StyledNavigationMainItem = styled.li<{ isDefaultSecondaryMenu?: boolean }>({
	paddingTop: themeBase.space[2],
	paddingBottom: themeBase.space[2],
	borderBottom: `1px solid ${themeBase.colors.grey30}`,

	[breakpoints.lg]: {
		paddingTop: 0,
		paddingBottom: 0,
		borderBottom: 'none',

		[`> ${StyledMainMenuNavigationLink}`]: {
			paddingRight: themeBase.space[1],
			paddingLeft: themeBase.space[1],
		},
	},

	[`> ${StyledMainMenuNavigationLink}, > button > ${StyledMainMenuNavigationLink}`]: {
		fontSize: 14,
		textTransform: 'uppercase',

		[breakpoints.lg]: {
			fontSize: 12,
			textTransform: 'uppercase',
		},
	},

	[`${StyledIcon}`]: {
		width: '20px',
		height: '20px',
	},
}, ifProp('isDefaultSecondaryMenu', ({
	[breakpoints.lg]: {
		[`> ${StyledMainMenuNavigationLink}`]: {
			display: 'none',
		},
	},
})));
