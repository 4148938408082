import { useMemo } from 'react';
import { useNetworkState } from 'react-use';
import { VimeoVideoFile } from '~/lib/data-contract';
import { isQuality, selectUsableVideos, sortQuality } from '../utils';

export const useVimeoFile = (files?: VimeoVideoFile[]) => {
	const { effectiveType } = useNetworkState();
	const isHighSpeed = effectiveType === '4g';

	return useMemo(() => {
		if (!files || !files.length) {
			// no files provided to the hook
			return null;
		}

		const filtered = files.filter(selectUsableVideos);
		const ordered = filtered.sort(sortQuality);

		if (!isHighSpeed && filtered.some(isQuality('sd'))) {
			return filtered.filter(isQuality('sd'))[0];
		}

		return ordered[0];
	}, [files]);
};
