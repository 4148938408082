import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledLink, StyledNextLink } from '~/shared/components/Link/Link.styled';
import { breakpoints } from '~/theme';

export const StyledFooterMenu = styled.ul<{ enableRows?: boolean }>(
	({ theme }) => ({
		li: {
			paddingBottom: '5px',
			paddingRight: '10px',

			[breakpoints.md]: {
				lineHeight: theme.lineHeights.compact,
			},

			[`${StyledLink}, ${StyledNextLink}`]: {
				[breakpoints.md]: {
					fontSize: theme.fontSizes['xs'],
				},
			},
		},
	}),
	ifProp('enableRows', ({
		[breakpoints.md]: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
		},

		[breakpoints.lg]: {
			gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
		},

		[breakpoints.xl]: {
			gridTemplateColumns: 'repeat(auto-fill, minmax(420px, 1fr))',
		},
	})),
);
