import { INavigationNode } from '~/lib/data-contract';
import Link from '~/shared/components/Link/Link';
import { Text } from '~/shared/components/Text/Text';
import { StyledFooterMenu } from './styled';

export type FooterNavigationProps = {
	title?: string;
	navigationItems?: INavigationNode[];
	enableMultiColumns?: boolean;
};

export const FooterNavigation = ({ navigationItems = [], title, enableMultiColumns }: FooterNavigationProps) => {
	return (
		<div>
			{title ? (
				<Text
					as="h4"
					variant="bodySmBold"
					marginBottom="2"
				>
					{title}
				</Text>
			) : null}
			<StyledFooterMenu
				enableRows={enableMultiColumns}
			>
				{navigationItems?.map((item) => {
					return (
						<li key={item.id}>
							<Link
								weight="regular"
								href={item.link.url}
								target={item.link.target}
							>
								{item.link.title ?? item.link.text}
							</Link>
						</li>
					);
				})}
			</StyledFooterMenu>
		</div>
	);
};
