import { useMemo } from 'react';
import { IVerticalSelectorItem, ModuleForegroundColor } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { LogoImage } from '../model/logoModel';
import { getFallbackLogo } from '../utils/getFallbackLogo';
import { MAX_HEIGHT_FALLBACK } from '../utils/getFallbackMaxHeights';

const { maxHeightScale: maxHeightScaleDefault, maxHeightScaleDesktop: maxHeightScaleDesktopDefault } = MAX_HEIGHT_FALLBACK;

export type UseLogoImageOpts = {
	brandNo?: string;
	color?: ModuleForegroundColor;
	global?: boolean;
};

type UseLogoImageFunc = (opts?: UseLogoImageOpts) => LogoImage;

export const useLogoImage: UseLogoImageFunc = ({ brandNo, color, global } = {}) => {
	const { data: frame } = useFrame();

	return useMemo<LogoImage>(() => {
		const isLight = color === 'light';
		const { vertical: { logo: frameLogo } = {}, header: { logo: headerLogo } = {} } = frame;

		if (global && headerLogo?.logoImage) {
			return {
				image: isLight && headerLogo.logoImageLight ? headerLogo.logoImageLight : headerLogo.logoImage,
				maxHeightScale: headerLogo.maxHeightScale || maxHeightScaleDefault,
				maxHeightScaleDesktop: headerLogo.maxHeightScaleDesktop || maxHeightScaleDesktopDefault,
			};
		}

		if (global && frameLogo?.logoImage) {
			return {
				image: isLight && frameLogo.logoImageLight ? frameLogo.logoImageLight : frameLogo.logoImage,
				maxHeightScale: frameLogo.maxHeightScale || maxHeightScaleDefault,
				maxHeightScaleDesktop: frameLogo.maxHeightScaleDesktop || maxHeightScaleDesktopDefault,
			};
		}

		const brand = brandNo ?? frame.vertical?.brandNo ?? 'none';
		const vertical = frame.verticalSelector?.verticals?.find(({ brand: verticalBrand }: IVerticalSelectorItem) => verticalBrand?.brandNo === brand);

		if (vertical?.brand?.logo?.logoImage) {
			return {
				image: isLight && vertical.brand.logo.logoImageLight ? vertical.brand.logo.logoImageLight : vertical.brand.logo.logoImage,
				maxHeightScale: vertical.brand.logo.maxHeightScale || maxHeightScaleDefault,
				maxHeightScaleDesktop: vertical.brand.logo.maxHeightScaleDesktop || maxHeightScaleDesktopDefault,
			};
		}

		return getFallbackLogo(brand, color);
	}, [frame.id, brandNo, color, global]);
};
