import { useCallback, useEffect } from 'react';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';
import { useOrderDraftKey } from '~/features/checkout/hooks/useOrderDraftKey';
import { IOrderDraftResponse, IValidatedBasket } from '~/lib/data-contract';
import { useNotification } from '~/shared/hooks/useNotification/useNotification';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { useGetRequest } from '~/shared/utils/request/hooks/useGetRequest';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { DeleteItemMutationArgs, SetBasketMutationArgs, UpsertItemMutationArgs, UseBasket } from './../models/basketModel';
import { addItem as addItemAPI } from './../service/add-item';
import { addItemMultiple as addItemMultipleAPI } from './../service/add-item-multiple';
import { BasketError } from './../service/BasketError';
import { BASKET_URL } from './../service/endpoints';
import { removeBasket as removeBasketAPI } from './../service/remove-basket';
import { removeItem as removeItemAPI } from './../service/remove-item';
import { updateItem as updateItemAPI } from './../service/update-item';
import { generateAddMutation } from './../utils/generateAddMutation';
import { generateSetBasketMutation } from './../utils/generateSetBasketMutation';
import { generateUpdateMutation } from './../utils/generateUpdateMutation';
import { updateOrderDraftBasket } from './../utils/updateOrderDraftBasket';
import { useBasketKey } from './useBasketKey';
import { useBasketState } from './useBasketState';
import { BASKET_STORAGE_DATA, useBasketStorage } from './useBasketStorage';

/*
 * Shared abort method for updating basket.
 * ReactQuery does not support aborting mutation requests.
 */
let updateBasketAbort: () => void;

/**
 * Exposes basket data and actions.
 */
export const useBasket: UseBasket = () => {
	const market = useMarket();
	const clientState = useBasketState();
	const queryClient = useQueryClient();
	const notification = useNotification();
	const { translate } = useTranslation();
	const { setStoredBasket, clearStoredBasket } = useBasketStorage();
	const { queryKey, addKey, updateKey, deleteKey, setBasketKey } = useBasketKey();
	const { queryKey: orderDraftKey } = useOrderDraftKey();

	const {
		data: validatedBasketResponse,
		refetch,
		error,
		isLoading,
		isError,
		isStale,
	} = useGetRequest<IValidatedBasket>(BASKET_URL, {
		params: { ...market },
		queryKey,
	});

	useEffect(() => {
		if (isSSR) return;

		const handlePageHide = () => clearStoredBasket();

		const handleStorage = async (e: StorageEvent) => {
			if (e.key !== BASKET_STORAGE_DATA || !e.newValue) {
				return;
			}

			try {
				await queryClient.cancelQueries(queryKey);
				queryClient.setQueryData(queryKey, JSON.parse(e.newValue));
			} catch (e) {
				console.error(e);
			}
		};

		window.addEventListener('pagehide', handlePageHide);
		window.addEventListener('storage', handleStorage);

		return () => {
			window.removeEventListener('pagehide', handlePageHide);
			window.removeEventListener('storage', handleStorage);
		};
	}, []);

	const handleSuccess = (data: IValidatedBasket) => {
		setStoredBasket(data);
		queryClient.setQueryData(queryKey, data);
		const orderDraftHasData = !!queryClient.getQueryState(orderDraftKey);
		if (orderDraftHasData) {
			queryClient.setQueryData<IOrderDraftResponse | undefined>(orderDraftKey, updateOrderDraftBasket(data));
		}
	};

	const handleError = async (e: Error | BasketError) => {
		let text: string;

		switch (true) {
			case Boolean(e instanceof BasketError && e.message):
				text = e.message;
				break;
			default:
				text = translate('basket.generic-error');
				break;
		}

		notification.push({
			severity: 'info',
			text,
		});

		await refetchAll();
	};

	const refetchOrderDraft = useCallback(() => {
		queryClient.invalidateQueries(orderDraftKey);
	}, [orderDraftKey]);

	// queryKey is the base of addKey, updateKey, deleteKey.
	// By default react-query searches inclusively for keys, meaning
	// that any mutations on the basket will be detected.
	const mutationCount = useIsMutating({ mutationKey: queryKey });
	const isMutating = Boolean(mutationCount);

	const refetchAll = async () => {
		refetchOrderDraft();
		await refetch();
	};

	const { mutate: addItem } = useMutation<IValidatedBasket, Error, UpsertItemMutationArgs>({
		mutationKey: addKey,
		mutationFn: ({ variation, quantity }) => {
			return addItemAPI(variation.sku, quantity, market);
		},
		onMutate: async ({ variation, quantity }) => {
			clientState.setLastAddedItem(variation);

			await queryClient.cancelQueries(queryKey);

			queryClient.setQueryData<IValidatedBasket | undefined>(queryKey, (prev) => {
				if (!prev) {
					return prev;
				}

				return {
					...prev,
					basket: generateAddMutation(prev.basket, variation, quantity),
				};
			});
		},
		onSuccess: handleSuccess,
		onError: async (error, { variation }) => {
			clientState.onErrorAddingItem(error.message, variation);
			await refetchAll();
		},
	});

	const { mutateAsync: updateItem } = useMutation<IValidatedBasket, Error, UpsertItemMutationArgs>({
		mutationKey: updateKey,
		mutationFn: async ({ variation, quantity }) => {
			const controller = new AbortController();
			updateBasketAbort = () => controller.abort();

			try {
				// Wrapped in try-catch to catch abort-errors
				return await updateItemAPI(variation.sku, quantity, market, controller.signal);
			} catch (error) {
				// mutateFn expect a promise, altho we're using fallback
				return new Promise<IValidatedBasket>((resolve) => {
					const data = queryClient.getQueryData<IValidatedBasket>(queryKey);
					data && resolve(data);

					if (error instanceof Error && error.name !== 'AbortError') {
						handleError(error);
					}
				});
			}
		},
		onMutate: async ({ variation, quantity }) => {
			await queryClient.cancelQueries(queryKey);

			queryClient.setQueryData<IValidatedBasket | undefined>(queryKey, (prev) => {
				if (!prev) return prev;

				return {
					...prev,
					basket: generateUpdateMutation(prev.basket, variation, quantity),
				};
			});

			updateBasketAbort?.();
		},
		onSuccess: handleSuccess,
		onError: handleError,
	});

	const { mutate: removeItem, mutateAsync: removeItemAsync } = useMutation<IValidatedBasket, BasketError | Error, DeleteItemMutationArgs>({
		mutationKey: deleteKey,
		mutationFn: ({ variation }) => {
			return removeItemAPI(variation.sku, market);
		},
		onMutate: async ({ variation }) => {
			await queryClient.cancelQueries(queryKey);

			queryClient.setQueryData<IValidatedBasket | undefined>(queryKey, (prev) => {
				if (!prev) {
					return prev;
				}

				return {
					...prev,
					basket: generateUpdateMutation(prev.basket, variation, 0),
				};
			});
		},
		onSuccess: handleSuccess,
		onError: handleError,
	});

	const { mutate: setBasket } = useMutation<IValidatedBasket, BasketError | Error, SetBasketMutationArgs>({
		mutationKey: setBasketKey,
		mutationFn: async ({ items }) => {
			let newResponse = await removeBasketAPI(market);

			const apiItems = items.map((item) => ({
				key: item.variation.sku,
				value: item.quantity,
			}));
			newResponse = await addItemMultipleAPI(apiItems, market);

			return newResponse;
		},
		onMutate: async ({ items }) => {
			await queryClient.cancelQueries(queryKey);

			queryClient.setQueryData<IValidatedBasket | undefined>(queryKey, (prev) => {
				if (!prev) {
					return prev;
				}

				return {
					...prev,
					basket: generateSetBasketMutation(prev.basket, items),
				};
			});
		},
		onSuccess: (data) => handleSuccess(data),
		onError: handleError,
	});

	return {
		...clientState,
		addItem,
		updateItem,
		removeItem,
		removeItemAsync,
		setBasket,
		error: clientState.error || String(error || ''),
		isLoading,
		isError,
		isStale,
		isMutating,
		refetch,
		data: validatedBasketResponse?.basket,
	};
};
