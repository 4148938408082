import { VimeoVideoFile, VimeoVideoQuality } from '~/lib/data-contract';

/**
 * Higher-order function for creating a function,
 * which compares the property VimeoVideoFile.quality
 * to the provided quality.
 */
export const isQuality = (quality: VimeoVideoQuality) => (file: VimeoVideoFile) => {
	return file.quality === quality;
};

const ALLOWED_VIMEO_RENDITIONS: VimeoVideoQuality[] = ['sd', 'hd'];

/**
 * Selects usable vimeo files of quality sd, hd
 */
export const selectUsableVideos = (item: VimeoVideoFile) => {
	if (item.width > 1080) {
		return undefined;
	}

	if (ALLOWED_VIMEO_RENDITIONS.includes(item.quality)) {
		return item;
	}
};

/**
 * Sort array of vimeo files, so highest quality comes first.
 */
export const sortQuality = (a: VimeoVideoFile, b: VimeoVideoFile) => {
	return a.width > b.width ? -1 : 0;
};
