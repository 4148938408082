import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledForm = styled.form(({ theme }) => ({
	display: 'grid',
	gap: theme.space[5],
	marginTop: theme.space[6],
	[breakpoints.sm]: {
		button: {
			marginTop: theme.space[2],
		},
	},
}));
