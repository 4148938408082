import { useLogoImage } from '~/shared/components/Logo/hooks/useLogoImage';
import { Logo } from '~/shared/components/Logo/Logo';
import { IBrandLogo } from '~/templates/blocks/components/BrandLogo/BrandLogo.def';
import { StyledLogoWrapper } from '~/templates/blocks/components/BrandLogo/BrandLogo.styled';

const BrandLogo = ({ logoProps }: IBrandLogo) => {
	const { image, maxHeightScale, maxHeightScaleDesktop } = useLogoImage(logoProps);

	return (
		<StyledLogoWrapper
			maxHeightScale={maxHeightScale}
			maxHeightScaleDesktop={maxHeightScaleDesktop}
		>
			<Logo image={image} />
		</StyledLogoWrapper>
	);
};

export default BrandLogo;
