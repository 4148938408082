import styled from '@emotion/styled';
import { StyledRichText } from '~/shared/components/RawHtml/styled';
import { breakpoints } from '~/theme';
import { getColumn } from '~/theme/lib/grid';

export const StyledFooterSub = styled.div(({ theme }) => ({
	display: 'grid',
	color: theme.colors.grey60,
	...theme.textStyles.bodySm,

	[breakpoints.md]: {
		columnGap: 'var(--grid-gutter)',
		gridTemplateColumns: `minmax(${getColumn(3, true)}, auto) 1fr auto`,
		marginTop: theme.space[10],
		paddingTop: theme.space[5],
		borderTop: `1px solid ${theme.colors.grey60}`,
		...theme.textStyles.body,
	},

	[`${StyledRichText}`]: {
		...theme.textStyles.bodyXs,
	},
}));

export const StyledPayments = styled.div(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: `${theme.space[2]} ${theme.space[4]}`,
	marginTop: theme.space[2],

	[breakpoints.md]: {
		order: 'initial',
		marginTop: 0,
	},
}));
