import create from 'zustand';

export type FocusMethod = 'keyboard' | 'mouse';

export type UseFocusMethodState = {
	focusMethod: FocusMethod;
	setFocusMethod: (focusMethod: FocusMethod) => void;
};

export const useFocusMethodState = create<UseFocusMethodState>((set) => ({
	focusMethod: 'mouse',
	setFocusMethod: (focusMethod) =>
		set({
			focusMethod,
		}),
}));
