import { UpdatePaymentAddressErrors } from '../model/checkoutModel';

export class UpdatePaymentAddressError extends Error {
	data: UpdatePaymentAddressErrors;

	constructor(data: UpdatePaymentAddressErrors) {
		super('UpdatePaymentAddressError');
		this.data = data;
	}
}
