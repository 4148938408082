import { Text } from '~/shared/components/Text/Text';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { MarketSelector } from '../MarketSelector/MarketSelector';
import { StyledMarketSelectorDialogContent } from './styled';

export const MarketSelectorDialogContent = () => {
	const { translate } = useTranslation();
	return (
		<StyledMarketSelectorDialogContent>
			<Text
				as="h3"
				variant="display3"
			>
				{translate('market-selector.select-your-location')}
			</Text>
			<MarketSelector />
		</StyledMarketSelectorDialogContent>
	);
};
