import { useCallback, useEffect } from 'react';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { FocusMethod, useFocusMethodState } from './useFocusMethodState';

/**
 * Used for detecting if the user is operating the site using a keyboard.
 *
 * To initialize, set `init` to true.
 */
export const useFocusMethod = (init = false): FocusMethod => {
	const { focusMethod, setFocusMethod } = useFocusMethodState();

	const handleKeyDown = useCallback(() => {
		removeKeyDownListener();
		addMouseMoveListener();
		setFocusMethod('keyboard');
	}, []);

	const handleMouseMove = useCallback(() => {
		removeMouseMoveListener();
		addKeyDownListener();
		setFocusMethod('mouse');
	}, []);

	const addMouseMoveListener = useCallback(() => window.addEventListener('mousemove', handleMouseMove), []);
	const addKeyDownListener = useCallback(() => window.addEventListener('keydown', handleKeyDown), []);
	const removeMouseMoveListener = useCallback(() => window.removeEventListener('mousemove', handleMouseMove), []);
	const removeKeyDownListener = useCallback(() => window.removeEventListener('keydown', handleKeyDown), []);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		if (init) {
			if (focusMethod === 'keyboard') {
				addMouseMoveListener();
			} else {
				addKeyDownListener();
			}

			return () => {
				removeMouseMoveListener();
				removeKeyDownListener();
			};
		}
	}, []);

	return focusMethod;
};
