import styled from '@emotion/styled';
import NextImage from 'next/image';
import { IStyledImageMagnifierCursorWindow } from './ImageMagnifier.def';

export const StyledImageMagnifierWrapper = styled.div({
	position: 'relative',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
});

export const StyledImageMagnifierCursorWindow = styled.div<IStyledImageMagnifierCursorWindow>(({ theme, imageUrl, showMagnifier }) => ({
	display: showMagnifier ? 'block' : 'none',
	position: 'absolute',
	zIndex: '9',
	width: 200,
	height: 200,
	border: `1px solid ${theme.colors.grey50}`,
	overflow: 'hidden',
	transform: 'translate(-50%, -50%)',
	cursor: 'none',
	pointerEvents: 'none',
	backgroundColor: theme.colors.white,
	backgroundImage: `url(${imageUrl})`,
	backgroundRepeat: 'no-repeat',
}));

export const StyledImageMagnifier = styled(NextImage)<{ isMagnified?: boolean }>(({ isMagnified }) => ({
	position: 'absolute',
	top: '0',
	right: '0',
	bottom: '0',
	left: '0',
	transformOrigin: '0 0',
	willChange: 'transform',
	cursor: isMagnified ? 'zoom-out' : isMagnified === false ? 'zoom-in' : 'default',
}));
