import { ReactNode } from 'react';
import BasketPane from '~/features/basket/components/BasketPane/BasketPane';
import { IModules, IPages } from '~/lib/data-contract';
import { UseLogoImageOpts } from '~/shared/components/Logo/hooks/useLogoImage';
import { Notifications } from '~/shared/components/Notifications/Notifications';
import BrandLogo from '~/templates/blocks/components/BrandLogo/BrandLogo';
import { getIsModuleHero } from '~/templates/pages/utils/getIsModuleHero';
import { getIsModuleOnlyPage } from '~/templates/pages/utils/getIsModuleOnlyPage';
import { StyledContent } from './styled';

export const useLogoProps = (page: IPages): null | UseLogoImageOpts => {
	const isHero = getIsModuleHero(page.pageElements[0] as any as IModules | undefined);
	const isModuleOnlyPage = getIsModuleOnlyPage(page);
	const isProductDetail = page.type === 'p50ProductDetailPage';
	const isProductList = page.type === 'p40ProductListPage' || page.type === 'p35CategoryDetailPage';
	const isRDG = page.verticalId === undefined;

	if (!isRDG && (isProductDetail || isProductList || (isHero && isModuleOnlyPage))) {
		return {
			global: true,
			color: isHero ? 'light' : 'dark',
		};
	}

	return null;
};

export const useCustomHeader = (page: IPages) => {
	const isHero = getIsModuleHero(page.pageElements[0] as any as IModules | undefined);
	const isModuleOnlyPage = getIsModuleOnlyPage(page);
	const isProductDetail = page.type === 'p50ProductDetailPage';
	const isProductList = page.type === 'p40ProductListPage' || page.type === 'p35CategoryDetailPage';
	const isWishlist = page.type === 'p80FavoritePage';

	return isProductDetail || isProductList || isWishlist || (isHero && isModuleOnlyPage);
};

export type PageContentProps = {
	children?: ReactNode;
	page: IPages;
};

export const PageContent = ({ children, page }: PageContentProps) => {
	const logoProps = useLogoProps(page);
	const isCustomHeader = useCustomHeader(page);

	return (
		<StyledContent hasCustomHeader={isCustomHeader}>
			<Notifications />
			{logoProps ? <BrandLogo logoProps={logoProps} /> : null}
			{children}
			<BasketPane />
		</StyledContent>
	);
};
