import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export type StyledTitleProps = {
	small?: boolean;
	uppercase?: boolean;
	grey?: boolean;
};

export const StyledTitle = styled.h1<StyledTitleProps>(
	({ theme }) => ({
		fontSize: theme.fontSizes['2xs'],
		color: theme.colors.blackMeta,
		lineHeight: theme.lineHeights.base,

		[breakpoints.md]: {
			fontSize: theme.fontSizes.xs,
		},
	}),
	ifProp('small', ({ theme }) => ({
		fontSize: theme.fontSizes['3xs'],

		[breakpoints.md]: {
			fontSize: theme.fontSizes['2xs'],
		},
	})),
	ifProp('uppercase', () => ({
		textTransform: 'uppercase',
		marginBottom: '3px',

		[breakpoints.sm]: {
			marginBottom: 0,
		},
	})),
	ifProp('grey', () => ({
		opacity: 0.5,
	})),
);
