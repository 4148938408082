import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { switchProp } from 'styled-tools';
import { ProductCardInner } from '~/features/products/components/ProductCard/ProductCard';

export type StyledCardProps = {
	background?: ComponentProps<typeof ProductCardInner>['background'];
};

export const StyledCard = styled.div<StyledCardProps>(
	{
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flex: '1 0 auto',
	},
	switchProp('background', {
		white: ({ theme }) => ({
			background: theme.colors.white,
		}),
	}),
);
