import { forwardRef } from 'react';
import Select, { Props as SelectProps } from 'react-select';
import Chevron from '~/icons/chevron-down.svg';
import { IImageMedia } from '~/lib/data-contract';
import { ResponsiveImageMedia } from '~/shared/components/ResponsiveImageMedia/ResponsiveImageMedia';
import {
	iconSizes,
	StyledAltSelectorLabel,
	StyledAltSelectorLabelInner,
	StyledControl,
	StyledDropdownIndicator,
	StyledIconWrapper,
	StyledIndicatorsContainer,
	StyledMenu,
	StyledOption,
	StyledSelectContainer,
	StyledSingleValue,
	StyledValueContainer,
} from './styled';
import { InvalidMessage } from '../InvalidMessage/InvalidMessage';

export type AltSelectorOption = {
	label: string;
	value: string;
	icon?: IImageMedia;
};

export type AltSelectorProps = SelectProps<AltSelectorOption, false> & {
	label?: string;
	isInvalid?: boolean;
	invalidMessage?: string;
};

export const AltSelector = forwardRef<any, AltSelectorProps>((props, ref) => {
	const { label, isInvalid, invalidMessage, ...rest } = props;

	return (
		<Select
			{...rest}
			ref={ref}
			isSearchable={false}
			components={{
				IndicatorSeparator: () => null,
				IndicatorsContainer: ({ children, ...props }) => {
					return <StyledIndicatorsContainer {...props.innerProps}>{children}</StyledIndicatorsContainer>;
				},
				DropdownIndicator: (props) => {
					return (
						<div {...props.innerProps}>
							<StyledDropdownIndicator
								isFocused={props.isFocused}
								size="md"
							>
								<Chevron />
							</StyledDropdownIndicator>
						</div>
					);
				},
				Control: ({ children, ...props }) => {
					return (
						<StyledControl
							ref={props.innerRef}
							{...props.innerProps}
						>
							{children}
						</StyledControl>
					);
				},
				SelectContainer: ({ children, ...props }) => {
					return (
						<div>
							<StyledSelectContainer
								{...props.innerProps}
								isFocused={props.isFocused}
								isInvalid={isInvalid}
							>
								{label ? (
									<StyledAltSelectorLabel isInvalid={isInvalid}>
										<StyledAltSelectorLabelInner>{label}</StyledAltSelectorLabelInner>
									</StyledAltSelectorLabel>
								) : null}
								{children}
							</StyledSelectContainer>
							{isInvalid && invalidMessage ? <InvalidMessage>{invalidMessage}</InvalidMessage> : null}
						</div>
					);
				},
				Option: ({ children, ...props }) => {
					const { icon } = props.data;

					return (
						<StyledOption
							ref={props.innerRef}
							{...props.innerProps}
							isFocused={props.isFocused}
						>
							{icon ? (
								<StyledIconWrapper>
									<ResponsiveImageMedia
										sizes={iconSizes}
										image={icon}
									/>
								</StyledIconWrapper>
							) : null}
							<span>{children}</span>
						</StyledOption>
					);
				},
				Menu: ({ children, ...props }) => {
					return (
						<StyledMenu
							{...props.innerProps}
							ref={props.innerRef}
						>
							{children}
						</StyledMenu>
					);
				},
				MenuList: ({ children }) => {
					return <div>{children}</div>;
				},
				SingleValue: ({ children, ...props }) => {
					const { icon } = props.data;

					return (
						<StyledSingleValue>
							{icon ? (
								<StyledIconWrapper>
									<ResponsiveImageMedia
										sizes={iconSizes}
										image={icon}
									/>
								</StyledIconWrapper>
							) : null}
							{children}
						</StyledSingleValue>
					);
				},
				ValueContainer: ({ children }) => {
					return <StyledValueContainer>{children}</StyledValueContainer>;
				},
				Placeholder: ({ children, ...props }) => {
					return <StyledSingleValue {...props.innerProps}>{children}</StyledSingleValue>;
				},
			}}
		/>
	);
});
