import { AccordionItem } from '@radix-ui/react-accordion';
import { memo, MouseEvent as MouseEventReact } from 'react';
import { CeNavigationHierarchy } from '~/features/tracking/model';
import { StyledAccordionContainer } from '~/shared/components/Accordion/components/AccordionItem/styled';
import useMainMenuState from './../../../../useMainMenuState';
import { INavigationItem } from './../../MainMenuNavigation.def';
import { StyledMainMenuAccordionTrigger } from './../../MainMenuNavigation.styled';
import MainMenuNavigationLink from './../../MainMenuNavigationLink/MainMenuNavigationLink';
import IMainMenuNavigationLink from './../../MainMenuNavigationLink/MainMenuNavigationLink.def';
import NavigationLevel1 from './../../NavigationLevel1/NavigationLevel1';

const NavigationSecondaryItem = memo(({ id, link, state, subListNodes, useAccordion, isSingleLine, hasMetaHeader }: Omit<INavigationItem, 'trackingHierarchy'>) => {
	const activeItemSecondary = useMainMenuState((menuState) => menuState.activeItemSecondary);
	const { setMenuIsOpen, setActiveItemSecondary } = useMainMenuState((menuState) => ({
		setMenuIsOpen: menuState.setMenuIsOpen,
		setActiveItemSecondary: menuState.setActiveItemSecondary,
	}));

	const { url: linkUrl, text: linkText, ...linkProps } = link;

	const hasSubNavList = !!subListNodes?.length;
	const hasAccordion = useAccordion && hasSubNavList;

	const trackingHierarchy: CeNavigationHierarchy = {
		firstLevel: linkText,
	};

	const handleNavBarClick = (event: MouseEventReact<HTMLAnchorElement>) => {
		setActiveItemSecondary(id);

		event.preventDefault();
		setMenuIsOpen(true);
		window.document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const handleAccordionClick = (event: MouseEventReact<HTMLAnchorElement>) => {
		// Ensure click on AccordionTrigger while avoiding redirect to anchor href
		event.preventDefault();
		event.currentTarget.parentElement?.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
			}),
		);

		return;
	};

	const navigationLinkProps: IMainMenuNavigationLink = {
		...linkProps,
		href: linkUrl,
		text: linkText,
		linkState: state,
		isSingleLine: isSingleLine,
		tracking: {
			hierarchy: trackingHierarchy,
		},
	};

	return hasAccordion ? (
		<AccordionItem
			value={id}
			asChild
		>
			<li>
				<StyledMainMenuAccordionTrigger>
					<MainMenuNavigationLink
						{...navigationLinkProps}
						onClick={handleAccordionClick}
						isAccordion
					/>
				</StyledMainMenuAccordionTrigger>

				<StyledAccordionContainer>
					<NavigationLevel1
						nodes={subListNodes}
						isOpen={state === 'active'}
						parentLink={link}
						trackingHierarchy={trackingHierarchy}
						hasMetaHeader={hasMetaHeader}
					/>
				</StyledAccordionContainer>
			</li>
		</AccordionItem>
	) : (
		<li>
			<MainMenuNavigationLink
				{...navigationLinkProps}
				onClick={hasSubNavList ? handleNavBarClick : undefined}
			/>

			{hasSubNavList && activeItemSecondary ? (
				<NavigationLevel1
					nodes={subListNodes}
					isOpen={state === 'active'}
					parentLink={link}
					trackingHierarchy={trackingHierarchy}
					hasMetaHeader={hasMetaHeader}
				/>
			) : null}
		</li>
	);
});

export default NavigationSecondaryItem;
