import { IVariationDetails } from '../../lib/data-contract';
import { ShippingPriceMap } from './useShippingPriceMap';

const getHasFreeShipping = (product: IVariationDetails, shippingPriceMap: ShippingPriceMap) => {
	const freeAmountLimit = shippingPriceMap.freeAmount;
	if (freeAmountLimit === undefined) {
		return false;
	}

	const productPrice = product.discountedPrice || product.price;

	return productPrice >= freeAmountLimit;
};

export default getHasFreeShipping;
