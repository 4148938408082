import { IMarket } from '~/shared/utils/market/model/marketModel';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { putRequest } from '~/shared/utils/request/utils/putRequest';
import { UpdatePaymentMethodReturn } from '../model/checkoutModel';

export const updatePaymentMethod = async (paymentOptionId: string, market: IMarket): Promise<UpdatePaymentMethodReturn> => {
	const url = buildURL('/api/proxy/checkout/payment/update', {
		...market,
		paymentOptionId,
	});

	const request = putRequest<boolean>(url, undefined);

	const response = await request();
	if (!response) {
		throw Error('Unable to update payment method');
	}

	return { type: 'updatePaymentMethod', paymentOptionId };
};
