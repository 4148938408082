import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const StyledContent = styled.main<{ hasCustomHeader: boolean }>(
	({ theme }) => ({
		position: 'relative',
		display: 'grid',
		gridTemplateColumns: '100%',
		flexDirection: 'column',
		rowGap: theme.space.rowGap,
		paddingBottom: theme.space.rowGap,
	}),
	ifProp({ 'hasCustomHeader': false }, ({ theme }) => ({
		paddingTop: theme.space[2],

		[breakpoints.md]: {
			paddingTop: theme.space[4],
		},

		[breakpoints.lg]: {
			paddingTop: theme.space[5],
		},
	})),
);
