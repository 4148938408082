import { AddToWishlistEvent } from '~/features/tracking/model';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import { IVariationDetails } from '~/lib/data-contract';

export const useWishlistTracking = (variation: IVariationDetails) => {
	return () => {
		pushToDataLayer<AddToWishlistEvent>({
			event: 'gaEvent',
			eventCategory: 'add_to_wishlist',
			eventAction: `${variation.name} | ${variation.sku}`.toLowerCase(),
			eventLabel: variation.url || '',
			eventValue: variation.discountedPrice || variation.price,
		});
	};
};
