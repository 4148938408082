import { CeSearchEvent } from '~/features/tracking/model';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';

export const useSearchTracking = (location: CeSearchEvent['eventCategory']) => {
	return (term: string, total: number) => {
		pushToDataLayer<CeSearchEvent>({
			event: 'gaEvent',
			eventCategory: location,
			eventAction: term.toLowerCase(),
			eventLabel: String(total),
		});
	};
};
