import { useMemo, useState } from 'react';

const isActuallyLoaded = (target: EventTarget) => !(target as { src?: string }).src?.includes('data:image/gif;base64');

/**
 * Checks for image loaded
 * onLoadingComplete is the correct listener for Next images but seems to fire a while after the
 * rendering is complete so it can be a few hundred ms after the image actually loaded
 * onLoad from DOM is faster but may trigger twice, as next places a placeholder image then replaces it
 * therefore the gif/base64 check
 */
export const useImageLoadingComplete = () => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const loadListeners = useMemo(
		() => ({
			onLoad: (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
				if (isActuallyLoaded(e.target)) {
					setIsImageLoaded(true);
				}
			},
			onLoadingComplete: () => setIsImageLoaded(true),
		}),
		[],
	);
	return { isImageLoaded, loadListeners };
};
