import { STOCK_STATUS_NO_TRACK_URL, STOCK_STATUS_URL } from '~/features/products/services/endpoints';
import { IStockResponse } from '~/lib/data-contract';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { useGetRequest, UseGetRequestResult } from '~/shared/utils/request/hooks/useGetRequest';
import { QueryParams } from '~/shared/utils/request/utils/buildURL';

export type UseStockStatus = (sku: string, shouldTrack?: boolean) => UseGetRequestResult<IStockResponse, Error>;

/**
 * Used for retrieving stock status for a specific SKU.
 * It does also enable tracking on the backend as a side effect.
 */
export const useStockStatus: UseStockStatus = (sku, shouldTrack) => {
	const market = useMarket();
	const url = (shouldTrack ? STOCK_STATUS_URL : STOCK_STATUS_NO_TRACK_URL).replace('{{sku}}', sku);
	const params: QueryParams = {
		...market,
	};

	return useGetRequest(url, {
		params,
		refetchOnMount: 'always',
		enabled: !isSSR,
	});
};
