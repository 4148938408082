import { memo } from 'react';
import Link from '~/shared/components/Link/Link';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { StyledList, StyledListLabel, StyledQuickLinksArea } from './styled';

export const QuickLinks = memo(() => {
	const { translate } = useTranslation();
	const { data: frame } = useFrame();
	return (
		<StyledQuickLinksArea aria-live="polite">
			<StyledListLabel
				as="h3"
				variant="bodySm"
				id="quick-links"
			>
				{translate('search.quick-links-title')}
			</StyledListLabel>
			<StyledList aria-labelledby="quick-links">
				{frame.search?.quickLinks?.slice(0, 5).map((link) => (
					<li key={`quick-link-${link.id}`}>
						<Link
							title={link.title}
							href={link.url}
						>
							{link.text}
						</Link>
					</li>
				))}
			</StyledList>
		</StyledQuickLinksArea>
	);
});
