import { useEffect, useState } from 'react';
import { isSSR } from '~/shared/utils/platform/utils/platform';

export type CookieInformationConsents = {
	cookie_cat_necessary: boolean;
	cookie_cat_functional: boolean;
	cookie_cat_marketing: boolean;
	cookie_cat_unclassified: boolean;
	cookie_cat_statistic: boolean;
};

export const useCookieConsent = () => {
	const [consents, setConsents] = useState<CookieInformationConsents>();

	useEffect(() => {
		if (isSSR) {
			return;
		}

		const handleCookieConsentGiven = () => {
			if ('cicc' in window) {
				setConsents((window as any).cicc as CookieInformationConsents);
			}
		};

		window.addEventListener('CookieInformationConsentGiven', handleCookieConsentGiven);

		return () => {
			window.removeEventListener('CookieInformationConsentGiven', handleCookieConsentGiven);
		};
	}, []);

	return {
		allowStatistics: consents?.cookie_cat_statistic,
	};
};
