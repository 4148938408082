import { mix, opacify } from 'color2k';
import easingCoordinates from 'easing-coordinates';

/**
 * Modified from https://github.com/larsenwork/postcss-easing-gradients
 */

/**
 * If a color is transparent then convert it to a hsl-transparent of the paired color
 * @param {Array} colors An array with two colors
 * @returns {Object} A color as chroma object - keep
 */
const transparentFix = (colors: [string, string]) =>
	colors.map((color, i) => {
		return color === 'transparent' ? opacify(colors[Math.abs(i - 1)], 1) : color;
	});

/**
 * Calculate the color stops based on start+stopColor in an array and easingType
 * @param {array} colors Two colors in an array
 * @param {array} coordinates An array of coordinates (object with x and y keys)
 * @returns {array} An array of colorstops (a string with color and position)
 */
const getColorStops = (colors: [string, string], coordinates: { x: number; y: number }[]) => {
	const colorStops: string[] = [];
	const colorsWithoutTransparency = transparentFix(colors);
	coordinates.forEach((coordinate) => {
		const amount = coordinate.y;
		const percent = coordinate.x * 100;
		const color = amount ? mix(colorsWithoutTransparency[0], colorsWithoutTransparency[1], Math.min(1, Math.max(0, amount))) : colorsWithoutTransparency[0];
		if (Number(coordinate.x) !== 0 && Number(coordinate.x) !== 1) {
			colorStops.push(`${color} ${+percent.toFixed(2)}%`);
		} else {
			colorStops.push(color);
		}
	});

	return colorStops;
};

export const easingGradient = (colors: [string, string], orientation = '90deg', easing = 'ease-in-out', stops = 10) => {
	const coordinates = easingCoordinates(easing, stops);

	return `linear-gradient(${orientation}, ${getColorStops(colors, coordinates)})`;
};
