import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { transparentize } from 'color2k';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useRouteTransition } from '~/shared/utils/route-transition/hooks/useRouteTransition';

const initialProgress = 0.1;
const initialIncreaseAmount = 0.2;
const fadeTime = 300;

const StyledLine = styled.div<{
	fadingOut: boolean;
	type: 'background' | 'foreground';
	color: string;
	scaleX?: number;
}>(({ theme, fadingOut, color, type, scaleX }) => ({
	width: '100%',
	height: '2px',
	position: 'fixed',
	opacity: fadingOut ? 0 : 1,
	transition:
		type === 'background'
			? fadingOut
				? `opacity cubic-bezier(0.35, 0, 0.15, 1) ${fadeTime}ms`
				: 'opacity 0s'
			: fadingOut
				? `opacity cubic-bezier(0.35, 0, 0.15, 1) ${fadeTime}ms, transform cubic-bezier(.21,1.25,.66,.46) 100ms`
				: 'opacity 0s, transform cubic-bezier(.21,1.25,.66,.46) 250ms',

	transformOrigin: 'left top',
	zIndex: type === 'background' ? theme.zIndices.dialogOverlay + 1 : theme.zIndices.dialogOverlay + 2,
	backgroundColor: color,
	transform: scaleX ? `scaleX(${scaleX})` : undefined,
}));

export const PageLoadingIndicator = () => {
	const { loading } = useRouteTransition();
	const theme = useTheme();
	const { foregroundColor, backgroundColor } = useMemo(
		() => ({
			foregroundColor: theme.colors.pageLoadingColor,
			backgroundColor: transparentize(theme.colors.pageLoadingColor, 0.75),
		}),
		[theme.colors.pageLoadingColor],
	);
	const [progress, setProgress] = useState(initialProgress);
	const increaseAmount = useRef(initialIncreaseAmount);
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const [state, setState] = useState<'idle' | 'loading' | 'finishing'>('idle');

	useUpdateEffect(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		if (loading) {
			if (state === 'idle') {
				timeout.current = setTimeout(() => {
					setState('loading');
				}, 100);
				increaseAmount.current = initialIncreaseAmount;
				setProgress(initialProgress);
			}
		}
		if (!loading) {
			setState('finishing');
			timeout.current = setTimeout(() => {
				setState('idle');
			}, fadeTime);
		}
	}, [loading]);

	useEffect(() => {
		if (!loading || state === 'idle') {
			return;
		}
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		if (progress < 0.95) {
			let delay = 0;
			if (progress < 0.7) {
				increaseAmount.current = increaseAmount.current * (0.75 + Math.random() * 0.15);
				delay = 200 + Math.random() * 400;
			} else if (progress < 0.89) {
				increaseAmount.current = 0.0025 + Math.random() * 0.0075;
				delay = 400 + Math.random() * 600;
			} else {
				increaseAmount.current = 0.001 + Math.random() * 0.004;
				delay = 500 + Math.random() * 1000;
			}
			timeout.current = setTimeout(() => {
				setProgress(progress + increaseAmount.current);
			}, delay);
		}
	}, [progress, loading, state]);

	if (state === 'idle') {
		return null;
	}

	return (
		<>
			<StyledLine
				type="background"
				fadingOut={state === 'finishing'}
				color={backgroundColor}
			/>
			<StyledLine
				type="foreground"
				scaleX={state === 'loading' ? progress : 1}
				fadingOut={state === 'finishing'}
				color={foregroundColor}
			/>
		</>
	);
};
