import { IMarket } from '~/shared/utils/market/model/marketModel';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { putRequest } from '~/shared/utils/request/utils/putRequest';
import { UpdatePaymentAddressData, UpdatePaymentAddressRequestBody, UpdatePaymentAddressResponseBody } from '../model/checkoutModel';
import { getIsStepValid } from '../utils/getIsStepValid';
import { UpdatePaymentAddressError } from './UpdatePaymentAddressError';

export const updatePaymentAddress = async (data: UpdatePaymentAddressData, market: IMarket) => {
	const url = buildURL('/api/proxy/checkout/payment/address', {
		...market,
	});

	const request = putRequest<UpdatePaymentAddressResponseBody, UpdatePaymentAddressRequestBody>(url, data);

	let response: ReturnTypeAwaited<typeof request>;

	try {
		response = await request();
	} catch (err) {
		throw new UpdatePaymentAddressError({ unknownError: true });
	}
	const isStepValid = getIsStepValid('userInfo', response.fieldErrors, response.errors);
	if (!isStepValid) {
		const { userInfo, billingAddress } = response.fieldErrors || {};
		const general = response.errors;
		throw new UpdatePaymentAddressError({ userInfo, billingAddress, general });
	}
	return response;
};
