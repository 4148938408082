import styled from '@emotion/styled';
import { HTMLAttributes, KeyboardEvent, useEffect, useRef, useState } from 'react';
import MinusIcon from '~/icons/minus.svg';
import PlusIcon from '~/icons/plus.svg';
import { BaseThemeType } from '~/theme/lib/themes/themeBase';
import { StyledButton, StyledContainer, StyledContent } from './styled';

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
	value: number;
	onChange: (amount: number) => void;
	size?: keyof BaseThemeType['fontSizes'];
};

const StyledInput = styled.input({
	width: '100%',
	height: 'calc(100% -2px)',
	textAlign: 'center',
	display: 'inline-block',
	border: 'none',
	outline: 'none',
	fontSize: 'inherit',
});

export function QuantityInput({ value, onChange, ...rest }: Props) {
	// We debounce blur to allow the user to press + and - while the input
	// field is empty
	const blurTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
	const [inputValue, setInputValue] = useState<string | number>(value);

	const numericInputValue = parseInt(inputValue + '') || 0;

	// Handle keydown manually to avoid number input field arrows
	const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
		const input = e.currentTarget;
		const value = parseInt(input.value);

		if (e.key == 'ArrowUp') {
			onChange(value + 1);
			e.preventDefault();
		} else if (e.key == 'ArrowDown') {
			onChange(value - 1);
			e.preventDefault();
		}
	};

	const updateValue = (value: string | number) => {
		blurTimeoutRef.current && clearInterval(blurTimeoutRef.current);
		setInputValue(value);

		if (value !== '') {
			onChange(Math.max(parseInt(value + ''), 0));
		}
	};

	// If the user deletes all values we allow them
	// to add a new number before assuming they wanted to delete
	const onBlurHandler = () => {
		blurTimeoutRef.current = setTimeout(() => {
			onChange(numericInputValue);
		}, 200);
	};

	// This useEffect ensures the correct item data is pushed to basket
	// without this the item can have the wrong stockState (eg. not showing preorder)
	useEffect(() => {
		updateValue(value);
	}, [value]);

	return (
		<StyledContainer
			{...rest}
			onBlur={onBlurHandler}
			onFocus={() => blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current)}
		>
			<StyledContent>
				<StyledButton
					disabled={!value}
					onClick={() => updateValue(numericInputValue - 1)}
				>
					<MinusIcon />
				</StyledButton>
				<StyledInput
					value={inputValue}
					inputMode="numeric"
					onKeyDown={onKeyDownHandler}
					onChange={(e) => updateValue(e.currentTarget.value)}
				/>
				<StyledButton onClick={() => updateValue(numericInputValue + 1)}>
					<PlusIcon />
				</StyledButton>
			</StyledContent>
		</StyledContainer>
	);
}
