import { IM10HeroModule, IModules } from '~/lib/data-contract';

/**
 * Useful for detecting if the first module should blend with the top menu
 */
export const getIsModuleHero = (module?: IModules): module is IM10HeroModule => {
	if (module?.type === 'm10HeroModule') {
		return true;
	}
	return false;
};
