import { useTheme } from '@emotion/react';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden/VisuallyHidden';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import useMainMenuState from './../../useMainMenuState';
import IMainMenuToggle from './MainMenuToggle.def';
import { StyledMainMenuToggleButton, StyledMainMenuToggleLine, StyledMainMenuToggleWrapper } from './MainMenuToggle.styled';

export const MENU_ID = 'rdg-navigation';

const MainMenuToggle = ({ isSubLevel }: IMainMenuToggle) => {
	const { translate } = useTranslation();
	const theme = useTheme();
	const menuIsOpen = useMainMenuState((state) => state.menuIsOpen);
	const { setMenuIsOpen, clearMainMenuStates } = useMainMenuState((state) => ({
		setMenuIsOpen: state.setMenuIsOpen,
		clearMainMenuStates: state.clearMainMenuStates,
	}));

	const toggleMenuIsOpen = () => {
		if (menuIsOpen) {
			return clearMainMenuStates();
		}

		// ensure scroll is triggered if metaMenu is still visible
		window.document.documentElement.scrollTo({ top: -parseInt(theme.space.mainNavigationTopOffset), behavior: 'smooth' });

		setMenuIsOpen(true);
	};

	return (
		<StyledMainMenuToggleWrapper isSubLevel={isSubLevel}>
			<StyledMainMenuToggleButton
				isOpen={menuIsOpen}
				onClick={toggleMenuIsOpen}
				aria-expanded={menuIsOpen}
				aria-controls={MENU_ID}
				aria-label={translate('mobile-navigation.open-menu')}
			>
				<StyledMainMenuToggleLine />
				<StyledMainMenuToggleLine />
				<StyledMainMenuToggleLine />

				<VisuallyHidden>{translate('mobile-navigation.open-menu')}</VisuallyHidden>
			</StyledMainMenuToggleButton>
		</StyledMainMenuToggleWrapper>
	);
};

export default MainMenuToggle;
