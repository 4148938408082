import { memo, MouseEvent as MouseEventReact } from 'react';
import { useMedia } from 'react-use';
import { CeNavigationHierarchy } from '~/features/tracking/model';
import { lgCondition } from '~/theme/lib/breakpoints';
import useMainMenuState from './../../../../useMainMenuState';
import { INavigationItem } from './../../MainMenuNavigation.def';
import MainMenuNavigationLink from './../../MainMenuNavigationLink/MainMenuNavigationLink';
import NavigationLevel2 from './../../NavigationLevel2/NavigationLevel2';

const NavigationLevel1Item = memo(({ id, link, state, subListNodes, trackingHierarchy: parentHierarchy }: Omit<INavigationItem, 'hasMetaHeader'>) => {
	const isMinBreakpointLarge = useMedia(lgCondition, false);
	const activeItemLevel1 = useMainMenuState((menuState) => menuState.activeItemLevel1);
	const setActiveItemLevel1 = useMainMenuState((menuState) => menuState.setActiveItemLevel1);

	const { url: linkUrl, text: linkText, ...linkProps } = link;

	// On mobile level 1 is the lowest level. Act as original link
	const hasSubNavList = !!subListNodes?.length && isMinBreakpointLarge;

	const trackingHierarchy: CeNavigationHierarchy = {
		...parentHierarchy,
		secondLevel: linkText,
	};

	const handleClick = (event: MouseEventReact<HTMLAnchorElement>) => {
		event.preventDefault();
		setActiveItemLevel1(activeItemLevel1 === id ? null : id);
	};

	return (
		<li>
			<MainMenuNavigationLink
				{...linkProps}
				href={linkUrl}
				text={linkText}
				linkState={state}
				onClick={hasSubNavList ? handleClick : undefined}
				isSubMenu
				tracking={{
					hierarchy: trackingHierarchy,
				}}
			/>

			{hasSubNavList ? (
				<NavigationLevel2
					nodes={subListNodes}
					parentLink={link}
					isOpen={state === 'active'}
					trackingHierarchy={trackingHierarchy}
				/>
			) : null}
		</li>
	);
});

export default NavigationLevel1Item;
