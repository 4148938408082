import Head from 'next/head';
import { Fragment } from 'react';
import { IMetaTag } from '~/lib/data-contract';

const getLinkedDataValues = (props: Record<string, string>) => {
	const { body = '{}' } = props;
	const parsedBody = JSON.parse(body);
	return Array.isArray(parsedBody) ? parsedBody.map((item) => JSON.stringify(item)) : [JSON.stringify(parsedBody)];
};

type MetaProps = { tags: IMetaTag[] | undefined; pageId: string };

export const Meta = ({ tags, pageId }: MetaProps) => {
	if (!tags?.length) {
		return null;
	}
	const elementData = tags as (IMetaTag & Record<string, string>)[];
	return (
		<Head>
			{elementData.map((element, elementIndex) => {
				const { tag, hreflang, ...props } = element;

				if (hreflang && !props.hrefLang) {
					props.hrefLang = hreflang;
				}

				const Tag = tag as keyof JSX.IntrinsicElements; // Expected 'meta' and 'script';

				if (props.charset) {
					props.charSet = props.charset;
					delete props.charset;
				}

				if (Tag === 'script') {
					const linkedDataValues = getLinkedDataValues(props);
					return (
						<Fragment key={`tag-${pageId}-${elementIndex}`}>
							{linkedDataValues.map((body, ldIndex) => (
								<script
									key={`script-${pageId}-${elementIndex}-${ldIndex}`}
									type="application/ld+json" // Hardcoded type ld+json to prevent script injection
									dangerouslySetInnerHTML={{ __html: body }}
								/>
							))}
						</Fragment>
					);
				} else {
					return (
						<Tag
							key={`tag-${pageId}-${elementIndex}`}
							{...props}
						/>
					);
				}
			})}
		</Head>
	);
};
