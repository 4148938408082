import { useMarket } from '~/shared/utils/market/hooks/useMarket';

const ORDER_DRAFT_KEY = 'RDG_ORDER_DRAFT';

export const useOrderDraftKey = () => {
	const market = useMarket();
	const key = [ORDER_DRAFT_KEY, market.market].join('-');

	return {
		queryKey: key,
	};
};
