import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { BASKET_STORAGE_DATA } from '~/features/basket/hooks/useBasketStorage';
import { UpdatePaymentAddressError } from '~/features/checkout/service/UpdatePaymentAddressError';
import { UpdateShippingAddressError } from '~/features/checkout/service/UpdateShippingAddressError';
import { IBillingDetailsResponse, IOrderDraftResponse, IShippingDetailsResponse, IValidatedBasket } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { useGetRequest } from '~/shared/utils/request/hooks/useGetRequest';
import { CheckoutAPIActions, UpdatePaymentAddressData, UpdateShippingAddressData, UseCheckoutOpts, UseCheckoutReturn } from '../model/checkoutModel';
import { createOrder } from '../service/createOrder';
import { updatePaymentAddress } from '../service/updatePaymentAddress';
import { updatePaymentMethod } from '../service/updatePaymentMethod';
import { updateShippingAddress } from '../service/updateShippingAddress';
import { updateShippingMethod } from '../service/updateShippingMethod';
import { orderDraftBillingDetailsUpdate } from '../utils/optimistic-update/orderDraftBillingDetailsUpdate';
import { orderDraftBillingShippingUpdate } from '../utils/optimistic-update/orderDraftBillingShippingUpdate';
import { orderDraftPaymentMethodUpdate } from '../utils/optimistic-update/orderDraftPaymentMethodUpdate';
import { orderDraftShippingAddressUpdate } from '../utils/optimistic-update/orderDraftShippingAddressUpdate';
import { orderDraftShippingMethodUpdate } from '../utils/optimistic-update/orderDraftShippingMethodUpdate';
import { useCheckoutMutation } from './useCheckoutMutation';
import { useOrderDraftKey } from './useOrderDraftKey';

/**
 * Exposes basket data and actions.
 */
export const useCheckout = (opts?: UseCheckoutOpts<IOrderDraftResponse>): UseCheckoutReturn => {
	const market = useMarket();
	const frame = useFrame();
	const queryClient = useQueryClient();

	const { queryKey } = useOrderDraftKey();

	const query = useGetRequest<IOrderDraftResponse>('/api/proxy/checkout/order/orderdraft', {
		enabled: !isSSR,
		queryKey,
		params: { ...market },
		refetchOnMount: opts?.refetchOnMount,
	});

	const { data: orderDraft, mutate: mutateOrderDraft, invalidate: invalidateOrderDraft } = query;

	const { shippingCountryId, shippingCountryName } = useMemo(() => {
		const shippingCountryId = orderDraft?.basket?.basket.shippingCountryId;
		const country = frame.data.market?.countries?.find(({ id }) => id === shippingCountryId);
		if (!country) {
			return { shippingCountryId: '', shippingCountryName: '' };
		}
		return { shippingCountryId: country.id, shippingCountryName: country.name };
	}, [orderDraft?.basket?.basket.shippingCountryId]);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		const handleStorage = async (e: StorageEvent) => {
			if (e.key !== BASKET_STORAGE_DATA || !e.newValue) {
				return;
			}

			try {
				await queryClient.cancelQueries(queryKey);
				queryClient.setQueryData<IOrderDraftResponse | undefined>(queryKey, (data) => {
					if (!data) {
						return;
					}

					return {
						...data,
						basket: JSON.parse(e.newValue as string) as IValidatedBasket,
					};
				});
			} catch (e) {
				console.error(e);
			}
		};

		window.addEventListener('storage', handleStorage);

		return () => {
			window.removeEventListener('storage', handleStorage);
		};
	}, []);

	const updateOrderDraft = (orderDraft?: IOrderDraftResponse, invalidateQuery = false) => {
		if (!orderDraft) {
			return;
		}
		mutateOrderDraft(orderDraft, invalidateQuery);
	};

	const actions: CheckoutAPIActions = {
		updatePaymentAddress: useCheckoutMutation<UpdatePaymentAddressError, UpdatePaymentAddressData, IBillingDetailsResponse>(
			() => (data) => updatePaymentAddress(data, market),
			(response) => updateOrderDraft(orderDraftBillingDetailsUpdate(orderDraft, response)),
		),
		updateShippingMethod: useCheckoutMutation(
			(ref) => (optionId) => updateShippingMethod(optionId, market, ref),
			(response) =>
				updateOrderDraft(
					orderDraftShippingMethodUpdate(orderDraft, response.shippingOptionId),
					true, // Invalidate as it affects pricing
				),
			(optionId) => updateOrderDraft(orderDraftShippingMethodUpdate(orderDraft, optionId)),
		),
		updateShippingAddress: useCheckoutMutation<UpdateShippingAddressError, UpdateShippingAddressData, IShippingDetailsResponse>(
			() => (data) => updateShippingAddress(data, market),
			(response) => updateOrderDraft(orderDraftShippingAddressUpdate(orderDraft, response)),
		),
		updatePaymentMethod: useCheckoutMutation(
			() => (optionId) => updatePaymentMethod(optionId, market),
			(response) => updateOrderDraft(orderDraftPaymentMethodUpdate(orderDraft, response.paymentOptionId)),
			(optionId) => updateOrderDraft(orderDraftPaymentMethodUpdate(orderDraft, optionId)),
		),
		updatePaymentAndShippingAddress: useCheckoutMutation<
			UpdatePaymentAddressError | UpdateShippingAddressError,
			{ billingAddress: UpdatePaymentAddressData; shippingAddress: UpdateShippingAddressData },
			[IBillingDetailsResponse, IShippingDetailsResponse]
		>(
			() => (data) => Promise.all([updatePaymentAddress(data.billingAddress, market), updateShippingAddress(data.shippingAddress, market, true)]),
			([billingResponse, shippingResponse]) => updateOrderDraft(orderDraftBillingShippingUpdate(orderDraft, billingResponse, shippingResponse)),
		),
		createOrder: useCheckoutMutation(() => () => createOrder(market)),
	};

	return {
		orderDraft,
		shippingCountryId,
		shippingCountryName,
		invalidateOrderDraft,
		...actions,
	};
};
