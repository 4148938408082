import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useRawHtml } from '~/shared/components/RawHtml/hooks/useRawHtml';
import { IN21MetaHeaderProps } from '../../N21MetaHeader';
import { StyledItemList } from './MetaHeaderItemsStyled';

const RichText = ({ html }: { html: string }) => {
	const parsed = useRawHtml(html);
	return parsed;
};

type MetaHeaderItemsProps = { items: IN21MetaHeaderProps['items'] };

export const MetaHeaderItems = ({ items = [] }: MetaHeaderItemsProps) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const item = items.length === 1 ? items[0] : items?.[currentIndex];

	useEffect(() => {
		if (items.length < 2) {
			return;
		}
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
		}, 4000);
		return () => clearInterval(interval);
	}, [items]);

	if (!item?.id || !item?.richText) {
		return null;
	}

	if (items.length === 1) {
		return (
			<StyledItemList>
				{item?.richText?.html && (
					<RichText
						key={item.id}
						html={item?.richText?.html || ''}
					/>
				)}
			</StyledItemList>
		);
	}

	return (
		<StyledItemList>
			<AnimatePresence exitBeforeEnter>
				<motion.div
					key={item.id}
					initial={{ transform: 'translateY(-120%)' }}
					animate={{ transform: 'translateY(0%)' }}
					exit={{ opacity: 0, transform: 'translateY(120%)' }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					{item?.richText?.html && <RichText html={item?.richText?.html || ''} />}
				</motion.div>
			</AnimatePresence>
		</StyledItemList>
	);
};
