import { useLocalStorage } from 'react-use';
import { IValidatedBasket } from '~/lib/data-contract';

export const BASKET_STORAGE_DATA = 'RDG_BASKET_STORAGE_DATA';

export const useBasketStorage = () => {
	const [storedBasket, setStoredBasket, clearStoredBasket] = useLocalStorage<IValidatedBasket>(BASKET_STORAGE_DATA);

	return {
		setStoredBasket,
		storedBasket,
		clearStoredBasket,
	};
};
