import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { Step } from '../model/checkoutModel';
import { checkoutSteps } from '../utils/checkoutSteps';
import { getCheckoutStepFromUrl } from '../utils/getCheckoutStepFromUrl';
import { getCheckoutStepUrl } from '../utils/getStepRoute';

export const useCheckoutSteps = (): {
	currentStep?: Step;
	nextStep?: Step;
	previousStep?: Step;
	nextStepUrl?: string;
	previousStepUrl?: string;
	userInfoStepUrl: string;
	deliveryStepUrl: string;
} => {
	const { data: frameData } = useFrame();
	const router = useRouter();
	const path = router.asPath;
	const checkoutUrl = frameData.header?.checkout?.url;

	return useMemo(() => {
		if (!checkoutUrl) {
			throw 'Checkout URL not found';
		}
		const userInfoStepUrl = getCheckoutStepUrl(checkoutUrl, 'userInfo');
		const deliveryStepUrl = getCheckoutStepUrl(checkoutUrl, 'delivery');

		if (!path.includes(checkoutUrl)) {
			return {
				userInfoStepUrl,
				deliveryStepUrl,
			};
		}

		const currentStep = getCheckoutStepFromUrl(path) || checkoutSteps[0];
		const index = checkoutSteps.indexOf(currentStep);
		const nextStep = checkoutSteps[index + 1];
		const previousStep = checkoutSteps[index - 1];
		const nextStepUrl = getCheckoutStepUrl(checkoutUrl, nextStep);
		const previousStepUrl = getCheckoutStepUrl(checkoutUrl, previousStep);

		return {
			currentStep,
			nextStep,
			previousStep,
			nextStepUrl,
			previousStepUrl,
			userInfoStepUrl,
			deliveryStepUrl,
		};
	}, [path, checkoutUrl]);
};
