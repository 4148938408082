import { IBrandId, ModuleForegroundColor } from '~/lib/data-contract';
import { LogoImage } from '../model/logoModel';
import { getFallbackMaxHeights } from './getFallbackMaxHeights';

export const getFallbackLogo = (brandNo: string, color?: ModuleForegroundColor): LogoImage => {
	let src = color === 'light' ? '/assets/logos/logo-light.svg' : '/assets/logos/logo.svg';
	let alt = 'Rosendahl.com logo';
	switch (brandNo as IBrandId) {
		case 'aj':
		case 'bw':
		case 'glo':
		case 'ho':
		case 'jun':
		case 'kah':
		case 'kay':
		case 'lyp':
		case 'ros':
			src = `/assets/logos/logo-${brandNo}.svg`;
			alt = `Logo ${brandNo}`;
			break;
	}
	return {
		image: {
			origin: 'cms',
			src,
			height: 0,
			width: 0,
			type: 'imageMedia',
			alt,
		},
		...getFallbackMaxHeights(brandNo),
	};
};
