import { useMemo } from 'react';
import { useCheckout } from '~/features/checkout/hooks/useCheckout';
import { IShippingOption } from '../../lib/data-contract';
import { isSSR } from '../utils/platform';

export type ShippingPriceMap = {
	rate?: number;
	freeAmount?: number;
};
const getShipmentDetails = (shippingOptions?: IShippingOption[]): ShippingPriceMap | undefined => {
	if (!shippingOptions || !shippingOptions.length) return undefined;

	const shipmentDetails: ShippingPriceMap = {};
	shippingOptions.forEach((option) => {
		if (shipmentDetails.rate === undefined || option.rate < shipmentDetails.rate) {
			shipmentDetails.rate = option.rate;
		}

		if (option.freeAmount !== undefined) {
			if (shipmentDetails.freeAmount === undefined || option.freeAmount < shipmentDetails.freeAmount) {
				shipmentDetails.freeAmount = option.freeAmount;
			}
		}
	});

	return shipmentDetails;
};

let shippingDetails: ShippingPriceMap | undefined;
export const useShippingPriceMap = () => {
	if (isSSR) return undefined;

	const checkoutData = useCheckout();

	return useMemo(() => {
		if (!shippingDetails) {
			shippingDetails = getShipmentDetails(checkoutData.orderDraft?.data?.shippingOptions);
		}

		if (!shippingDetails?.rate) {
			return undefined;
		}

		return shippingDetails;
	}, [checkoutData]);
};

export default useShippingPriceMap;
