import styled from '@emotion/styled';
import Logo from '~/assets/logos/logo.svg';
import Link from '~/shared/components/Link/Link';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

export const StyledLogoLink = styled(Link)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifySelf: 'center',
	marginTop: themeBase.space[1],

	[breakpoints.lg]: {
		alignItems: 'flex-start',
		justifySelf: 'start',
		marginTop: 0,
	},
});

export const StyledLogo = styled(Logo)({
	height: 12,

	[breakpoints.lg]: {
		height: 15,
	},
});

export const StyledMainMenuLogoTagLine = styled.span({
	marginTop: themeBase.space[1],
	lineHeight: '1em',
	color: themeBase.colors.black,
	fontSize: 12,
	letterSpacing: '0.06px',
	textAlign: 'center',

	[breakpoints.lg]: {
		marginTop: themeBase.space[2],
		textAlign: 'initial',
	},
});
