import { Accordion } from '@radix-ui/react-accordion';
import { memo } from 'react';
import { useMedia } from 'react-use';
import { ILink, INavigationNode } from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { lgCondition } from '~/theme/lib/breakpoints';
import useMainMenuState from './../../../useMainMenuState';
import { INavigationList, NavigationItemState } from './../MainMenuNavigation.def';
import MainMenuNavigationLink from './../MainMenuNavigationLink/MainMenuNavigationLink';
import { StyledNavigationSecondary, StyledNavigationSecondaryWrapper } from './NavigationSecondary.styled';
import NavigationSecondaryItem from './NavigationSecondaryItem/NavigationSecondaryItem';

const NavigationSecondary = memo(({ nodes, isOpen, useAccordion, hasMetaHeader }: Omit<INavigationList, 'trackingHierarchy'>) => {
	const { translate } = useTranslation();
	const isMinBreakpointLarge = useMedia(lgCondition, false);

	const activeItemSecondary = useMainMenuState((state) => state.activeItemSecondary);

	const getLinkState = (id: string): NavigationItemState => {
		return id === activeItemSecondary ? 'active' : 'inactive';
	};

	const getParentLinkNode = (link: ILink) => {
		const { url: linkUrl, text: linkText, ...linkProps } = link;

		return (
			<li>
				<MainMenuNavigationLink
					{...linkProps}
					href={linkUrl}
					text={translate('navigation.view-parent', { parent: linkText })}
					linkState={activeItemSecondary ? 'inactive' : 'default'}
					isSingleLine={isMinBreakpointLarge}
					tracking={{
						hierarchy: {
							firstLevel: linkText,
						},
					}}
				/>
			</li>
		);
	};

	const renderListItem = ({ id, link, children }: INavigationNode) => (
		<NavigationSecondaryItem
			key={id}
			id={id}
			link={link}
			state={activeItemSecondary ? getLinkState(id) : 'default'}
			subListNodes={children}
			useAccordion={useAccordion}
			isSingleLine={isMinBreakpointLarge}
			hasMetaHeader={hasMetaHeader}
		/>
	);

	const renderList = () => (
		<StyledNavigationSecondaryWrapper isOpen={isOpen}>
			<StyledNavigationSecondary>
				{nodes.map((node, index) => {
					const children = node.children;

					if (index === 0 && !isMinBreakpointLarge && !!children?.length) {
						return (
							<li key={node.id}>
								<StyledNavigationSecondary isNested>
									{getParentLinkNode(node.link)}
									{children.map(renderListItem)}
								</StyledNavigationSecondary>
							</li>
						);
					}

					return renderListItem(node);
				})}
			</StyledNavigationSecondary>
		</StyledNavigationSecondaryWrapper>
	);

	return useAccordion ? (
		<Accordion
			asChild
			type="single"
			collapsible
		>
			{renderList()}
		</Accordion>
	) : (
		renderList()
	);
});

export default NavigationSecondary;
