import { IPages } from '~/lib/data-contract';

/**
 * True for pages that have no required modules before the pageElements of the DynamicBlockList
 */
export const getIsModuleOnlyPage = (page: IPages): boolean => {
	switch (page.type) {
		case 'p20FrontPage':
		case 'p60ModulePage':
		case 'p170VerticalPage':
		case 'p140NotFoundPage':
		case 'p150ErrorPage':
		case 'p180MarketingConfirmationPage':
		case 'p181MarketingUpdatePage':
			return true;
		default:
			return false;
	}
};
