import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledDialogFooterContainer = styled.div(({ theme }) => ({
	position: 'sticky',
	bottom: 0,
	zIndex: theme.zIndices.dialogFooter,
	padding: theme.space[4],
	paddingBottom: 0,
	margin: `0 -${theme.space[4]}`,
	marginTop: 'auto',
	marginBottom: `-${theme.space[8]}`,

	[breakpoints.sm]: {
		paddingLeft: theme.space[7],
		paddingRight: theme.space[7],
		marginLeft: `-${theme.space[7]}`,
		marginRight: `-${theme.space[7]}`,
		marginBottom: `-${theme.space[7]}`,
	},
}));

export const StyledDialogFooter = styled.footer(({ theme }) => ({
	background: theme.colors.white,
	paddingTop: theme.space[4],
	borderTop: `1px solid ${theme.colors.grey40}`,
	margin: `0 -${theme.space[4]}`,
	padding: theme.space[4],

	[breakpoints.sm]: {
		margin: `0 -${theme.space[7]}`,
		padding: `${theme.space[4]} ${theme.space[7]}`,
	},
}));

export type DialogFooterProps = {
	children?: JSX.Element | JSX.Element[];
};

export const DialogFooter = ({ children }: DialogFooterProps) => (
	<StyledDialogFooterContainer>
		<StyledDialogFooter>{children}</StyledDialogFooter>
	</StyledDialogFooterContainer>
);
