import styled from '@emotion/styled';
import SearchIcon from '~/icons/search.svg';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

export const StyledMainMenuSearchButtonWrapper = styled.div({
	marginBottom: themeBase.space[2],
	width: '100%',
	padding: `0 ${themeBase.space[3]}`,
	color: themeBase.colors.grey60,

	[breakpoints.lg]: {
		display: 'none',
	},
});

export const StyledMainMenuSearchButton = styled(StyledResetButton)({
	...themeBase.textStyles.bodySm,

	display: 'flex',
	width: '100%',
	padding: `${themeBase.space[3]} 0 ${themeBase.space[2]}`,
	borderBottom: `1px solid currentColor`,
});

export const StyledMainMenuSearchButtonIcon = styled(SearchIcon)({
	flex: `0 0 calc(${themeBase.sizes.iconSm} + 2 * ${themeBase.space[2]})`,
	height: themeBase.sizes.iconSm,
	padding: `0 ${themeBase.space[2]}`,
	strokeWidth: 1.5,
});
