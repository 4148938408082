import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import { ProductCardInner } from '~/features/products/components/ProductCard/ProductCard';

export const StyledThumbnail = styled.div<{ imageLoaded: boolean }>(({ imageLoaded, theme }) => ({
	position: 'relative',
	width: '100%',
	height: 'auto',
	paddingTop: `calc(${theme.productCard.imageHeightNumber} / ${theme.productCard.imageWidthNumber} * 100%)`,
	img: {
		opacity: imageLoaded ? 1 : 0.01,
		transform: imageLoaded ? undefined : 'scale(1.05)',
		transition: `transform ${theme.animations.timingLongest} ${theme.animations.easeSmooth}, opacity ${theme.animations.timingLongest} ${theme.animations.easeSmooth}`,
	},
}));

export type StyledThumbnailInnerProps = {
	isHidden?: boolean;
	background?: ComponentProps<typeof ProductCardInner>['background'];
};

export const StyledThumbnailInner = styled.div<StyledThumbnailInnerProps>(
	({ theme }) => ({
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		background: theme.colors.productCardThumbnail,
	}),
	ifProp('isHidden', () => ({
		visibility: 'hidden',
	})),
	switchProp('background', {
		white: ({ theme }) => ({
			background: theme.colors.white,
		}),
	}),
);
