import { ImageConfig } from 'next/dist/shared/lib/image-config';
import { ImageLoader } from 'next/image';
import { ImageMediaOrigin } from '~/lib/data-contract';
import { imageBankLoader } from '~/shared/components/ImageMedia/loaders/imageBankLoader';
import { optimizelyLoader } from '~/shared/components/ImageMedia/loaders/optimizelyLoader';

const { imageSizes = [] } = ((process.env.__NEXT_IMAGE_OPTS as any) || {}) as Pick<ImageConfig, 'imageSizes' | 'deviceSizes'>;

/**
 * Pre-defined sizes in DAM
 * [96, 384, 750, 1080, 1920, 3840]
 */
export const allowedSizes = imageSizes;
export const allowedQualities = [
	0, // TODO: Add quality 0 preset to backend
	25,
	75,
];

/**
 * Compares provided attribute to list of allowed attributes, and returns closest match.
 */
export const getComputedAttribute = (allowedAttrs: number[], attr: number) => {
	if (allowedAttrs.includes(attr)) {
		// If the provided attribute is allowed,
		// we want to return it as it is.
		return attr;
	}

	// If the provided attribute was not allowed,
	// we want to find the closest match within a tolerance.
	// If the provided attribute exceeds the largest allowed attribute,
	// the largest is used.
	const closeEnough = attr * 0.9;
	return allowedAttrs.find((allowedSize) => allowedSize >= closeEnough) || allowedAttrs[allowedAttrs.length - 1];
};

export type CustomLoaderMap = { [key in ImageMediaOrigin]: ImageLoader };

export const customLoaderMap: CustomLoaderMap = {
	imagebank: imageBankLoader,
	cms: optimizelyLoader,
};

export const getLoader = (origin: ImageMediaOrigin) => customLoaderMap[origin];
