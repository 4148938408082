import { IBasket } from '~/lib/data-contract';
import { UpsertItemMutationArgs } from './../models/basketModel';
import { generateAddMutation } from './../utils/generateAddMutation';

export const generateSetBasketMutation = (prev: IBasket, items: UpsertItemMutationArgs[]): IBasket => {
	let mutation: IBasket = {
		...prev,
		lineItems: [],
		quantitySum: 0,
	};

	items.forEach((item) => {
		mutation = generateAddMutation(mutation, item.variation, item.quantity);
	});

	return mutation;
};
