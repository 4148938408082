import { IFrame, IVariationDetails } from '~/lib/data-contract';
import { getBrandNameFromFrame } from '~/shared/utils/frame/utils/getBrandNameFromFrame';

export function fromVariationDetails(item: IVariationDetails, frame: IFrame) {
	return {
		name: item.name,
		tagline: item.designCollection?.name,
		badges: item.splashes,
		image: item.image,
		hoverImage: item.moodImage,
		price: item.discountedPrice ?? item.price, // TODO: investigate why some are missing
		beforePrice: typeof item.discountedPrice === 'number' ? item.price : undefined,
		variants: item.variations,
		url: item.url,
		brand: getBrandNameFromFrame(frame, item.brandNo),
		hideVariantPicker: item.variations.length === 0,
		size: item.sizeAttribute,
		video: item.video,
		newArrival: item.newArrival,
		manualOrderItem: item.manualOrderItem,
	};
}
