import { IImageMedia } from '~/lib/data-contract';
import { ResponsiveImageMedia } from '../ResponsiveImageMedia/ResponsiveImageMedia';

export type LogoProps = {
	image?: IImageMedia;
	priority?: boolean;
	alt?: string;
};

export const Logo = ({ image, priority }: LogoProps) => {
	if (!image) {
		return null;
	}

	return (
		<ResponsiveImageMedia
			vertical
			priority={priority}
			sizes={'140px'}
			image={image}
		/>
	);
};
