import { useMemo } from 'react';
import { ProductCard } from '~/features/products/components/ProductCard/ProductCard';
import { IVariationDetails } from '~/lib/data-contract';
import Link from '~/shared/components/Link/Link';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { mdMinWidth } from '~/theme/lib/breakpoints';
import { StyledList, StyledListLabel, StyledProductsList, StyledSearchProductsArea } from './styled';

export const SearchProductsArea = ({ products }: { products: IVariationDetails[] }) => {
	const { translate } = useTranslation();

	const showProductCard = useMemo(() => {
		return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > mdMinWidth;
	}, [products]);

	return (
		<StyledSearchProductsArea
			aria-live="polite"
			hasResults={products.length > 0}
		>
			<StyledListLabel
				as="h3"
				variant="bodySm"
				id="search-results"
			>
				{translate('search.search-results-title')}
			</StyledListLabel>
			{showProductCard ? (
				<StyledProductsList aria-labelledby="search-results">
					{products.map((item, i) => (
						<ProductCard
							product={item}
							index={i}
							listId={`search_products_caroussel`}
						/>
					))}
				</StyledProductsList>
			) : (
				<StyledList aria-labelledby="search-results">
					{products.map((link) => (
						<li key={`search-result-link-${link.id}`}>
							<Link
								title={link.name}
								href={link.url || '#'}
							>
								{link.name}
							</Link>
						</li>
					))}
				</StyledList>
			)}
		</StyledSearchProductsArea>
	);
};
