import styled from '@emotion/styled';
import { StyledResponsiveImageMedia } from '~/shared/components/ResponsiveImageMedia/ResponsiveImageMedia';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

export const StyledLogoWrapper = styled.div<{ maxHeightScale: number, maxHeightScaleDesktop: number }>(({ maxHeightScale, maxHeightScaleDesktop }) => (
	{
		position: 'absolute',
		top: `calc(${themeBase.space[3]} / ${maxHeightScale})`,
		right: `calc(${themeBase.space[3]} / ${maxHeightScale})`,
		zIndex: themeBase.zIndices.content,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		width: `calc(var(--brand-logo-width) * ${maxHeightScale})`,
		height: `calc(var(--brand-logo-width) * 0.75 * ${maxHeightScale})`,
		pointerEvents: 'none',

		[`${StyledResponsiveImageMedia}`]: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			width: '100%',
		},

		[breakpoints.lg]: {
			top: `calc(${themeBase.space[5]} / ${maxHeightScaleDesktop})`,
			right: `calc(${themeBase.space[5]} / ${maxHeightScaleDesktop})`,
			width: `calc(var(--brand-logo-width) * ${maxHeightScaleDesktop})`,
			height: `calc(var(--brand-logo-width) * 0.75 * ${maxHeightScaleDesktop})`,
		}
	}
));
