import styled from '@emotion/styled';

export const StyledVariantEllipsis = styled.span(({ theme }) => ({
	width: 'auto',
	height: '50px',
	padding: theme.space['1'],
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
	color: theme.colors.black,
	opacity: 0.5,
	textDecoration: 'none',
}));
