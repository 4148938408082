import { fetcher } from './fetcher';

/**
 * Simpler version of a DELETE request, supplies content type and serializes body to json
 */
export const deleteRequest = <ResponseBody = unknown>(url: string) => {
	return fetcher<ResponseBody>(url, {
		method: 'DELETE',
	});
};
