import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '~/shared/components/Icon/Icon';

const StyledPaneTitle = (theme: Theme) => ({
	...theme.textStyles.body,
	fontWeight: theme.fontWeights.bold,
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	alignItems: 'center',
	marginBottom: theme.space[4],
});

export const StyledLineItemWrap = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: 10,
});

export const StyledAddToBasketPaneTitle = styled.h3(({ theme }) => ({
	...StyledPaneTitle(theme),
	gridTemplateRows: 'auto auto',
}));

export const StyledAddToBasketPaneError = styled.div({
	gridColumnStart: 1,
	gridColumnEnd: 'span 2',
	fontWeight: 'normal',
	color: 'red',
});

export const StyledIcon = styled(Icon)({
	display: 'grid',
	alignItems: 'center',
	justifyContent: 'center',
});
