import styled from '@emotion/styled';

export const StyledWrapper = styled.div<{ moduleBackgroundColor?: string; textColor?: string }>(({ theme, moduleBackgroundColor, textColor }) => ({
	width: '100%',
	height: theme.space.mainNavigationTopOffset,
	backgroundColor: moduleBackgroundColor || theme.colors.metaMenuDefaultBackgroundColor,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	zIndex: theme.zIndices.mainMenu,
	overflow: 'hidden',

	'& > *': {
		color: textColor,
	},
}));
