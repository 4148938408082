import { Theme } from '@emotion/react';
import styled, { Interpolation } from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { mediaQueries } from '~/theme';

export const StyledInputRoot = styled.div();

export type InputBoxHeight = 'tiny' | 'compact' | 'default';

type ColorableInput = {
	isActive?: boolean;
	hasFocus?: boolean;
	isValid?: boolean;
};

export type StyledInputBoxProps = {
	hasValue?: boolean;
	inactive?: boolean;
	height?: InputBoxHeight;
	type?: HTMLInputElement['type'];
} & ColorableInput;

const inputColorStyling: Interpolation<{ theme: Theme } & ColorableInput> = ({ theme, isActive, hasFocus, isValid }) =>
	!isValid
		? {
			color: theme.colors.negative,
		}
		: {
			color: isActive && hasFocus ? theme.forms.textColor : theme.forms.textColorDimmed,
		};

export const StyledInputBox = styled.div<StyledInputBoxProps>(
	({ theme, height = 'default', type = 'text' }) => ({
		position: 'relative',
		height: theme.forms.height[height],
		color: theme.forms.textColor,
		backgroundColor: theme.colors.white,
		boxShadow: `inset 0px 0px 0px ${theme.forms.border} currentColor`,
		display: 'flex',
		alignItems: 'baseline',
		transition: 'color 0.1s',

		[mediaQueries.reducedMotion]: {
			transition: 'none',
		},

		[`${StyledInputLabel}`]: {
			cursor: ['text', 'password', 'email', 'number', 'tel', 'url', 'search'].includes(type) ? 'text' : 'pointer',
		},
	}),
	inputColorStyling,
	ifProp('inactive', ({ theme }) => ({
		backgroundImage: `linear-gradient(135deg, transparent 42.86%, ${theme.forms.disabledLines} 42.86%, ${theme.forms.disabledLines} 50%, transparent 50%, transparent 92.86%, ${theme.forms.disabledLines} 92.86%, ${theme.forms.disabledLines} 100%)`,
		backgroundSize: '9.9px 9.9px',
	})),
);

export const StyledInputLabel = styled.label({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'transparent',
	userSelect: 'none',
});

type StyledInputLabelProps = StyledInputBoxProps;

export const StyledInputLabelContent = styled.div<StyledInputLabelProps>(
	({ theme, hasFocus }) => ({
		position: 'absolute',
		top: '0',
		left: theme.space[3],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: 'fit-content',
		maxWidth: `calc(100% - ${theme.space[3]} * 2)`,
		height: '100%',
		fontSize: theme.fontSizes['sm'],
		lineHeight: 1,
		color: hasFocus ? 'currentColor' : theme.colors.grey60,
		zIndex: 2,

		'&::before': {
			content: '""',
			position: 'absolute',
			top: '-1px',
			left: `calc(${theme.space[1]} * -1)`,
			width: `calc(${theme.forms.labelScaleDown * 100}% + ${theme.space[1]} * 2)`,
			height: '0.6em',
			backgroundColor: theme.colors.bodyBackground,
			transition: `0.15s 0.02s transform ${theme.animations.easeSmooth}`,
			transformOrigin: `${theme.space[4]} 0`,
			transform: 'scaleX(0) translateZ(0)',
			willChange: 'transform',

			[mediaQueries.reducedMotion]: {
				transition: 'none',
			},
		},
	}),
	ifProp('isActive', ({ theme }) => ({
		height: 'auto',
		maxWidth: `calc(100% - ${theme.space[3]})`,

		'&::before': {
			transform: 'scaleX(1) translateZ(0)',
			transitionDelay: '0s',
		},
		[`${StyledInputLabelText}`]: {
			transform: `translateY(-50%) scale(${theme.forms.labelScaleDown}) translateZ(0)`,
		},

		[`${StyledInputPrefix}`]: {
			color: theme.forms.textColor,
		},
	})),
	ifProp({ isActive: true, isValid: false }, ({ theme }) => ({
		color: theme.colors.negative,
	})),
	ifProp('inactive', ({ theme }) => ({
		[`${StyledInputLabelText}`]: {
			color: theme.forms.textColorInactive,
		},
	})),
);

export const StyledInputLabelText = styled.span(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	height: '100%',
	width: 'fit-content',
	transformOrigin: 'center left',
	transition: `0.2s transform ${theme.animations.easeOutCirc}`,
	transform: 'translateY(0) scale(1) translateZ(0)',
	willChange: 'transform',

	wordBreak: 'break-word',

	[mediaQueries.reducedMotion]: {
		transition: 'none',
	},
}));

type StyledInputPrefixProps = {
	hide?: boolean;
};

export const StyledInputPrefix = styled.span<StyledInputPrefixProps>(
	({ theme }) => ({
		display: 'block',
		flex: '0 0 auto',
		paddingLeft: theme.space[3],
		transition: '0.1s opacity',
	}),
	ifProp('hide', { opacity: 0 }),
);

export const StyledInputWrap = styled.div<{ isSelector?: boolean }>(
	{
		position: 'relative',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',

		'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},

		'input[type=number]': {
			MozAppearance: 'textfield',
		},
	},
	ifProp('isSelector', {
		zIndex: 2,
	}),
);

type StyledInputProps = {
	withLabel?: boolean;
	withPrefix?: boolean;
	withAppend?: boolean;
	withDisabledStyling?: boolean;
	isActive?: boolean;
	hide?: boolean;
};

export const StyledInput = styled.input<StyledInputProps>(
	({ theme }) => ({
		outline: 0,
		border: 'none',
		width: '100%',
		boxSizing: 'border-box',
		textAlign: 'left',
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		padding: `0 ${theme.space[3]}`,
		fontFamily: 'inherit',
		fontSize: theme.fontSizes.sm,
		color: theme.forms.textColor,
		backgroundColor: 'transparent',
		WebkitTapHighlightColor: 'transparent',

		'&::placeholder': {
			transition: '0.1s color',
			color: theme.forms.textColorDimmed,
		},
	}),
	ifProp(
		({ disabled, withDisabledStyling }) => disabled || withDisabledStyling,
		({ theme }) => ({
			color: theme.forms.textColorInactive,
		}),
	),
	ifProp(
		{ withLabel: true, isActive: false },
		{
			[`&::placeholder`]: {
				color: 'transparent',
			},
		},
	),
	ifProp('withPrefix', {
		paddingLeft: 0,
	}),
	ifProp('withAppend', {
		paddingRight: 0,
	}),
	ifProp('hide', () => ({
		position: 'absolute',
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: '1',
		overflow: 'hidden',
		width: '1',
	})),
);

export const StyledAdditionalContent = styled.span<ColorableInput>(
	({ theme }) => ({
		position: 'relative',
		zIndex: 2,
		display: 'flex',
		alignSelf: 'center',
		flex: '0 0 auto',
		padding: `0 ${theme.space[2]}`,
		fontSize: theme.fontSizes.xs,
	}),
	inputColorStyling,
);
