import { AccordionMultipleProps, Root } from '@radix-ui/react-accordion';
import { Children, FC, useMemo, useState } from 'react';
import { AccordionContext } from './context/AccordionContext';

export type AccordionProps = Omit<AccordionMultipleProps, 'type'>;

export const Accordion: FC<AccordionProps> = ({ onValueChange, children = [], ...rest }) => {
	const [states, setOpenStates] = useState<string[]>([]);
	const [disableAnimation, setDisabledAnimation] = useState(true);

	const renderAsList = useMemo(() => {
		return Children.count(children) > 1;
	}, [children]);

	const SemanticTag = useMemo(() => (renderAsList ? 'ul' : 'div'), [renderAsList]);

	const setStates = (stateIds: string[]) => {
		const newStates = stateIds.filter((id, index) => {
			// remove duplicates
			return stateIds.indexOf(id) === index;
		});

		setOpenStates(newStates);
		return newStates;
	};

	const open = (stateId: string) => {
		setStates([...states, stateId]);
	};

	const close = (stateId: string) => {
		setStates(states.filter((id) => id !== stateId));
	};

	const toggle = (stateId: string) => {
		states.includes(stateId) ? close(stateId) : open(stateId);
	};

	const onValueChangeHandler = (values: string[]) => {
		const radixChangeValue = setStates(values);

		// When user interacts with accordion, enable animations
		setDisabledAnimation(false);

		onValueChange && onValueChange(radixChangeValue);
	};

	return (
		<Root
			type="multiple"
			onValueChange={onValueChangeHandler}
			value={states}
			asChild
			{...rest}
		>
			<SemanticTag>
				<AccordionContext.Provider value={{ states, setStates, toggle, open, close, renderAsList, disableAnimation }}>{children}</AccordionContext.Provider>
			</SemanticTag>
		</Root>
	);
};
