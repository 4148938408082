import { M100RichTextLayout } from '~/lib/data-contract';
import { useRawHtml } from './hooks/useRawHtml';
import { StyledRichTextProps } from './styled';

export type RawHtmlProps = StyledRichTextProps & {
	html?: string;
	layout?: M100RichTextLayout;
};

export const RawHtml = ({ html = '', ...rest }: RawHtmlProps) => {
	return useRawHtml(html, rest);
};
