import create from 'zustand';

type SearchState = {
	isDialogOpen: boolean;
};

type SearchActions = {
	openDialog: () => void;
	closeDialog: () => void;
};

export const useSearchState = create<SearchState & SearchActions>((set) => ({
	isDialogOpen: false,
	openDialog: () => set({ isDialogOpen: true }),
	closeDialog: () => set({ isDialogOpen: false }),
}));
