import { useMemo } from 'react';
import { getColorVariants } from '~/features/products/utils/getColorVariants/getColorVariants';
import { IVariation, IVariationDetails } from '~/lib/data-contract';

export const useColorVariants = (variants: IVariation[], parentSize: IVariationDetails['sizeAttribute']) => {
	return useMemo(() => {
		const colorVariants = getColorVariants(variants, parentSize);
		const visibleVariants = colorVariants.slice(0, 3);
		const diff = colorVariants.length - visibleVariants.length;
		const numOfHiddenVariants = diff > 0 ? diff : 0;

		return {
			numOfColorVariants: colorVariants.length,
			colorVariants,
			visibleVariants,
			numOfHiddenVariants,
		};
	}, [variants, parentSize]);
};
