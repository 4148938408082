import { ComponentPropsWithRef } from 'react';
import { StyledInvalidMessage } from './styled';

export const InvalidMessage = (props: ComponentPropsWithRef<typeof StyledInvalidMessage>) => (
	<StyledInvalidMessage
		aria-live="polite"
		role="status"
		{...props}
	/>
);
