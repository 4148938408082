import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { Icon } from '~/shared/components/Icon/Icon';
import Link from '~/shared/components/Link/Link';

export const StyledN55BreadcrumbIcon = styled(Icon)(({ theme }) => ({
	marginRight: theme.space['1'],
	transition: `${theme.animations.easeSmooth} ${theme.animations.timingShort} transform`,
}));

const N55BreadcrumbStyle = ({ theme }: { theme: Theme }) => css`
	display: flex;
	align-items: center;
	color: ${theme.colors.black};
	text-decoration: none;
	line-height: 1;
	font-family: inherit;
	font-size: ${theme.fontSizes['2xs']};
	width: fit-content;

	&:hover figure {
		transform: translate3d(-4px, 0, 0);
	}

	&:is([href], button) {
		span {
			position: relative;

			&:after {
				transition: transform ${theme.animations.timingLong} ${theme.animations.easeOutCirc};
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -1px;
				height: 1px;
				background-color: currentColor;
				transform: scaleX(0);
				transform-origin: right center;
			}
		}

		&:hover span:after,
		&:focus span:after {
			transform: scaleX(1);
			transform-origin: left center;
		}
	}
`;

export const StyledN55Breadcrumb = styled(StyledResetButton)(N55BreadcrumbStyle);

export const StyledN55BreadcrumbLink = styled(Link)(N55BreadcrumbStyle);
