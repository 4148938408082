import { ThemeProvider } from '@emotion/react';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useRef } from 'react';
import { isSSR } from '~/shared/utils/platform';
import { themes } from '~/theme';
import { useLayoutScroll } from './../../hooks/useLayoutScroll';
import MainMenuHotLinks from './components/MainMenuHotLinks/MainMenuHotLinks';
import MainMenuLogo from './components/MainMenuLogo/MainMenuLogo';
import MainMenuNavigation from './components/MainMenuNavigation/MainMenuNavigation';
import { StyledMainMenuAccordionTrigger } from './components/MainMenuNavigation/MainMenuNavigation.styled';
import MainMenuToggle from './components/MainMenuToggle/MainMenuToggle';
import IMainMenu from './N20MainMenu.def';
import { StyledHeader, StyledOverlay } from './N20MainMenu.styled';
import useMainMenuState from './useMainMenuState';

const N20MainMenu = memo(({ menuNodes, hasMetaHeader, currentVertical }: IMainMenu) => {
	const router = useRouter();
	const headerRef = useRef<HTMLElement>(null);
	const { menuIsOpen, activeItemSecondary } = useMainMenuState((state) => ({
		menuIsOpen: state.menuIsOpen,
		activeItemSecondary: state.activeItemSecondary,
	}));
	const { setActiveItemMain, clearMainMenuStates } = useMainMenuState((state) => ({
		setActiveItemMain: state.setActiveItemMain,
		clearMainMenuStates: state.clearMainMenuStates,
	}));

	useLayoutScroll(!menuIsOpen);

	const handleMouseLeave = () => {
		if (menuIsOpen || activeItemSecondary !== null) {
			return;
		}

		setActiveItemMain(null);
	};

	const resetMenu = useCallback(() => {
		clearMainMenuStates();

		// Programmatically close potential open accordions
		const activeAccordionTrigger = headerRef?.current?.querySelector(`[data-state="open"] > ${StyledMainMenuAccordionTrigger}`);
		if (activeAccordionTrigger) {
			activeAccordionTrigger.dispatchEvent(
				new MouseEvent('click', {
					bubbles: true,
				}),
			);
		}
	}, [headerRef, headerRef.current]);

	const handleRouteChange = useCallback(() => {
		if (window.sessionStorage.getItem('navigationMenuKeepActive')) {
			window.sessionStorage.removeItem('navigationMenuKeepActive');
		} else {
			resetMenu();
		}
	}, [resetMenu]);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		router.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [handleRouteChange]);

	return (
		<ThemeProvider theme={themes['none']}>
			<StyledOverlay
				aria-hidden
				isOpen={menuIsOpen}
				onClick={resetMenu}
			/>

			<StyledHeader
				ref={headerRef}
				onMouseLeave={handleMouseLeave}
			>
				<MainMenuToggle />

				<MainMenuLogo />

				<MainMenuNavigation
					nodes={menuNodes}
					hasMetaHeader={hasMetaHeader}
					currentVertical={currentVertical}
				/>

				<MainMenuHotLinks />
			</StyledHeader>
		</ThemeProvider>
	);
});

export default N20MainMenu;
