import { DialogProps, Root } from '@radix-ui/react-dialog';
import { StyledDialogOverlayCover } from './styled';

export const Dialog = ({ children, ...props }: DialogProps) => {
	return (
		<Root {...props}>
			<StyledDialogOverlayCover />
			{children}
		</Root>
	);
};
