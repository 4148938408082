import { MutableRefObject } from 'react';
import { IMarket } from '~/shared/utils/market/model/marketModel';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { putRequest } from '~/shared/utils/request/utils/putRequest';
import { UpdateShippingMethodReturn } from '../model/checkoutModel';

export const updateShippingMethod = async (shippingOptionId: string, market: IMarket, abortControllerRef: MutableRefObject<AbortController | null>): Promise<UpdateShippingMethodReturn> => {
	const url = buildURL('/api/proxy/checkout/shipping/shipping', {
		...market,
		shippingOptionId,
	});

	const abortController = new AbortController();
	abortControllerRef.current = abortController;

	const request = putRequest<boolean>(url, undefined, abortController.signal);

	const response = await request();

	if (!response) {
		throw Error('Unable to update shipping method');
	}

	return { type: 'updateShippingMethod', shippingOptionId };
};
