import styled from '@emotion/styled';
import { M100RichTextLayout } from '~/lib/data-contract';
import { breakpoints } from '~/theme';
import { getGutter } from '~/theme/lib/grid';

export type StyledTextColumnsProps = {
	columns: number;
	layout: M100RichTextLayout;
};

export const StyledTextColumns = styled.div<StyledTextColumnsProps>(({ columns }) => ({
	'--column-count': columns,
	columnGap: getGutter(),

	[breakpoints.sm]: {
		display: 'grid',
		gridTemplateColumns: 'repeat(var(--column-count), minmax(0, 50%))',
	},
}));

export type TextColumnsProps = StyledTextColumnsProps & {
	children?: JSX.Element | JSX.Element[] | string | string[];
};

export const TextColumns = ({ children, layout, columns }: TextColumnsProps) => {
	return (
		<StyledTextColumns
			columns={columns}
			layout={layout}
		>
			{children}
		</StyledTextColumns>
	);
};
