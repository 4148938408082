import Link from '~/shared/components/Link/Link';
import { StyledVariantEllipsis } from './styled';

export type EllipsisProps = {
	value: number;
	href?: string;
};

export const Ellipsis = ({ value, href }: EllipsisProps) => {
	const label = `+ ${value}`;

	if (!href) {
		return <StyledVariantEllipsis>{label}</StyledVariantEllipsis>;
	}

	return (
		<Link
			href={href}
			weight="regular"
		>
			<StyledVariantEllipsis>{label}</StyledVariantEllipsis>
		</Link>
	);
};
