import { createContext } from 'react';

type AccordionContextType = {
	states: string[];
	setStates: (ids: string[]) => string[];
	toggle: (id: string) => void;
	open: (id: string) => void;
	close: (id: string) => void;
	renderAsList: boolean;
	disableAnimation: boolean;
};

export const AccordionContext = createContext<AccordionContextType>({} as AccordionContextType);
