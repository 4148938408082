import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

export const StyledNavigationSecondaryWrapper = styled.div<{ isOpen?: boolean }>(
	{
		paddingTop: themeBase.space[1],
		paddingBottom: themeBase.space[3],
		background: themeBase.colors.navigationBackground,

		[breakpoints.lg]: {
			position: 'absolute',
			top: 'calc(100% + 1px)', // 1px for top border offset
			right: '0',
			left: '0',
			display: 'none',
			alignItems: 'stretch',
			marginTop: '0',
			paddingTop: '0',
			paddingRight: themeBase.space[6],
			paddingLeft: themeBase.space[6],
			paddingBottom: '0',
			borderBottom: `1px solid ${themeBase.colors.navigationBorder}`,
		},
	},
	ifProp('isOpen', {
		[breakpoints.lg]: {
			display: 'flex',
		},
	}),
);

export const StyledNavigationSecondary = styled.ul<{ isNested?: boolean }>(
	{
		display: 'grid',
		gridTemplateColumns: '100%',
		gap: themeBase.space[1],

		[breakpoints.lg]: {
			display: 'flex',
			alignItems: 'stretch',
			gap: themeBase.space[5],
			height: '36px',
		},
	},
	ifProp('isNested', {
		paddingTop: 0,
		paddingBottom: themeBase.space[1],
		borderBottom: `1px solid ${themeBase.colors.grey20}`,
	}),
);
