import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { ISplash } from '~/lib/data-contract';
import { breakpoints } from '~/theme';

// TODO: Implement new badge styles when model has been determined.
export type BadgeTypes = 'splash' | 'sale';
export type StyledBadgeProps = {
	type: BadgeTypes;
};

const StyledBadge = styled.div<StyledBadgeProps>(
	({ theme }) => ({
		marginRight: `calc(${theme.space['1']} / 2)`,
		marginBottom: `calc(${theme.space['1']} / 2)`,
		padding: `calc(${theme.space['1']} / 2) ${theme.space['1']}`,
		fontSize: theme.fontSizes['3xs'],
		letterSpacing: '0.3px',
		textTransform: 'uppercase',
		lineHeight: theme.lineHeights.single,
		fontFamily: theme.fonts.body,

		[breakpoints.sm]: {
			fontSize: theme.fontSizes['2xs'],
		},

		[breakpoints.md]: {
			marginRight: theme.space['1'],
			marginBottom: theme.space['1'],
			fontSize: theme.fontSizes['xs'],
		},
	}),
	switchProp('type', {
		sale: ({ theme }) => ({
			background: theme.colors.salesBadgeBackground,
			color: theme.colors.salesBadgeText,
		}),
		splash: ({ theme }) => ({
			border: `1px solid ${theme.colors.salesBadgeBackground}`,
			color: theme.colors.bodyText,
		}),
	}),
);

export type BadgeProps = ISplash;

export const Badge = ({ text, type }: BadgeProps) => <StyledBadge type={type}>{text}</StyledBadge>;
