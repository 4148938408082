import { IValidatedBasket } from '~/lib/data-contract';
import { IMarket } from '~/shared/utils/market/types';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { deleteRequest } from '~/shared/utils/request/utils/deleteRequest';
import { BASKET_URL } from './endpoints';

export async function removeBasket(market: IMarket) {
	const url = buildURL(BASKET_URL, { ...market });

	const request = await deleteRequest<IValidatedBasket>(url);
	return await request();
}
