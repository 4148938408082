import { useSearchState } from '~/features/search/hooks/useSearchState';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import IMainMenuSearchButton from './MainMenuSearchButton.def';
import { StyledMainMenuSearchButton, StyledMainMenuSearchButtonIcon, StyledMainMenuSearchButtonWrapper } from './MainMenuSearchButton.styled';

const MainMenuSearchButton = ({ onOpen }: IMainMenuSearchButton) => {
	const { translate } = useTranslation();
	const openSearchDialog = useSearchState((state) => state.openDialog);

	const handleOpen = () => {
		onOpen?.();
		openSearchDialog();
	};

	return (
		<StyledMainMenuSearchButtonWrapper>
			<StyledMainMenuSearchButton onClick={handleOpen}>
				<StyledMainMenuSearchButtonIcon />

				<span>{translate('search.menu-label')}</span>
			</StyledMainMenuSearchButton>
		</StyledMainMenuSearchButtonWrapper>
	);
};

export default MainMenuSearchButton;
