import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledVariants = styled.div(({ theme }) => ({
	display: 'none',

	'@media all and (any-hover: hover)': {
		display: 'block',
		position: 'absolute',
		top: '0',
		right: '0',
		zIndex: theme.zIndices.productCardVariants,
	},
}));

export type StyledVariantsInnerProps = {
	isVisible?: boolean;
};

export const StyledVariantsInner = styled.div<StyledVariantsInnerProps>(
	({ theme }) => ({
		display: 'flex',
		padding: `calc(${theme.space['1']} / 2) ${theme.space['3']}`,
		boxShadow: theme.boxShadow,
		background: theme.colors.white,
		opacity: 0,
		transition: `opacity ${theme.animations.timingShort} ${theme.animations.easeSmooth}, transform ${theme.animations.timingShort} ${theme.animations.easeSmooth}`,
		pointerEvents: 'none',
	}),
	ifProp('isVisible', () => ({
		transform: 'translate3d(0, -75%, 0)',
		opacity: 1,
	})),
);
