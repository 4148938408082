import styled from '@emotion/styled';
import { getHomeUrl } from '~/features/navigation/utils/getHomeUrl';
import { BreadcrumbLink } from '~/shared/components/BreadcrumbLink/BreadcrumbLink';
import Link from '~/shared/components/Link/Link';
import { useLogoImage } from '~/shared/components/Logo/hooks/useLogoImage';
import { Logo } from '~/shared/components/Logo/Logo';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { breakpoints } from '~/theme';
import { useCheckoutSteps } from '../../../hooks/useCheckoutSteps';

const StyledCheckoutHeader = styled.header(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	backgroundColor: theme.colors.checkoutHeaderBackground,
	paddingLeft: theme.space[4],
	paddingRight: theme.space[4],

	[breakpoints.lg]: {
		display: 'grid',
		gridTemplateColumns: 'repeat(12, 1fr)',
		paddingLeft: 0,
		paddingRight: 0,
	},
}));

const StyledBreadcrumbLink = styled(BreadcrumbLink)({
	[breakpoints.lg]: {
		gridColumn: '2 / span 5',
	},
});

const StyledCheckoutLogo = styled(Link)({
	justifySelf: 'flex-end',
	height: '15%',
	gridColumn: '7 / span 6',

	[breakpoints.lg]: {
		gridColumn: '7 / span 5',
	},
});

export const CheckoutHeader = () => {
	const { translate } = useTranslation();
	const { previousStepUrl } = useCheckoutSteps();
	const { data: { header} } = useFrame();
	const homeUrl = getHomeUrl(header?.home);
	const { image } = useLogoImage({
		brandNo: 'none',
		color: 'dark',
		global: true,
	});

	return (
		<>
			<StyledCheckoutHeader>
				<StyledBreadcrumbLink
					url={previousStepUrl || header?.basket?.url || '#'}
					text={translate('navigation.back')}
				/>
				<StyledCheckoutLogo href={homeUrl}>
					<Logo image={image} />
				</StyledCheckoutLogo>
			</StyledCheckoutHeader>
		</>
	);
};
