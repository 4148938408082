import { Button } from '~/shared/components/Button/Button';
import { ButtonGroup } from '~/shared/components/ButtonGroup/ButtonGroup';
import { DialogFooter } from '~/shared/components/Dialog/components/DialogFooter/DialogFooter';
import Link from '~/shared/components/Link/Link';
import { RawHtml } from '~/shared/components/RawHtml/RawHtml';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import UseBasketPaneState from '../../hooks/useBasketPaneState';

type PaneContentProps = {
	textContent: string;
};

export function PaneContent({ textContent }: PaneContentProps) {
	const { closeDialog } = UseBasketPaneState();
	const { translate } = useTranslation();
	const { data: frame } = useFrame();
	const basketLink = frame.header?.basket;

	const { url = '#', ...linkProps } = basketLink || { url: '#' };

	return (
		<>
			<RawHtml html={textContent} />
			<DialogFooter>
				<ButtonGroup>
					<Button
						variant="outline"
						onClick={closeDialog}
					>
						{translate('basket.continue-shopping')}
					</Button>
					<Button
						as={Link}
						href={url}
						{...linkProps}
					>
						{translate('basket.go-to-checkout')}
					</Button>
				</ButtonGroup>
			</DialogFooter>
		</>
	);
}
