import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StyledOverlay = styled.div<{ isOpen: boolean }>(
	{
		position: 'fixed',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		background: themeBase.colors.black,
		opacity: 0,
		visibility: 'hidden',
		zIndex: themeBase.zIndices.mainMenu - 1,
		transition: `opacity ${themeBase.animations.easeSmooth} ${themeBase.animations.timingShort},visibility 0s ${themeBase.animations.timingShort}`,
	},
	ifProp('isOpen', {
		opacity: 0.5,
		visibility: 'visible',
		transition: `opacity ${themeBase.animations.easeSmooth} ${themeBase.animations.timingShort},	visibility 0s`,
	}),
);

export const StyledHeader = styled.header({
	...themeBase.textStyles.body,

	display: 'grid',
	gridTemplateColumns: '65px 1fr 65px', // 65px estimated secondary width
	alignItems: 'center',
	position: 'relative',
	zIndex: themeBase.zIndices.mainMenu,
	width: '100%',
	paddingLeft: themeBase.space[2],
	paddingRight: themeBase.space[2],
	background: themeBase.colors.navigationBackground,
	borderBottom: `1px solid ${themeBase.colors.navigationBorder}`,

	[breakpoints.sm]: {
		paddingLeft: themeBase.space[4],
		paddingRight: themeBase.space[4],
	},

	[breakpoints.lg]: {
		gridTemplateColumns: 'auto 1fr auto',
		gap: themeBase.space[2],
		height: '81px',
		paddingRight: themeBase.space[6],
		paddingLeft: themeBase.space[6],
	},
});
