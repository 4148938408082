import FacebookIcon from '~/icons/fb.svg';
import InstagramIcon from '~/icons/instagram.svg';
import LinkedinIcon from '~/icons/linkedin.svg';
import PinterestIcon from '~/icons/pinterest.svg';
import TwitterIcon from '~/icons/twitter.svg';
import YouTubeIcon from '~/icons/youtube.svg';
import { IFooterSocialMediaIcon } from '~/lib/data-contract';
import Link from '~/shared/components/Link/Link';
import { StyledIcon, StyledIcons } from './styled';

export type SocialMediaIconsProps = {
	icons?: IFooterSocialMediaIcon[];
};

export const SocialMediaIcons = ({ icons }: SocialMediaIconsProps) => {
	const handleIcon = (type: string) => {
		switch (type) {
			case 'facebook':
				return <FacebookIcon />;
			case 'instagram':
				return <InstagramIcon />;
			case 'linkedin':
				return <LinkedinIcon />;
			case 'pinterest':
				return <PinterestIcon />;
			case 'twitter':
				return <TwitterIcon />;
			case 'yt':
				return <YouTubeIcon />;
			default:
				return null;
		}
	};

	return (
		<StyledIcons>
			{icons?.map((item) =>
				item.link ? (
					<Link
						key={item.id}
						target="_blank"
						href={item.link.url}
						title={item.link.text}
						isExternal
						isCustomStyle
					>
						<StyledIcon size="lg">{handleIcon(item.icon)}</StyledIcon>
					</Link>
				) : (
					<StyledIcon
						key={item.id}
						size="lg"
					>
						{handleIcon(item.icon)}
					</StyledIcon>
				),
			)}
		</StyledIcons>
	);
};
