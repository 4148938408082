import { useEffect } from 'react';
import { isSSR } from '~/shared/utils/platform/utils/platform';

export const useLayoutScroll = (isScrollable: boolean) => {
	useEffect(() => {
		if (isSSR) return;

		if (!isScrollable) {
			document.documentElement.classList.add('noScroll');
		} else {
			document.documentElement.classList.remove('noScroll');
		}

		return () => document.documentElement.classList.remove('noScroll');
	}, [isScrollable]);
};
