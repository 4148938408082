import styled from '@emotion/styled';
import { StyledText } from '~/shared/components/Text/styled';
import { breakpoints } from '~/theme';

export const StyledItemList = styled.div(({ theme }) => ({
	marginLeft: theme.space[4],

	[`${StyledText}`]: {
		...theme.textStyles.bodyXs,
		minHeight: 0,
		marginBottom: 0,
	},

	[breakpoints.md]: {
		textAlign: 'center',
		marginLeft: 0,
		width: '100%',
		justifyContent: 'space-around',
	},
}));
