import { useMemo } from 'react';
import { BASKET_LIST_ID, BASKET_LIST_NAME } from '~/features/tracking/constants';
import { useAddOrRemoveProductTracking } from '~/features/tracking/hooks/useAddOrRemoveProductTracking';
import { useEcommerceReset } from '~/features/tracking/hooks/useEcommerceReset';
import { useListState } from '~/features/tracking/hooks/useListState';
import { GA4AddToCartEvent, UaAddToCartEvent } from '~/features/tracking/model';
import { createGA4ProductTrackingObject } from '~/features/tracking/utils/createGA4ProductTrackingObject';
import { createProductTrackingObject } from '~/features/tracking/utils/createProductTrackingObject';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import { IBasket, IVariationDetails } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { usePage } from '~/templates/pages/hooks/usePage';

type UseAddProductTrackingOpts = {
	listName?: string;
	listId?: string;
};

export const useAddProductTracking = (variation: IVariationDetails, opts?: UseAddProductTrackingOpts) => {
	const { data: frame } = useFrame();
	const page = usePage();
	const resetEcommerce = useEcommerceReset();
	const trackAddOrRemoveProduct = useAddOrRemoveProductTracking();
	const currency = frame.market?.currency?.id.toUpperCase() || '';
	const { prevListId, prevListName } = useListState();

	const listName = (opts?.listName || prevListName)?.toLowerCase();
	const listId = (opts?.listId || prevListId)?.toLowerCase();

	const isBasketPage = useMemo(() => {
		return page.type === 'p90BasketPage';
	}, [page.type]);

	const track = (quantity: number, basket: IBasket) => {
		trackAddOrRemoveProduct(basket);

		resetEcommerce();
		pushToDataLayer<UaAddToCartEvent>({
			event: 'eec_add',
			ecommerce: {
				currencyCode: currency,
				add: {
					products: [createProductTrackingObject(variation, { quantity })],
					actionField: {
						list: isBasketPage ? BASKET_LIST_NAME : listName,
					},
				},
			},
		});

		resetEcommerce();
		pushToDataLayer<GA4AddToCartEvent>({
			event: 'add_to_cart',
			ecommerce: {
				items: [
					createGA4ProductTrackingObject({
						variation,
						itemListId: isBasketPage ? BASKET_LIST_ID : listId,
						itemListName: isBasketPage ? BASKET_LIST_NAME : listName,
						currency,
						quantity,
					}),
				],
			},
		});
	};

	return track;
};
