import { Notification, NotificationOptions } from './useNotificationModel';
import { useNotificationState } from './useNotificationState';

let key = 0;

/**
 * Exposes functions and notifications
 * Use to update notification state
 */
export const useNotification = () => {
	const { notifications, add, remove } = useNotificationState();

	const push = (notification: NotificationOptions) => {
		// Configure default notification
		const configuredNotification: Notification = {
			text: 'Default text',
			severity: 'error',
			duration: notification.severity === 'info' || notification.severity === 'success' ? 5000 : undefined,
			id: String(key),
			...notification,
		};

		add(configuredNotification);

		key = key + 1;
	};

	return { notifications, push, dismiss: remove };
};
