import { GA4AddOrRemoveProductEvent } from '~/features/tracking/model';
import { createGA4ProductTrackingObject } from '~/features/tracking/utils/createGA4ProductTrackingObject';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import { IBasket } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';

export const useAddOrRemoveProductTracking = () => {
	const { data: frame } = useFrame();
	const currency = frame.market?.currency?.id || '';

	return (basket: IBasket) => {
		const items = basket?.lineItems.map((lineItem) =>
			createGA4ProductTrackingObject({
				currency: currency.toUpperCase(),
				quantity: lineItem.quantity,
				variation: lineItem.variation,
			}),
		);

		pushToDataLayer<GA4AddOrRemoveProductEvent>({
			event: 'cart_items',
			cart: {
				items: items || [],
				value: basket.totals.orderLinesTotal,
			},
		});
	};
};
