import { IImageMedia } from '~/lib/data-contract';

/**
 * No image. Use only if the defaultProductImage provided in the frame is not available
 * 1x1 grey pixel
 */
export const noImage: IImageMedia = {
	origin: 'cms',
	type: 'imageMedia',
	src: '/assets/mock/products/no-img.png',
	width: 1,
	height: 1,
};
