import { useRef, useEffect, useState, VideoHTMLAttributes } from 'react';
import Image from 'next/image';
import { IVideoMedia } from '~/lib/data-contract';
import { buildURL, extractVideoId } from '~/shared/utils/request/utils/buildURL';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { StyledVideoMediaContainer, StyledVideoMediaControl, StyledVideoMediaControls, StyledVideoMediaPlayer } from '../styled';
import Player from '@vimeo/player';
import { useVimeoFile } from '../hooks/useVimeoFile';

export type VideoMediaProps = VideoHTMLAttributes<HTMLVideoElement> & {
	video: IVideoMedia;
	onChangeVideoPlaying?: (value: boolean) => void;
	objectFit?: string;
	sourceAsDataAttr?: boolean;
	showMediaControls?: boolean;
	product?: boolean;
};

export const VideoMediaInView = ({ video, onChangeVideoPlaying, showMediaControls = false }: VideoMediaProps) => {
	const vimeoPlayerRef = useRef<HTMLDivElement | null>(null);
	const vimeoPlayerInstance = useRef<Player | null>(null!);
	const file = useVimeoFile(video?.external?.files);
	const { translate } = useTranslation();
	const [playing, setPlaying] = useState(false);
	const [hovering, setHovering] = useState(false);
	const [showPoster, setShowPoster] = useState(true);
	const observerRef = useRef<IntersectionObserver | null>(null);

	if (!file) {
		return null;
	}

	const videoUrl = buildURL(file.link, { dnt: 1 });
	const videoId = extractVideoId(videoUrl);

	/*
	 * Handle video controls
	 */
	const handleVideoControls = async () => {
		if (!vimeoPlayerInstance.current) {
			return;
		}

		if (showPoster) {
			setShowPoster(false);
		}

		try {
			if (vimeoPlayerInstance.current) {
				const isPaused = await vimeoPlayerInstance.current.getPaused();
				if (isPaused) {
					vimeoPlayerInstance.current?.play();
				} else {
					vimeoPlayerInstance.current?.pause();
				}
				setPlaying(isPaused);
			}
		} catch (error) {
			console.error('Error controlling Vimeo player:', error);
		}
	};

	useEffect(() => {
		if (!vimeoPlayerRef.current || !videoId || vimeoPlayerInstance.current) {
			return;
		}

		vimeoPlayerInstance.current = new Player(vimeoPlayerRef.current, {
			id: Number(videoId),
			transparent: true,
			autoplay: video.autoPlay,
			controls: false,
			muted: true,
			dnt: true,
			loop: video.loop,
			responsive: true,
			playsinline: true,
			portrait: true,
			background: true,
			keyboard: false,
		});

		vimeoPlayerInstance.current.on('play', () => {
			setPlaying(true);
			onChangeVideoPlaying?.(true);
		});

		vimeoPlayerInstance.current.on('pause', () => {
			setPlaying(false);
			onChangeVideoPlaying?.(false);
		});

		vimeoPlayerInstance.current.ready().then(() => {
			if (video.autoPlay) {
				setShowPoster(false);
			}
		});

		// Set up the observer for autoplay
		if (video.autoPlay) {
			observerRef.current = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (vimeoPlayerInstance.current) {
							if (entry.isIntersecting) {
								vimeoPlayerInstance.current
									.play()
									.then(() => {
										setPlaying(true);
										onChangeVideoPlaying?.(true);
									})
									.catch((error) => {
										console.error('Error playing video:', error);
									});
							} else {
								vimeoPlayerInstance.current
									.pause()
									.then(() => {
										setPlaying(false);
										onChangeVideoPlaying?.(false);
									})
									.catch((error) => {
										console.error('Error pausing video:', error);
									});
							}
						}
					});
				},
				{ threshold: 0.4 },
			);

			if (vimeoPlayerRef.current) {
				observerRef.current.observe(vimeoPlayerRef.current);
			}
		}

		// Cleanup on component unmount
		return () => {
			if (vimeoPlayerInstance.current) {
				vimeoPlayerInstance.current.destroy();
				vimeoPlayerInstance.current = null;
			}
			if (observerRef.current && vimeoPlayerRef.current) {
				observerRef.current.unobserve(vimeoPlayerRef.current);
			}
		};
	}, [videoId, observerRef.current, video.autoPlay]);

	return (
		<StyledVideoMediaContainer>
			<Image
				alt={'vimeo poster'}
				src={video.external.previewImageUrl}
				width={0}
				height={0}
				sizes="100vw"
				style={{ objectFit: 'cover', width: '100%', height: '100%', display: showPoster ? 'block' : 'none', border: '3px solid red' }}
			/>
			{videoId && <StyledVideoMediaPlayer ref={vimeoPlayerRef}></StyledVideoMediaPlayer>}
			{!video.autoPlay || showMediaControls ? (
				<StyledVideoMediaControls
					onClick={handleVideoControls}
					onMouseEnter={() => setHovering(true)}
					onMouseLeave={() => setHovering(false)}
				>
					<StyledVideoMediaControl hidden={!hovering && playing}>{playing ? translate('video-player.pause') : translate('video-player.play')}</StyledVideoMediaControl>
				</StyledVideoMediaControls>
			) : null}
		</StyledVideoMediaContainer>
	);
};
