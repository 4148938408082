import styled from '@emotion/styled';
import { themeBase } from '~/theme/lib/themes/themeBase';

// Level 3 is only rendered on desktop
export const StyledNavigationLevel3 = styled.ul({
	display: 'grid',
	gridTemplateColumns: '100%',
	gap: themeBase.space[1],
	marginTop: themeBase.space[1],
});
