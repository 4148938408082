export const MAX_HEIGHT_FALLBACK = { maxHeightScale: 2.25, maxHeightScaleDesktop: 1 };

export const getFallbackMaxHeights = (brandNo: string) => {
	switch (brandNo) {
		case 'jun':
			return { maxHeightScale: 0.5, maxHeightScaleDesktop: 0.35 };
		case 'aj':
			return { maxHeightScale: 0.8, maxHeightScaleDesktop: 0.35 };
		case 'none':
			return { maxHeightScale: 0.35, maxHeightScaleDesktop: 0.17 };
		case 'ros':
			return { maxHeightScale: 0.35, maxHeightScaleDesktop: 0.15 };
		case 'lyp':
			return { maxHeightScale: 2.25, maxHeightScaleDesktop: 1.15 };
		default:
			return MAX_HEIGHT_FALLBACK;
	}
};
