import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { Label } from '../Label/Label';

const StyledShipmentLabel = styled(Label)(
	({ theme }) => ({
		display: 'flex',
		alignItems: 'flex-end',
		flex: '1 0 auto',
		color: theme.colors.productCardShipmentLabel,
	}),
	ifProp('hideOnMobile', {
		display: 'none',

		[breakpoints.md]: {
			display: 'flex',
		},
	}),
	ifProp('hideOnDesktop', {
		display: 'flex',

		[breakpoints.md]: {
			display: 'none',
		},
	}),
);

export const ShipmentLabel = StyledShipmentLabel;
