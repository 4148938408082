import { GetServerSidePropsContext } from 'next';
import { dehydrate } from 'react-query/hydration';
import { IPages } from '~/lib/data-contract';
import { navigationResolver } from '~/templates/pages/utils/navigationResolver';
import { frameResolver } from './frameResolver';
import { ssrQueryClient } from './ssrQueryClient';
import { translationResolver } from './translationResolver';

export const getDehydratedState = async (page: IPages, context: GetServerSidePropsContext) => {
	const frameFetch = frameResolver(context, page);
	const navigationFetch = navigationResolver(context, page);
	const translationFetch = translationResolver(context, page);

	if (!frameFetch.hasPreviousData) {
		await frameFetch.fetchPromise; // Server previous data, stale or not, if available
	}
	if (!navigationFetch.hasPreviousData) {
		await navigationFetch.fetchPromise; // Server previous data, stale or not, if available
	}
	if (!translationFetch.hasPreviousData) {
		await translationFetch.fetchPromise; // Server previous data, stale or not, if available
	}

	const dehydratedState = dehydrate(ssrQueryClient, {
		shouldDehydrateQuery: (query) => query.queryKey === frameFetch.queryKey || query.queryKey === translationFetch.queryKey || query.queryKey === navigationFetch.queryKey,
	});

	return dehydratedState;
};
