import { useCallback } from 'react';
import { IImageMedia, IVariation } from '~/lib/data-contract';
import { ImageMedia } from '~/shared/components/ImageMedia/ImageMedia';
import Link from '~/shared/components/Link/Link';
import { imageSizes } from '../../shared';
import { StyledVariant } from './styled';

export type VariantProps = {
	variation: IVariation;
	onHover: (val: string | null) => void;
	onClick?: () => void;
	fallbackImage: IImageMedia;
};

export const Variant = ({ onHover, onClick, fallbackImage, variation }: VariantProps) => {
	const { id, image, url } = variation;
	const handleMouseEnter = useCallback(() => onHover(id), [id]);
	const handleMouseLeave = useCallback(() => onHover(null), []);
	const handleClick = useCallback(() => (onClick ? onClick() : null), []);

	const imageComponent = (
		<StyledVariant
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			onBlur={handleMouseLeave}
			onClick={handleClick}
		>
			<ImageMedia
				image={image || fallbackImage}
				fill
				objectFit="contain"
				sizes={imageSizes}
			/>
		</StyledVariant>
	);

	if (!url) {
		return imageComponent;
	}

	return (
		<Link
			href={url}
			isCustomStyle
		>
			{imageComponent}
		</Link>
	);
};
