import { useMutation, useQueryClient } from 'react-query';
import { useWishlistKey } from '~/features/wishlist/hooks/useWishlistKey';
import { IVariationDetails, IWishlist } from '~/lib/data-contract';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { removeFromWishlist } from '../service/removeFromWishlist';

type UseRemoveFromWishlistProps = {
	variation: IVariationDetails;
};

export const useRemoveFromWishlist = () => {
	const { market, culture } = useMarket();
	const { queryKey, mutationKeyRemove } = useWishlistKey();

	const queryClient = useQueryClient();

	return useMutation<IWishlist, Error, UseRemoveFromWishlistProps>({
		mutationKey: mutationKeyRemove,
		mutationFn: ({ variation }) => removeFromWishlist(variation.sku, { market, culture }),
		onMutate: async ({ variation }) => {
			await queryClient.cancelQueries(queryKey);

			queryClient.setQueryData<IWishlist | undefined>(queryKey, (prev) => {
				if (prev) {
					return {
						...prev,
						items: prev.items.filter((item) => item.variation.sku !== variation.sku),
					};
				}
			});
		},
		onSuccess: async (data) => {
			await queryClient.cancelQueries(queryKey);
			queryClient.setQueryData<IWishlist>(queryKey, data);
		},
		onError: () => queryClient.invalidateQueries(queryKey),
	});
};
