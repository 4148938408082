import { ILink, ISearchResult, IVariationDetails, SearchResultCategory } from '~/lib/data-contract';

/**
 * Get top 5 results, with the last 2 being pages if any returned
 */
export const getSearchResultLinks = (searchResult?: ISearchResult): { firstPages: ILink[]; products: IVariationDetails[] } => {
	if (!searchResult) {
		return { firstPages: [], products: [] };
	}
	const firstPages =
		searchResult.categories
			.filter(({ category }) => category === SearchResultCategory.contentPage)[0]
			?.items
			.map((item) => {
				const link: ILink = {
					id: item.id,
					text: item.searchTitle,
					url: item.searchUrl,
				};
				return link;
			}) || [];
	const products =
		searchResult.categories
			.filter(({ category }) => category === SearchResultCategory.product)[0]
			?.items
			.map((item) => {
				const variationDetails = item as unknown as IVariationDetails; // TODO: Fix BE model
				return variationDetails;
			}) || [];
	return { firstPages: firstPages.slice(0, 5), products: products.slice(0, 4) };
};
