import { useMarket } from '~/shared/utils/market/hooks/useMarket';

const WISHLIST_KEY = 'RDG_WISHLIST';
const ADD_TO_WISHLIST_KEY = 'RDG_ADD_TO_WISHLIST';
const REMOVE_FROM_WISHLIST_KEY = 'RDG_REMOVE_FROM_WISHLIST';

export const useWishlistKey = () => {
	const { market, culture } = useMarket();

	return {
		queryKey: [WISHLIST_KEY, market, culture].join('-'),
		mutationKeyAdd: [ADD_TO_WISHLIST_KEY, market, culture].join('-'),
		mutationKeyRemove: [REMOVE_FROM_WISHLIST_KEY, market, culture].join('-'),
	};
};
