import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { Icon } from '~/shared/components/Icon/Icon';
import Link from '~/shared/components/Link/Link';
import { ShouldNotForwardProps } from '~/shared/utils/styled/shouldNotForwardProps';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';
import { NavigationItemState } from './../MainMenuNavigation.def';

export const StyledIcon = styled(Icon)<{ isActive: boolean }>(
	{
		width: '16px',
		height: '16px',
		transition: `transform ${themeBase.animations.timingMedium} ${themeBase.animations.easeOutCirc}`,

		[breakpoints.lg]: {
			display: 'none',
		},
	},
	ifProp('isActive', {
		transform: 'rotate(180deg)',
	}),
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - textTransform inside ifProp breaks TS rules
export const StyledMainMenuNavigationLink = styled(Link, {
	shouldForwardProp: ShouldNotForwardProps(['linkState', 'isSingleLine', 'isSubMenu']),
})<{
	linkState: NavigationItemState;
	isSingleLine?: boolean;
	isSubMenu?: boolean;
}>(
	{
		display: 'grid',
		gridTemplateColumns: 'auto 20px',
		alignItems: 'center',
		padding: `${themeBase.space[1]} ${themeBase.space[3]}`,
		textDecoration: 'none',
		fontSize: 12,
		fontWeight: themeBase.fontWeights.regular,
		lineHeight: themeBase.lineHeights.medium,
		transition: `color ${themeBase.animations.timingMedium}, font-weight ${themeBase.animations.timingShort}`,

		// Utilise after for bold hover effect.
		// Smoother transition and avoidance of layout shift
		'&::after': {
			content: 'attr(data-text)',
			height: '0',
			visibility: 'hidden',
			overflow: 'hidden',
			userSelect: 'none',
			pointerEvents: 'none',
			fontWeight: themeBase.fontWeights.bold,

			'@media speech': {
				display: 'none',
			},
		},

		[breakpoints.lg]: {
			// Display flex for ensuring no layout shift on bold text hover effect
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'center',
			height: '100%',
			padding: '0',
		},
	},
	ifProp('isSubMenu', {
		[breakpoints.lg]: {
			padding: 'unset',
			alignItems: 'flex-start',
		},
	}),
	ifProp('isSingleLine', {
		// NoWrap to avoid unexpected word-break on larger text in active state
		// Not applicable if items has longer text which is expected to break
		whiteSpace: 'nowrap',
	}),
	switchProp('linkState', {
		default: {
			color: themeBase.colors.black,
		},
		active: {
			color: `${themeBase.colors.black} !important`, // Important due to specificity war with :hover rules
			fontWeight: themeBase.fontWeights.bold,
		},
		inactive: {
			color: themeBase.colors.grey60,
		},
	}),
);
