import styled from '@emotion/styled';
import { Icon } from '~/shared/components/Icon/Icon';

export const StyledIcons = styled.div(({ theme }) => ({
	color: theme.colors.white,
	display: 'flex',
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
	color: theme.colors.white,
}));
