import { Close } from '@radix-ui/react-dialog';
import { memo } from 'react';
import CloseIcon from '~/icons/close.svg';
import { StyledCloseButton } from './styled';

export const CloseButton = memo(({ onCloseClick }: { onCloseClick?: () => void }) => (
	<Close asChild>
		<StyledCloseButton onClick={onCloseClick}>
			<CloseIcon />
		</StyledCloseButton>
	</Close>
));
