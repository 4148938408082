import { ComponentProps, memo, useCallback, useMemo, useState } from 'react';
import { useBasket } from '~/features/basket/hooks/useBasket';
import UseBasketPaneState, { BasketPaneContentType } from '~/features/basket/hooks/useBasketPaneState';
import { useAddProductTracking } from '~/features/tracking/hooks/useAddProductTracking';
import { useProductClickTracking } from '~/features/tracking/hooks/useProductClickTracking';
import { useWishlistHandlers } from '~/features/wishlist/hooks/useWishlistHandlers';
import { IImageMedia, ISplash, IVariationDetails, IVideoMedia, StockState } from '~/lib/data-contract';
import { AddToBasket } from '~/shared/components/AddToBasket/AddToBasket';
import CampaignTimeframe from '~/shared/components/CampaignTimeframe/CampaignTimeframe';
import ICampaignTimeframe from '~/shared/components/CampaignTimeframe/CampaignTimeframe.def';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden/VisuallyHidden';
import getHasFreeShipping from '~/shared/hooks/getHasFreeShipping';
import { useFormattedPrice } from '~/shared/hooks/useFormattedPrice';
import useShippingPriceMap from '~/shared/hooks/useShippingPriceMap';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { fromVariationDetails } from '../../utils/fromVariationDetails';
import getStockStatusDetermined from '../../utils/stockState/getStockStatusDetermined';
import { useColorVariants } from './../../hooks/useColorVariants';
import { noImage } from './../../utils/noImage';
import { BadgeList } from './components/BadgeList/BadgeList';
import { Brand } from './components/Brand/Brand';
import { Card } from './components/Card/Card';
import { Details, DetailsProps } from './components/Details/Details';
import { Label } from './components/Label/Label';
import { Price } from './components/Price/Price';
import { ShipmentLabel } from './components/ShipmentLabel/ShipmentLabel';
import { Thumbnail } from './components/Thumbnail/Thumbnail';
import { Title } from './components/Title/Title';
import { Variants, VariantsProps } from './components/Variants/Variants';
import {
	StyledProductCardAnchor,
	StyledProductCardContainer,
	StyledProductCardHeader,
	StyledProductCardNewProductBadge,
	StyledProductCardPriceContainer,
	StyledProductCardTaglineContainer,
	StyledProductCardWishlistButton,
} from './styled';

export type ProductCardInnerProps = {
	name: string;
	tagline?: string;
	label?: string;
	badges?: ISplash[];
	image?: IImageMedia;
	hoverImage?: IImageMedia;
	price: number;
	beforePrice?: number;
	variants: VariantsProps['variants'];
	hideVariantPicker?: boolean;
	url?: string;
	brand?: string;
	hideBrandLabel?: boolean;
	background?: 'white';
	className?: string;
	size?: string;
	video?: IVideoMedia;
	imageSizes?: string;
	onProductClick?: () => void;
	onWishlistClick: () => void;
	isInWishlist: boolean;
	newArrival: boolean;
	stockState: StockState;
	stockAvailable: boolean;
	manualOrderItem: boolean;
	onClick?: () => void;
	onAddToBasket?: (quantity: number) => void;
	shippingPrice?: string;
	shouldShowCampaignTimeFrame: boolean;
	campaignTimeframe?: ICampaignTimeframe;
	priority?: boolean;
};

export const ProductCardInner = memo(
	({
		name,
		tagline,
		label,
		badges,
		image: productImage,
		hoverImage,
		variants,
		price: priceRaw,
		beforePrice: beforePriceRaw,
		hideVariantPicker,
		url = '#',
		brand,
		hideBrandLabel,
		size,
		background,
		className,
		video,
		imageSizes,
		onProductClick,
		onWishlistClick,
		isInWishlist,
		newArrival,
		stockState,
		stockAvailable,
		manualOrderItem,
		onClick,
		onAddToBasket,
		shippingPrice,
		shouldShowCampaignTimeFrame,
		campaignTimeframe,
		priority,
	}: ProductCardInnerProps) => {
		const [isHovered, setHovered] = useState(false);
		const [variantId, setVariantId] = useState<string | null>(null);
		const { translate } = useTranslation();

		const isIncludingBrandLabel = !hideBrandLabel && brand;
		const price = useFormattedPrice(priceRaw, true);
		const beforePrice = useFormattedPrice(beforePriceRaw, true);

		const frame = useFrame();
		const fallbackImage = frame.data.vertical?.defaultProductImage || noImage;
		const image = productImage || fallbackImage;

		const variantImages = variants.map(({ image }) => image || fallbackImage);
		const variantImageIndex = variants.findIndex(({ id }) => id === variantId);

		const handleVariantHover = (id: string | null) => {
			setVariantId(id);
			setHovered(true);
		};

		const handleMouseEnter = () => setHovered(true);
		const handleMouseLeave = () => setHovered(false);

		const left = (
			<>
				{isIncludingBrandLabel ? <Brand>{brand}</Brand> : null}
				{tagline ? (
					<StyledProductCardTaglineContainer>
						{newArrival ? <StyledProductCardNewProductBadge>{translate('product-card.news-badge')}</StyledProductCardNewProductBadge> : null}
						<Title
							small
							grey
							uppercase
						>
							{tagline}
						</Title>
					</StyledProductCardTaglineContainer>
				) : null}
				<Title>{name}</Title>
				{label ? <Label hideOnMobile>{label}</Label> : null}
				{shippingPrice ? <ShipmentLabel hideOnMobile>{shippingPrice}</ShipmentLabel> : null}
			</>
		);

		const right = beforePrice ? (
			<>
				<StyledProductCardPriceContainer>
					<Price stockAvailable={stockAvailable}>{price}</Price>
					<Price old>{beforePrice}</Price>
				</StyledProductCardPriceContainer>
				{shouldShowCampaignTimeFrame ? <CampaignTimeframe timeFrame={campaignTimeframe ? { ...campaignTimeframe } : undefined} /> : null}
				{label ? <Label hideOnDesktop>{label}</Label> : null}
				{shippingPrice ? <ShipmentLabel hideOnDesktop>{shippingPrice}</ShipmentLabel> : null}
			</>
		) : (
			<>
				<Price stockAvailable={stockAvailable}>{price}</Price>
				{label ? <Label hideOnDesktop>{label}</Label> : null}
				{shippingPrice ? <ShipmentLabel hideOnDesktop>{shippingPrice}</ShipmentLabel> : null}
			</>
		);

		const detailsProps: DetailsProps = {
			left,
			right,
		};

		if (!hideVariantPicker) {
			detailsProps.picker = (
				<Variants
					variants={variants}
					isHovered={isHovered}
					onVariantHover={handleVariantHover}
					onVariantClick={onProductClick}
					parentSize={size}
				/>
			);
		}

		return (
			<StyledProductCardContainer>
				<Card
					background={background}
					className={className}
					onClick={onClick}
				>
					<StyledProductCardAnchor
						href={url}
						isCustomStyle
						onClick={onProductClick}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						onFocus={handleMouseEnter}
						onBlur={handleMouseLeave}
					>
						<VisuallyHidden>{name}</VisuallyHidden>
					</StyledProductCardAnchor>
					<StyledProductCardHeader>
						{badges ? <BadgeList badges={badges} /> : <div />}
						<StyledProductCardWishlistButton
							active={isInWishlist}
							onClick={onWishlistClick}
						/>
					</StyledProductCardHeader>

					<Thumbnail
						image={image}
						moodImage={hoverImage}
						variantImages={variantImages}
						variantImageIndex={variantImageIndex}
						hovered={isHovered}
						video={video}
						imageSizes={imageSizes}
						priority={priority}
					/>
					<Details {...detailsProps} />
				</Card>

				{onAddToBasket ? (
					<AddToBasket
						isStockAvailable={stockAvailable}
						stockState={stockState}
						isManualOrder={manualOrderItem}
						onConfirm={onAddToBasket}
						small
						variant="outline"
					/>
				) : null}
			</StyledProductCardContainer>
		);
	},
);

export type ProductCardProps = {
	product: IVariationDetails;
	background?: ComponentProps<typeof ProductCardInner>['background'];
	className?: string;
	imageSizes?: string;
	listName?: string;
	listId?: string;
	index?: number;
	raptorSource?: string;
	onClick?: () => void;
	priority?: boolean;
};

export const ProductCard = ({ product, background, className, imageSizes, listName, listId, index, raptorSource, onClick, priority }: ProductCardProps) => {
	const { data: frameData } = useFrame();
	const { numOfColorVariants } = useColorVariants(product.variations, product.sizeAttribute);
	const { translate } = useTranslation();
	const { addItem } = useBasket();
	const trackAddProduct = useAddProductTracking(product, {
		listName,
		listId,
	});
	const trackProductClick = useProductClickTracking(product, {
		listName,
		listId,
		index,
		raptorSource,
	});

	const { openDialog: openBasketPane } = UseBasketPaneState();

	const showBrandLabel = !frameData.vertical?.brandNo || frameData.vertical.brandNo === 'none';
	const label = numOfColorVariants
		? translate('product-card.colors-label', {
				count: numOfColorVariants,
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  })
		: undefined;

	const fromVariationDetailsData = useMemo(() => fromVariationDetails(product, frameData), [product, frameData]);

	// Get stock status to start server side tracking.
	const { stockAvailable, stockState } = getStockStatusDetermined(product.sku, product.stockAvailable, product.stockState);

	const { isInWishlist, handleWishlistClick } = useWishlistHandlers(product);

	const shippingPriceMap = useShippingPriceMap();
	const shippingPrice = useFormattedPrice(shippingPriceMap?.rate, true);
	let shippingPriceLabel: string | undefined;
	if (shippingPrice && shippingPriceMap) {
		shippingPriceLabel = getHasFreeShipping(product, shippingPriceMap)
			? translate('product.shipping-price.label-free')
			: translate('product.shipping-price.label', {
					price: shippingPrice,
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  });
	}

	const basketPaneContentType = useMemo((): BasketPaneContentType => {
		if (fromVariationDetailsData.manualOrderItem) {
			return 'manualOrder';
		}

		if (stockState === 'preorder') {
			return 'preorder';
		}

		return 'normal';
	}, [fromVariationDetailsData.manualOrderItem, stockState]);

	const handleAddToBasket = useCallback(
		(quantity: number) => {
			addItem(
				{ variation: product, quantity },
				{
					onSuccess: ({ basket }) => trackAddProduct(quantity, basket),
				},
			);

			openBasketPane(basketPaneContentType);
		},
		[basketPaneContentType],
	);

	const showCampaignTimeframeDate = product.showSchedulingAndStatusOnProductCardOptions === 1;
	const showCampaignTimeframeUntilSoldOut = product.showSchedulingAndStatusOnProductCardOptions === 2;

	return (
		<ProductCardInner
			onWishlistClick={handleWishlistClick}
			onProductClick={trackProductClick}
			background={background}
			className={className}
			hideBrandLabel={!showBrandLabel}
			imageSizes={imageSizes}
			label={label}
			isInWishlist={isInWishlist}
			onClick={onClick}
			shippingPrice={shippingPriceLabel}
			stockAvailable={stockAvailable}
			stockState={stockState}
			onAddToBasket={handleAddToBasket}
			{...fromVariationDetailsData}
			shouldShowCampaignTimeFrame={showCampaignTimeframeDate || showCampaignTimeframeUntilSoldOut}
			campaignTimeframe={showCampaignTimeframeDate ? {
				fromDateString: product.discountedPriceValidFrom,
				untilDateString: product.discountedPriceValidUntil,
			} : undefined}
			priority={priority}
		/>
	);
};
