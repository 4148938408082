import { CSSProperties, forwardRef, HTMLProps } from 'react';
import { HeaderTag } from '~/lib/data-contract';
import { StyledHeadline } from './styled';
import { useHeadlineAs } from './utils';

export interface HeadlineProps extends Omit<HTMLProps<HTMLHeadingElement>, 'as' | 'size'> {
	/**
	 * The styling size
	 *
	 * If you want to not apply any font-size or override using `css={}` set to `null`
	 */
	size?: 1 | 2 | 3 | 4 | 5 | 6 | null;

	/**
	 * Text alignment
	 */
	alignment?: CSSProperties['textAlign'];
	className?: string;
	as?: HeaderTag;
}

/**
 * Merge of StyledHeadline's own props with those inherited from `E` - the underlying dynamic element type (`"h1"` by default)
 */

/**
 * Use `size` to choose headline style. Markup will follow suit (ie. `size={3}` renderes as `<h3>` by default)
 *
 * Override rendered markup with `as` attribute. For instance you can render a h3 like a size 1:
 *
 * ```tsx
 * <Headline as="h3" size={1}>An h3 in markup. Styled like an h1.</Headline>
 * ```
 */

export const Headline = forwardRef<HTMLHeadingElement, HeadlineProps>(({ size = 1, as, ...restProps }, ref) => {
	const headlineAs = useHeadlineAs({ as, size });
	return (
		<StyledHeadline
			ref={ref}
			size={size}
			as={headlineAs}
			{...restProps}
		/>
	);
});
