import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Content } from '@radix-ui/react-dialog';
import { StyledBrand } from '~/features/products/components/ProductCard/components/Brand/Brand';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { dialogContentEnter, dialogContentExit } from '~/shared/components/Dialog/styled';
import { StyledInput } from '~/shared/components/FormElements/components/InputField/styled';
import { Text } from '~/shared/components/Text/Text';
import { breakpoints } from '~/theme';
import { getColumn, getGutter } from '~/theme/lib/grid';

export const StyledDialogContent = styled(Content)(({ theme }) => ({
	position: 'absolute',
	zIndex: theme.zIndices.dialogOverlay + 1,
	top: 0,
	left: 0,
	width: '100vw',
	height: '100vh',
	overflowY: 'auto',
	'--translateOpenFrom': `0, -10%`,
	'--translateCloseTo': `0, -5%`,

	bottom: 'unset',
	paddingTop: theme.space.dialog.topOffset.sm,

	[breakpoints.md]: {
		top: 0,
		bottom: 'unset',
		paddingTop: theme.space.dialog.topOffset.sm,
	},

	'&[data-state="open"]': {
		animation: `${dialogContentEnter} 0.5s ${theme.animations.easeOutCirc}`,
	},

	'&[data-state="closed"]': {
		animation: `${dialogContentExit} 0.3s ${theme.animations.easeSmooth}`,
	},
}));

export const StyledDialogPane = styled.div(({ theme }) => ({
	position: 'relative',
	background: theme.colors.white,
	color: theme.colors.black,

	[breakpoints.md]: {
		display: 'grid',
		minHeight: '520px',
		gridTemplateRows: 'repeat(4, 1fr)',
		gridTemplateColumns: 'repeat(4, 1fr)',
		gridTemplateAreas: `
			'search-input  search-input    search-input    search-input'
			'quick-links   search-results  search-results  search-results'
			'quick-links   search-results  search-results  search-results'
			'quick-links   search-results  search-results  search-results'
		`,
	},
}));

// Animate in the caret after transform animation is done on container
const caretDelayedDisplay = (caretColor: string) =>
	keyframes({
		to: {
			caretColor: caretColor,
		},
	});

export const StyledSearchInputArea = styled.div(({ theme }) => ({
	gridArea: 'search-input',

	padding: `${theme.space[6]} ${theme.space[9]} ${theme.space[6]} ${theme.space[4]}`,

	[`${StyledInput}`]: {
		// The transform animation on parent breaks caret positioning on iOS.
		// Delay caret display for better UX. Transform animationDuration is 0.5s.
		caretColor: 'transparent',
		animation: `${caretDelayedDisplay(theme.colors.primary)} 0.5s 0.4s forwards`,
	},

	[breakpoints.md]: {
		borderBottom: `solid 1px ${theme.colors.navigationBackground}`,
		padding: 0,
		display: 'grid',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export const StyledQuickLinksArea = styled.div(({ theme }) => ({
	gridArea: 'quick-links',
	backgroundColor: theme.colors.grey10,
	padding: `${theme.space[6]} ${theme.space[4]}`,

	[breakpoints.md]: {
		padding: `${theme.space[6]} ${getGutter()} ${theme.space[6]} ${getColumn(1, true)}`,
	},
}));

export const StyledSearchFirstPagesArea = styled.div(({ theme }) => ({
	gridArea: 'quick-links',
	backgroundColor: theme.colors.navigationBackground,
	padding: `${theme.space[6]} ${theme.space[4]}`,

	[breakpoints.md]: {
		padding: `${theme.space[6]} ${getGutter()} ${theme.space[6]} ${getColumn(1, true)}`,
	},
}));

export const StyledSearchProductsArea = styled.div<{ hasResults: boolean }>(({ theme, hasResults }) => ({
	gridArea: 'search-results',
	padding: `0 ${theme.space[4]}`,
	display: hasResults ? 'block' : 'none',
	marginBottom: theme.space[6],

	[breakpoints.md]: {
		marginBottom: 0,
		padding: `${theme.space[6]} ${getGutter()} ${theme.space[6]} ${getColumn(1, true)}`,
	},
}));

export const StyledCloseArea = styled.div({
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: 0,
	left: 0,
});

export const StyledCloseButton = styled(StyledResetButton)(({ theme }) => ({
	position: 'absolute',
	top: '48px',
	display: 'block',
	right: theme.space[4],
	fontSize: theme.sizes.iconXs,
	padding: '8px',
	strokeWidth: 1.5,

	width: '36px',
	height: '36px',
	borderRadius: '50%',
	boxShadow: 'inset 0 0 0 1.5px currentColor',

	[breakpoints.md]: {
		top: theme.space[6],
		right: theme.space[6],
		strokeWidth: 1,
		padding: 0,
		fontSize: theme.sizes.iconLg,
		boxShadow: 'none',
	},
}));

export const StyledListLabel = styled(Text)(({ theme }) => ({
	color: theme.colors.grey60,
	marginBottom: theme.space[4],
}));

export const StyledProductsList = styled.ol(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
	gap: theme.space[3],
	...theme.textStyles.bodySm,

	'& > :nth-of-type(4)': {
		display: 'none',
	},

	[`${StyledBrand}`]: {
		whiteSpace: 'nowrap',
	},
	whiteSpace: 'normal',

	[breakpoints.lg]: {
		gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
		'& > :nth-of-type(4)': {
			display: 'flex',
		},
	},
}));

export const StyledList = styled.ol(({ theme }) => ({
	display: 'grid',
	gap: theme.space[3],
	...theme.textStyles.bodySm,

	a: {
		fontWeight: theme.fontWeights.regular,
	},
}));

export const StyledSearchProductsCarousel = styled.div({
	display: 'inline-block',
	width: getColumn(3.1),

	':hover': {
		textDecoration: 'none',
		color: 'inherit',
	},

	[breakpoints.sm]: {
		width: getColumn(1.9),
	},

	[breakpoints.md]: {
		width: getColumn(3.21),
	},

	[breakpoints.xl]: {
		width: getColumn(2.2),
	},
});
