import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Icon } from '~/shared/components/Icon/Icon';

export const iconSizes = '50px';

export const StyledSelectContainer = styled.div<{ isFocused: boolean; isInvalid?: boolean }>(({ theme, isFocused, isInvalid }) => ({
	color: isFocused ? theme.forms.textColor : theme.colors.grey60,
	position: 'relative',
	border: '1px solid',
	height: theme.forms.height.default,
	borderColor: isInvalid ? theme.colors.negative : 'currentColor',
}));

export const StyledAltSelectorLabel = styled.label<{ isInvalid?: boolean }>(({ theme, isInvalid }) => ({
	...theme.textStyles.label,
	color: isInvalid ? theme.colors.negative : 'currentColor',
	position: 'absolute',
	top: '-50%',
	left: '15px',
	display: 'flex',
	alignItems: 'center',
	height: '100%',
	zIndex: 100,
	pointerEvents: 'none',

	'&:after': {
		content: '""',
		position: 'absolute',
		left: `calc(${theme.space[1]} * -1)`,
		right: `calc(${theme.space[1]} * -1)`,
		height: '0.6em',
		backgroundColor: theme.colors.bodyBackground,
	},
}));

export const StyledAltSelectorLabelInner = styled.span(({ theme }) => ({
	...theme.textStyles.label,
	display: 'flex',
	alignItems: 'center',
	height: '100%',
	zIndex: 100,
}));

export const StyledControl = styled.div({
	height: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	lineHeight: 1,
});

export const StyledDropdownIndicator = styled(Icon)<{ isFocused: boolean }>(({ isFocused }) => ({
	svg: {
		transform: `rotate(${isFocused ? '180deg' : '0'})`,
	},
}));

export const StyledIndicatorsContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '100%',
	padding: '0 5px',
});

export const StyledOption = styled.div<{ isFocused?: boolean }>(
	({ theme }) => ({
		display: 'grid',
		gridTemplateColumns: 'auto minmax(0, 1fr)',
		alignItems: 'center',
		gap: theme.space[2],
		height: theme.forms.selectorOptionHeight,
		padding: `0 ${theme.space[3]}`,
		lineHeight: 1,
	}),
	ifProp('isFocused', ({ theme }) => ({
		backgroundColor: theme.forms.selectorOptionHighlightBackground,
	})),
);

export const StyledMenu = styled.div(({ theme }) => ({
	padding: 0,
	margin: 0,
	width: '100%',
	backgroundColor: theme.colors.light,
	boxSizing: 'border-box',
	outline: 'none',
	boxShadow: '0 0 14px 0 rgba(0,0,0,0.1), 8px 8px 14px 0 rgba(0,0,0,0.15)',
	maxHeight: theme.forms.selectorPopoverMinHeight,
	overflowY: 'auto',
	marginTop: '1px',
	position: 'relative',
	zIndex: 99999,
}));

export const StyledValueContainer = styled.div({
	height: '100%',
});

export const StyledSingleValue = styled.div(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'auto minmax(0, 1fr)',
	alignItems: 'center',
	gap: theme.space[2],
	padding: `0 ${theme.space[3]}`,
	height: '100%',
	color: theme.forms.textColor,
}));

export const StyledIconWrapper = styled.div(({ theme }) => ({
	width: theme.space[4],
}));
