import { IVariation, IVariationDetails } from '~/lib/data-contract';

export const getColorVariants = (variants: IVariation[], parentSize: IVariationDetails['sizeAttribute']): IVariation[] => {
	const colorVariants = variants.reduce<IVariation[]>((prev, curr) => {
		if (curr.sizeAttribute !== parentSize) {
			return prev;
		}

		if (prev.find((i) => i.color?.colorId === curr.color?.colorId)) {
			return prev;
		}

		return [curr, ...prev];
	}, []);

	return colorVariants;
};
