import { ReactNode } from 'react';
import { StyledButtonGroupContainer, StyledButtonGroupContainerProps } from './styled';

export type ButtonGroupProps = {
	children: ReactNode | ReactNode[];
	cols?: StyledButtonGroupContainerProps['cols'];
};

export const ButtonGroup = ({ children, cols }: ButtonGroupProps) => {
	return <StyledButtonGroupContainer cols={cols}>{children}</StyledButtonGroupContainer>;
};
