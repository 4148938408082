import { useColorVariants } from '~/features/products/hooks/useColorVariants';
import { IVariation, IVariationDetails } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { noImage } from '../../../../utils/noImage';
import { Ellipsis } from '../Ellipsis/Ellipsis';
import { Variant, VariantProps } from '../Variant/Variant';
import { StyledVariants, StyledVariantsInner } from './styled';

export type VariantsProps = {
	isHovered: boolean;
	onVariantHover: VariantProps['onHover'];
	onVariantClick: VariantProps['onClick'];
	variants: IVariation[];
	parentSize: IVariationDetails['sizeAttribute'];
};

export const Variants = ({ isHovered, onVariantHover, onVariantClick, variants, parentSize }: VariantsProps) => {
	const { visibleVariants, numOfHiddenVariants } = useColorVariants(variants, parentSize);
	const frame = useFrame();

	if (!visibleVariants.length) {
		return null;
	}

	const variantFallbackImage = frame.data.vertical?.defaultProductImage || noImage;

	return (
		<StyledVariants>
			<StyledVariantsInner isVisible={isHovered}>
				{visibleVariants.map((item) => (
					<Variant
						variation={item}
						fallbackImage={variantFallbackImage}
						onHover={onVariantHover}
						onClick={onVariantClick}
						key={`product_card_variant_${item.id}`}
					/>
				))}
				{numOfHiddenVariants > 0 ? <Ellipsis value={numOfHiddenVariants} /> : null}
			</StyledVariantsInner>
		</StyledVariants>
	);
};
