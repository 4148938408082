import { ReactNode } from 'react';
import { StyledTable, StyledTableWrapper } from './styled';

export type TableProps = {
	children: ReactNode | ReactNode[];
};

export const Table = ({ children }: TableProps) => {
	return (
		<StyledTableWrapper>
			<StyledTable>{children}</StyledTable>
		</StyledTableWrapper>
	);
};
