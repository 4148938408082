import { IFieldErrors } from '~/lib/data-contract';
import { Step } from '../model/checkoutModel';

export const getIsStepValid = (step: Step, fieldErrors: IFieldErrors | undefined, errors: string[] | undefined): boolean => {
	if (!fieldErrors && !errors) {
		return true;
	}
	if (errors?.length) {
		return false;
	}
	if (fieldErrors) {
		if (step === 'userInfo') {
			const billingAddressErrors = fieldErrors.billingAddress || {};
			const userInfoErrors = fieldErrors.userInfo || {};
			return Object.keys(billingAddressErrors).length === 0 && Object.keys(userInfoErrors).length === 0;
		}
		if (step === 'delivery') {
			const deliveryAddressErrors = fieldErrors.shippingAddress || {};
			return Object.keys(deliveryAddressErrors).length === 0;
		}
	}

	return false;
};
