import { PropsWithChildren } from 'react';
import { StyledTitle, StyledTitleProps } from './styled';

type TitleProps = PropsWithChildren<StyledTitleProps>;

export const Title = ({ children, ...rest }: TitleProps) => {
	return (
		<StyledTitle
			as="div"
			{...rest}
		>
			{children}
		</StyledTitle>
	);
};
