import create from 'zustand';

type MainMenuState = {
	menuIsOpen: boolean;
	setMenuIsOpen: (value: boolean) => void;

	activeItemMain: string | null;
	setActiveItemMain: (id: string | null) => void;

	activeItemSecondary: string | null;
	setActiveItemSecondary: (id: string | null) => void;

	activeItemLevel1: string | null;
	setActiveItemLevel1: (id: string | null) => void;

	activeItemLevel2: string | null;
	setActiveItemLevel2: (id: string | null) => void;

	clearMainMenuStates: () => void;
};

export const useMainMenuState = create<MainMenuState>((set) => ({
	menuIsOpen: false,
	setMenuIsOpen: (isOpen) => {
		set({ menuIsOpen: isOpen });
	},

	activeItemMain: null,
	setActiveItemMain: (id) =>
		set({
			activeItemMain: id,
			activeItemSecondary: null,
			activeItemLevel1: null,
			activeItemLevel2: null,
		}),

	activeItemSecondary: null,
	setActiveItemSecondary: (id) =>
		set({
			activeItemSecondary: id,
			activeItemLevel1: null,
			activeItemLevel2: null,
		}),

	activeItemLevel1: null,
	setActiveItemLevel1: (id) =>
		set({
			activeItemLevel1: id,
			activeItemLevel2: null,
		}),

	activeItemLevel2: null,
	setActiveItemLevel2: (id) =>
		set({
			activeItemLevel2: id,
		}),

	clearMainMenuStates: () =>
		set({
			menuIsOpen: false,
			activeItemMain: null,
			activeItemSecondary: null,
			activeItemLevel1: null,
			activeItemLevel2: null,
		}),
}));

export default useMainMenuState;
