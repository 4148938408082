import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { getFluidFontSize } from '~/theme/lib/getClamp';

export type StyledWishlistButtonProps = {
	active?: boolean;
};

export const StyledWishlistButton = styled(StyledResetButton)<StyledWishlistButtonProps>(
	({ theme }) => ({
		pointerEvents: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.colors.grey60,
		transition: `${theme.animations.easeSmooth} ${theme.animations.timingShort}`,
		fontSize: getFluidFontSize(28, 42),

		'@media (hover: hover) and (pointer: fine)': {
			'&:hover': {
				svg: {
					path: {
						fill: theme.colors.grey40,
					},
				},
			},
		},
	}),
	ifProp('active', ({ theme }) => ({
		svg: {
			path: {
				fill: theme.colors.grey60,
			},
		},
		'@media (hover: hover) and (pointer: fine)': {
			'&:hover': {
				transform: 'scale(1.25)',
				svg: {
					path: {
						fill: theme.colors.grey40,
					},
				},
			},
		},
	})),
);
