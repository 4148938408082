import { ISearchResult, SearchResultCategory } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { useGetRequest } from '~/shared/utils/request/hooks/useGetRequest';

type UseSearchResultsOpts = {
	query?: string;
	pageSize?: number;
	category?: SearchResultCategory;
	onSuccess?: (data: ISearchResult) => void;
};

export const useSearchResults = ({ query = '', pageSize, category, onSuccess }: UseSearchResultsOpts) => {
	const { data: frame } = useFrame();
	const market = useMarket();
	return useGetRequest<ISearchResult>('/api/proxy/search/results', {
		params: {
			frameId: frame.id,
			...market,
			query,
			...(pageSize ? { pageSize } : undefined),
			...(category ? { category } : undefined),
		},
		enabled: !!query.length,
		onSuccess,
	});
};
