import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledResetButton } from '~/shared/components/Button/styled';
import Link from '~/shared/components/Link/Link';
import { ShouldNotForwardProps } from '~/shared/utils/styled/shouldNotForwardProps';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';
import { IHideableOnMobile } from './MainMenuHotLinks.def';

export const StyledMainMenuHotLinks = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	gap: themeBase.space[1],

	[breakpoints.lg]: {
		gap: themeBase.space[2],
	},
});

const iconBaseStyle = css({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	color: 'currentColor',

	gap: 2,

	svg: {
		width: 24,
		height: 24,
	},
});

export const StyledMainMenuHotLinksButton = styled(StyledResetButton)({
	...iconBaseStyle,
});

export const StyledMainMenuHotLinksLink = styled(Link, { shouldForwardProp: ShouldNotForwardProps([]) })<IHideableOnMobile>(
	{
		...iconBaseStyle,
		textDecoration: 'none',
	},
	ifProp('hideOnMobile', {
		display: 'none',

		[breakpoints.lg]: {
			display: 'flex',
		},
	}),
);

export const StyledMainMenuHotLinksCounter = styled.span({
	fontSize: 12,
	lineHeight: '24px', // Match icon size
});

export const StyledMainMenuHotLinksSearchLabel = styled.span({
	display: 'none',
	fontSize: 12,

	[breakpoints.lg]: {
		display: 'unset',
	},
});
