import { useEffect } from 'react';
import { useTimeoutFn } from 'react-use';
import Close from '~/icons/close.svg';
import { NotificationOptions } from '~/shared/hooks/useNotification/useNotificationModel';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { StyleContent, StyledCloseButton, StyledNotification } from '../styled';

type Props = NotificationOptions & {
	onDismiss: () => void;
};

const variants = {
	pre: { opacity: 0 },
	visible: { opacity: 1 },
};

export const Notification = ({ text, onDismiss, onClick, severity = 'error', duration }: Props) => {
	const { translate } = useTranslation();
	const [, cancel, restart] = useTimeoutFn(onDismiss, duration);

	useEffect(() => {
		if (!duration) {
			cancel();
		}
	}, []);

	return (
		<StyledNotification
			layout
			variant={severity}
			variants={variants}
			initial="pre"
			animate="visible"
			exit="pre"
			aria-live="polite"
			role="button"
			tabIndex={0}
			onClick={onClick || onDismiss}
			onFocus={cancel}
			onMouseEnter={cancel}
			onMouseLeave={duration ? restart : undefined}
			onTouchStart={cancel}
			onTouchEnd={duration ? restart : undefined}
		>
			<StyleContent>
				{text}
				<StyledCloseButton
					children={<Close title={translate('accessibility.close-icon-title')} />}
					onClick={onDismiss}
				/>
			</StyleContent>
		</StyledNotification>
	);
};
