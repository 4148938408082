import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

export const StyledLineItemPriceContainer = styled.div({
	display: 'grid',
});

export type StyledLineItemPriceType = 'old' | 'saved' | 'actual';

export type StyledLineItemPriceProps = {
	type: StyledLineItemPriceType;
};

export const StyledLineItemPrice = styled.span<StyledLineItemPriceProps>(
	({ theme }) => ({
		...theme.textStyles.bodyXs,
		textAlign: 'right',
	}),
	switchProp('type', {
		old: ({ theme }) => ({
			textDecoration: 'line-through',
			color: theme.colors.grey60,
		}),
		saved: ({ theme }) => ({
			color: theme.colors.grey60,
		}),
		actual: ({ theme }) => ({
			color: theme.colors.black,
		}),
	}),
);
