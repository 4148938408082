import create from 'zustand';

export type BasketPaneContentType = 'manualOrder' | 'preorder' | 'normal';

type BasketPaneState = {
	isDialogOpen: boolean;
	contentType: BasketPaneContentType;
};

type BasketPaneActions = {
	openDialog: (contentType?: BasketPaneContentType) => void;
	closeDialog: () => void;
};

const useBasketPaneState = create<BasketPaneState & BasketPaneActions>((set) => ({
	isDialogOpen: false,
	contentType: 'normal',
	openDialog: (contentType?: BasketPaneContentType) =>
		set((prevState) => ({
			isDialogOpen: true,
			contentType: contentType ?? prevState.contentType,
		})),
	closeDialog: () => set({ isDialogOpen: false }),
}));

export default useBasketPaneState;
