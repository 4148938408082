import { IVideoMedia } from '~/lib/data-contract';
import { ProductCardInnerProps } from '../../ProductCard';
import { StyledThumbnailInner } from '../Thumbnail/styled';
import { VideoMediaInView } from '~/shared/components/VideoMedia/components/VideoMediaInView';

export type ProductVideoProps = {
	video: IVideoMedia;
	poster?: string;
	background?: ProductCardInnerProps['background'];
};

export const ProductVideo = ({ video, poster, background }: ProductVideoProps) => {
	if (poster && !video.external.previewImageUrl) {
		video.external.previewImageUrl = poster;
	}

	return (
		<StyledThumbnailInner background={background}>
			<VideoMediaInView
				showMediaControls={false}
				video={{ ...video, loop: true, autoPlay: true } as IVideoMedia}
			/>
		</StyledThumbnailInner>
	);
};
