import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { getButtonBaseFontStyle } from '~/shared/components/Button/styled';
import Link from '~/shared/components/Link/Link';
import { breakpoints } from '~/theme';
import { CallToActionProps } from './CallToAction';

type StyledCallToActionContentProps = Pick<CallToActionProps, 'layout'> & {
	shade?: 'light' | 'dark';
};
export const StyledCallToAction = styled(Link)<StyledCallToActionContentProps>(
	getButtonBaseFontStyle,
	({ theme, layout }) => ({
		display: 'inline-flex',
		flexDirection: layout === 'horizontal' ? 'row' : 'column',
		alignItems: 'center',
		letterSpacing: theme.letterSpacing.xs,
		color: 'inherit',
		textDecoration: 'none',

		figure: {
			marginLeft: layout === 'horizontal' ? theme.space['2'] : 'auto',
			transition: `transform ${theme.animations.timingLong} ${theme.animations.easeSmooth}`,
			transform: 'translateX(0px)',

			[breakpoints.md]: {
				marginLeft: layout === 'horizontal' ? theme.space['4'] : 'auto',
			},

			path: {
				strokeDasharray: '42 42',
				strokeDashoffset: '0',
				transition: `stroke-dashoffset ${theme.animations.timingLong} ${theme.animations.easeSmooth}`,
				willChange: 'stroke-dashoffset',
			},
		},

		'&:hover, &:focus': {
			textDecoration: 'none',

			figure: {
				transform: 'translateX(3px)',
				transitionDuration: theme.animations.timingShort,

				path: {
					strokeDashoffset: '10',
					transitionDuration: theme.animations.timingLongest,
					transitionTimingFunction: theme.animations.easeOutCirc,
				},
			},
		},
	}),
	ifProp('shade', ({ shade, theme }) => ({
		color: shade === 'light' ? theme.colors.light : theme.colors.dark,
	})),
);
