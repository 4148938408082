import currency from 'currency.js';

export const add = (num1: number, num2: number) => {
	return currency(num1).add(num2).value;
};

export const multiply = (num1: number, num2: number) => {
	return currency(num1).multiply(num2).value;
};

export const subtract = (num1: number, num2: number) => {
	return currency(num1).subtract(num2).value;
};
