import { GetServerSidePropsContext } from 'next';
import { getCMSData } from '~/lib/api';
import { IGlobalMenu, IPages } from '~/lib/data-contract';
import { getNavigationQueryKey } from '~/shared/utils/navigation/utils/getFrameQueryKey';
import { ssrQueryClient } from './ssrQueryClient';

export const navigationResolver = (context: GetServerSidePropsContext, page: IPages) => {
	const { culture, market } = page;
	const { req, resolvedUrl } = context;
	const queryKey = getNavigationQueryKey(culture, market);
	const fetchPromise = ssrQueryClient.fetchQuery(queryKey, () =>
		getCMSData<IGlobalMenu>(`api/content/navigation`, { culture, market }, req).then(({ status, data }) => {
			if (status !== 200) {
				throw new Error(`Failed to fetch frame ${resolvedUrl}`);
			}
			return data;
		}),
	);
	const hasPreviousData = !!ssrQueryClient.getQueryState(queryKey)?.data;
	return { fetchPromise, hasPreviousData, queryKey };
};
