import { MutableRefObject, useCallback, useRef } from 'react';
import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import { useOrderDraftKey } from './useOrderDraftKey';

/**
 * Helper method for mutations (PUT/POST)
 * @param mutationFn Async method for request that solves or throws
 * @param onSuccess Callback for when the response is successful (use for network-delayed optimistic updates)
 * @param onMutate Callback for when the request is called (use for immediate optimistic updates)
 * @returns
 */
export const useCheckoutMutation = <TError extends Error = Error, TVariables = never, TData = unknown>(
	mutationFn: (ref: MutableRefObject<AbortController | null>) => MutationFunction<TData, TVariables>,
	onSuccess?: (data: TData) => void,
	onMutate?: (data: TVariables) => void,
) => {
	const abortControllerRef = useRef<AbortController | null>(null);
	const { queryKey: orderDraftKey } = useOrderDraftKey();
	const queryClient = useQueryClient();

	const refetchOrderDraft = useCallback(() => {
		queryClient.invalidateQueries(orderDraftKey);
	}, [orderDraftKey]);

	const mutation = useMutation<TData, TError, TVariables>(mutationFn(abortControllerRef), {
		onSuccess: onSuccess,
		onError: () => {
			refetchOrderDraft();
			// TODO: unauthorizedErrorHandler for expired sessions
		},
		onMutate,
	});
	return {
		...mutation,
		cancel: () => {
			abortControllerRef.current?.abort();
		},
	};
};
