import styled from '@emotion/styled';
import { ISplash } from '~/lib/data-contract';
import { breakpoints } from '~/theme';
import { Badge } from '../Badge/Badge';

export const StyledBadgeList = styled.div(({ theme }) => ({
	zIndex: theme.zIndices.productCardBadges,
	display: 'flex',
	flexWrap: 'wrap',
	padding: theme.space['1'],

	[breakpoints.md]: {
		padding: theme.space['2'],
	},
}));

export type BadgeListProps = { className?: string; badges: ISplash[] };

export const BadgeList = ({ className, badges }: BadgeListProps) => (
	<StyledBadgeList className={className}>
		{badges.map((badge) => (
			<Badge
				key={badge.text}
				{...badge}
			/>
		))}
	</StyledBadgeList>
);
