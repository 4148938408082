import styled from '@emotion/styled';

export type StyledButtonGroupContainerProps = {
	cols?: number;
};

export const StyledButtonGroupContainer = styled.div<StyledButtonGroupContainerProps>(({ theme, cols = 2 }) => ({
	display: 'grid',
	gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
	gap: theme.space[2],
}));
