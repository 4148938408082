import { ImageLoader } from 'next/image';
import { allowedQualities, allowedSizes, getComputedAttribute } from './shared';

export const optimizelyLoader: ImageLoader = ({ src, width, quality = 75 }) => {
	const computedWidth = getComputedAttribute(allowedSizes, width);
	const computedQuality = getComputedAttribute(allowedQualities, quality);
	const params = new URLSearchParams({
		w: String(computedWidth),
		q: String(computedQuality),
	});

	return src + '?' + params.toString();
};
