import { useCallback } from 'react';
import { useEcommerceReset } from '~/features/tracking/hooks/useEcommerceReset';
import { useListState } from '~/features/tracking/hooks/useListState';
import { GA4ProductClickEvent, UaProductClickEvent } from '~/features/tracking/model';
import { createGA4ProductTrackingObject } from '~/features/tracking/utils/createGA4ProductTrackingObject';
import { createProductTrackingObject } from '~/features/tracking/utils/createProductTrackingObject';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import { IVariation, IVariationDetails } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { trackRaptorCarouselClickEvent } from '../utils/trackRaptorCarouselClickEvent';

type UseProductClickTrackingOpts = {
	listName?: string;
	listId?: string;
	index?: number;
	raptorSource?: string;
};

export const useProductClickTracking = (variation: IVariationDetails | IVariation, opts?: UseProductClickTrackingOpts) => {
	const { data: frame } = useFrame();
	const currency = frame.market?.currency?.id.toUpperCase() || '';
	const resetEcommerce = useEcommerceReset();
	const { setPrevListId, setPrevListName } = useListState();

	const track = useCallback(() => {
		setPrevListId(opts?.listId);
		setPrevListName(opts?.listName);

		resetEcommerce();

		const listName = opts?.listName?.toLowerCase() || '';

		pushToDataLayer<UaProductClickEvent>({
			event: 'eec_productClick',
			ecommerce: {
				currencyCode: currency,
				click: {
					actionList: {
						list: listName,
					},
					products: [
						createProductTrackingObject(variation, {
							position: opts?.index,
						}),
					],
				},
			},
		});

		resetEcommerce();
		pushToDataLayer<GA4ProductClickEvent>({
			event: 'select_item',
			ecommerce: {
				items: [
					createGA4ProductTrackingObject({
						variation,
						currency: currency.toUpperCase(),
						itemListName: listName,
						itemListId: opts?.listId,
						index: opts?.index,
					}),
				],
			},
		});

		if (opts?.raptorSource) {
			trackRaptorCarouselClickEvent(opts.raptorSource);
		}
	}, [variation, frame, opts?.listId, opts?.index, opts?.listName, opts?.raptorSource]);

	return track;
};
