import { ComponentProps, useMemo } from 'react';
import { ProductVideo } from '~/features/products/components/ProductCard/components/ProductVideo/ProductVideo';
import { IImageMedia, IVideoMedia } from '~/lib/data-contract';
import { ImageMedia } from '~/shared/components/ImageMedia/ImageMedia';
import { useImageLoadingComplete } from '~/shared/hooks/useImageLoadingComplete';
import { ProductCardInner } from '../../ProductCard';
import { imageSizes as defaultImageSizes } from '../../shared';
import { StyledThumbnail, StyledThumbnailInner } from './styled';

export type ThumbnailProps = {
	image: IImageMedia;
	moodImage?: IImageMedia;
	variantImages?: (IImageMedia | undefined)[];
	variantImageIndex?: number;
	video?: IVideoMedia;
	background?: ComponentProps<typeof ProductCardInner>['background'];
	hovered: boolean;
	imageSizes?: string;
	priority?: boolean;
};

export const Thumbnail = ({ image, moodImage, variantImages = [], variantImageIndex, hovered, background, video, imageSizes = defaultImageSizes, priority }: ThumbnailProps) => {
	const { loadListeners, isImageLoaded } = useImageLoadingComplete();

	const variantImage = useMemo(() => {
		const variant = variantImages.find((_, i) => i === variantImageIndex);

		if (!variant) {
			return null;
		}

		return (
			<StyledThumbnailInner background={background}>
				<ImageMedia
					fill
					image={variant}
					sizes={imageSizes}
					objectFit="contain"
				/>
			</StyledThumbnailInner>
		);
	}, [variantImageIndex]);

	const hoverImage = useMemo(() => {
		if (!hovered) {
			return null;
		}

		if (video) {
			return (
				<StyledThumbnailInner background={background}>
					<ImageMedia
						{...loadListeners}
						fill
						image={image}
						sizes={imageSizes}
						objectFit="contain"
					/>
				</StyledThumbnailInner>
			);
		}

		if (moodImage) {
			return (
				<StyledThumbnailInner background={background}>
					<ImageMedia
						fill
						image={moodImage}
						sizes={imageSizes}
						objectFit="cover"
					/>
				</StyledThumbnailInner>
			);
		}

		return null;
	}, [hovered, video, moodImage]);

	const mainImage = useMemo(() => {
		if (video) {
			return (
				<ProductVideo
					video={video}
					poster={image?.src || moodImage?.src}
					background={background}
				/>
			);
		}

		return (
			<StyledThumbnailInner background={background}>
				<ImageMedia
					{...loadListeners}
					fill
					image={image}
					sizes={imageSizes}
					objectFit="contain"
					priority={priority}
				/>
			</StyledThumbnailInner>
		);
	}, [video, image, imageSizes, background]);

	return (
		<StyledThumbnail imageLoaded={isImageLoaded}>
			{mainImage}
			{hoverImage}
			{variantImage}
		</StyledThumbnail>
	);
};
