import { Trigger } from '@radix-ui/react-dialog';
import ChevronDownIcon from '~/icons/chevron-down.svg';
import GlobeIcon from '~/icons/globe.svg';
import { Icon } from '~/shared/components/Icon/Icon';
import { StyledMarketButton, StyledMarketIconAndName } from './styled';

export const MarketSelectorTrigger = ({ marketName }: { marketName?: string }) => {
	return (
		<Trigger asChild>
			<StyledMarketButton>
				<StyledMarketIconAndName>
					<Icon size="md">
						<GlobeIcon />
					</Icon>
					{marketName}
				</StyledMarketIconAndName>
				<Icon size="md">
					<ChevronDownIcon />
				</Icon>
			</StyledMarketButton>
		</Trigger>
	);
};
