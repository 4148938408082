import { memo, useMemo } from 'react';
import getStockStateString from '~/features/products/utils/stockState/getStockStateString';
import { ILineItem } from '~/lib/data-contract';
import { AspectRatio } from '~/shared/components/AspectRatio/AspectRatio';
import { ImageMedia } from '~/shared/components/ImageMedia/ImageMedia';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { LineItemPrice } from '../LineItemPrice/LineItemPrice';
import { imageSizes as styledImageSizes, StyledLineItem, StyledLineItemPreOrder, StyledLineItemThumbnail, StyledLineItemTitle } from './styled';

export type LineItemProps = Pick<ILineItem, 'variation' | 'quantity' | 'linePrice' | 'discountedLinePrice' | 'discount'> & {
	labelType: 'brand' | 'series';
	className?: string;
	imageSizes?: string;
};

export const LineItem = memo(({ variation, quantity, linePrice, discountedLinePrice, discount, labelType, className, imageSizes = styledImageSizes }: LineItemProps) => {
	const { name, image, color, designCollection, brand } = variation;
	const { colorName } = color || {};

	const { translate } = useTranslation();

	const constructedName = useMemo(() => {
		const bits = [name];

		if (colorName) {
			bits.push(colorName);
		}

		return bits.join(', ');
	}, [variation]);

	let label: string | undefined = undefined;

	if (labelType === 'brand' && brand?.name) {
		label = brand.name;
	}

	if (labelType === 'series' && designCollection?.name) {
		label = designCollection.name;
	}

	const stockStateString = getStockStateString(variation.stockAvailable, variation.stockState);

	return (
		<StyledLineItem className={className}>
			<StyledLineItemThumbnail>
				<AspectRatio ratio={70 / 85}>
					{image ? (
						<ImageMedia
							sizes={imageSizes}
							image={image}
							fill
							objectFit="contain"
						/>
					) : null}
				</AspectRatio>
			</StyledLineItemThumbnail>
			<div>
				{label ? <StyledLineItemTitle bold>{label}</StyledLineItemTitle> : null}
				<StyledLineItemTitle as="p">
					{quantity} x {constructedName}
				</StyledLineItemTitle>
				{stockStateString === 'preorder' ? <StyledLineItemPreOrder>{translate('line-item.preorder')}</StyledLineItemPreOrder> : null}
			</div>
			<LineItemPrice
				linePrice={quantity ? linePrice : 0}
				discountedLinePrice={discountedLinePrice}
				discount={discount}
			/>
		</StyledLineItem>
	);
});
