import { IBillingDetailsResponse, IOrderDraftResponse, IShippingDetailsResponse } from '~/lib/data-contract';
import { orderDraftBillingDetailsUpdate } from './orderDraftBillingDetailsUpdate';
import { orderDraftShippingAddressUpdate } from './orderDraftShippingAddressUpdate';

export const orderDraftBillingShippingUpdate = (
	orderDraft: IOrderDraftResponse | undefined,
	billingResponse: IBillingDetailsResponse,
	shippingResponse: IShippingDetailsResponse,
): IOrderDraftResponse | undefined => {
	if (!orderDraft?.data) {
		return;
	}
	let updatedOrderDraft = orderDraftBillingDetailsUpdate(orderDraft, billingResponse);
	updatedOrderDraft = orderDraftShippingAddressUpdate(updatedOrderDraft, shippingResponse);
	updatedOrderDraft!.data!.billingAddressAsShipping = !shippingResponse.data.address;
	return updatedOrderDraft;
};
