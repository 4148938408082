import { forwardRef } from 'react';
import IAspectRatioProps from './AspectRatio.def';
import { StyledAspectRatio, StyledAspectRatioContent } from './styled';

/**
 * Use for any case where a `height` (or `min-height`) determined by a fixed aspect ratio is needed
 *
 * Set widescreen ratio:
 *
 * ```tsx
 * <AspectRatio ratio={16/9}>
 *  { content }
 * </AspectRatio>
 * ```
 *
 * Set ratio, but allow long content to expand height:
 *
 * ```tsx
 * <AspectRatio ratio={16/9} asMinHeight>
 *  { content potentially taller than aspect ratio }
 * </AspectRatio>
 * ```
 */
export const AspectRatio = forwardRef<HTMLDivElement, IAspectRatioProps>(({ ratio, children, fwdCss, ...restProps }, ref) => (
	<StyledAspectRatio
		ratio={ratio}
		{...restProps}
		ref={ref}
	>
		{!!children && <StyledAspectRatioContent css={fwdCss}>{children}</StyledAspectRatioContent>}
	</StyledAspectRatio>
));
