import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { ImageMedia, ImageMediaProps } from '~/shared/components/ImageMedia/ImageMedia';

export const StyledResponsiveImageMedia = styled.div<{ vertical?: boolean }>({
		position: 'relative',

		'.image': {
			objectFit: 'contain' as unknown as 'contain',
			position: 'relative !important' as unknown as 'relative',
		},
},
	ifProp(
		'vertical',
		{
			height: '100%',

			'& .image': {
				height: '100% !important',
				width: 'unset !important',
			},
		},
		{
			width: '100%',

			'& .image': {
				width: '100% !important',
				height: 'unset !important',
			},
		},
	),
);

export type ResponsiveImageMediaProps = Pick<ImageMediaProps, 'image' | 'sizes' | 'onLoad' | 'priority'> & {
	className?: string;
	vertical?: boolean;
};

export const ResponsiveImageMedia = ({ className, vertical, ...props }: ResponsiveImageMediaProps) => (
	<StyledResponsiveImageMedia
		vertical={vertical}
		className={className}
	>
		<ImageMedia
			{...props}
			fill
			className="image"
		/>
	</StyledResponsiveImageMedia>
);
