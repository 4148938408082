import { useEffect } from 'react';
import { useHoverIntent } from 'react-use-hoverintent';

function useNavigationHoverIntent(id: string, setActiveItemMain: (id: string | null) => void) {
	const [isHovering, intentRef] = useHoverIntent({
		timeout: 100,
		sensitivity: 10,
		interval: 100,
	});

	useEffect(() => {
		if (isHovering) {
			setActiveItemMain(id);
		}
	}, [isHovering, id, setActiveItemMain]);

	return intentRef;
}

export default useNavigationHoverIntent;
