import { Accordion } from '@radix-ui/react-accordion';
import { memo } from 'react';
import { useMedia } from 'react-use';
import { lgCondition } from '~/theme/lib/breakpoints';
import useMainMenuState from './../../../useMainMenuState';
import { NavigationItemState } from './../MainMenuNavigation.def';
import INavigationMain from './NavigationMain.def';
import { StyledNavigationMain } from './NavigationMain.styled';
import NavigationMainItem from './NavigationMainItem/NavigationMainItem';

export const NAVIGATION_MAIN_DEFAULT_SECONDARY_ID = 'defaultSecondary';

const NavigationMain = memo(({ nodes, hasMetaHeader, currentVertical }: INavigationMain) => {
	const isMinBreakpointLarge = useMedia(lgCondition, false);
	const activeItemMain = useMainMenuState((state) => state.activeItemMain);

	const useAccordion = !isMinBreakpointLarge;

	const desktopDefaultId = currentVertical && currentVertical.brandNo ? currentVertical.id : nodes[0].id;

	const getLinkState = (id: string): NavigationItemState => {
		if (activeItemMain) {
			return id === activeItemMain ? 'active' : 'inactive';
		}

		if (isMinBreakpointLarge) {
			return id === desktopDefaultId ? 'active' : 'inactive';
		}

		return 'default';
	};

	const renderList = () => (
		<StyledNavigationMain>
			{nodes.map(({ id, subMenu, link }) => (
				<NavigationMainItem
					key={id}
					id={id}
					link={link}
					state={getLinkState(id)}
					defaultSecondaryMenu={currentVertical && !currentVertical.brandNo && id === NAVIGATION_MAIN_DEFAULT_SECONDARY_ID}
					subListNodes={subMenu}
					useAccordion={useAccordion}
					isSingleLine={isMinBreakpointLarge}
					hasMetaHeader={hasMetaHeader}
					currentVertical={currentVertical}
				/>
			))}
		</StyledNavigationMain>
	);

	return useAccordion ? (
		<Accordion
			asChild
			type="single"
			collapsible
			key="navigation-main"
		>
			{renderList()}
		</Accordion>
	) : (
		renderList()
	);
});

export default NavigationMain;
