import { Router } from 'next/router';
import { useCallback, useEffect } from 'react';
import { trackRaptorPageView } from '~/features/raptor/services';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { usePage } from '~/templates/pages/hooks/usePage';

let LOADED = false;

export const useRaptorPageView = () => {
	const page = usePage();
	const { market, culture } = useMarket();

	const handleRouteChangeComplete = useCallback(() => {
		trackRaptorPageView({
			page: page.id,
			contentIds: page.pageElements.map((item) => item.id),
			market,
			culture,
		});
	}, [page.id, page.pageElements, market, culture]);

	useEffect(() => {
		Router.events.on('routeChangeComplete', handleRouteChangeComplete);

		return () => Router.events.off('routeChangeComplete', handleRouteChangeComplete);
	}, [handleRouteChangeComplete]);

	useEffect(() => {
		if (!LOADED) {
			handleRouteChangeComplete();
			LOADED = true;
		}
	}, []);
};
