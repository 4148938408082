import { QuantityInput } from '~/features/products/components/ProductDetails/components/QuantityInput/QuantityInput';
import Check from '~/icons/check.svg';
import { ILineItem, ILink } from '~/lib/data-contract';
import { Button } from '~/shared/components/Button/Button';
import { ButtonGroup } from '~/shared/components/ButtonGroup/ButtonGroup';
import { DialogFooter } from '~/shared/components/Dialog/components/DialogFooter/DialogFooter';
import Link from '~/shared/components/Link/Link';
import { LoadingCircle } from '~/shared/components/Loading/LoadingCircle';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import UseBasketPaneState from '../../hooks/useBasketPaneState';
import { LineItem } from '../LineItem/LineItem';
import { StyledAddToBasketPaneError, StyledAddToBasketPaneTitle, StyledIcon, StyledLineItemWrap } from './styled';

type PaneContentProps = {
	isLoading?: boolean;
	errorMessage?: string;
	lineItem: ILineItem;
	basketLink?: ILink;
	onQuantityChange: (quantity: number) => void;
};

export function PaneContent({ isLoading, errorMessage, lineItem, basketLink, onQuantityChange }: PaneContentProps) {
	const { closeDialog } = UseBasketPaneState();
	const { translate } = useTranslation();
	const { url = '#', ...linkProps } = basketLink || { url: '#' };

	return (
		<>
			<StyledAddToBasketPaneTitle>
				<StyledIcon size="xl">{isLoading ? <LoadingCircle /> : <Check />}</StyledIcon>
				{translate('basket.item-added')}
				{!isLoading && errorMessage ? <StyledAddToBasketPaneError>{errorMessage}</StyledAddToBasketPaneError> : null}
			</StyledAddToBasketPaneTitle>
			{!isLoading ? (
				<StyledLineItemWrap>
					<LineItem
						{...lineItem}
						labelType="series"
					/>
					{!lineItem.isGift ? (
						<QuantityInput
							style={{ marginLeft: 'auto' }}
							value={lineItem.quantity}
							onChange={onQuantityChange}
							size="xs"
						/>
					) : null}
				</StyledLineItemWrap>
			) : null}
			<DialogFooter>
				<ButtonGroup>
					<Button
						variant="outline"
						onClick={closeDialog}
					>
						{translate('basket.continue-shopping')}
					</Button>
					<Button
						as={Link}
						href={url}
						{...linkProps}
					>
						{translate('basket.go-to-checkout')}
					</Button>
				</ButtonGroup>
			</DialogFooter>
		</>
	);
}
