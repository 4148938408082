import { Theme } from '@emotion/react';
import { HeaderTag } from '~/lib/data-contract';
import { HeadlineProps } from './Headline';

type UseHeadlineAsProps = {
	as?: HeaderTag;
	size?: HeadlineProps['size'];
};

export const useHeadlineAs = ({ as: defaultAs, size }: UseHeadlineAsProps) => {
	return defaultAs || ((size && size >= 1 && size <= 6 ? `h${size}` : 'h1') as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6');
};

const DIGIT_REGEX = /\D.*/;

/**
 * Helper method to remove px,%,rem etc from a value
 */
export function numberFromValue(value: string) {
	return Number(value.replace(DIGIT_REGEX, ''));
}

/**
 * Helper method to generate styling for headers.
 * This is not ment to cover 100% of the usecases. Keep simple and hardcode
 * tweaks that do not match the pattern.
 *
 * fontSize: 5xl
 * lineHeight: 6xl/5xl
 *
 * @param fontSizeKey the font size key
 * @param theme the current theme
 */
export function fontStyling(fontSizeKey: keyof Theme['fontSizes'], theme: Theme) {
	return {
		fontSize: theme.fontSizes[fontSizeKey],
		lineHeight: lineHeight(fontSizeKey, theme),
	};
}

/**
 * Helper method to return the line height. It assumes that the line height
 * is calculated based on the next font size.
 *
 * eg when font size is 5xl the line height is 6xl/5xl:
 * fontSize: 5xl
 * lineHeight: 6xl/5xl
 *
 * @param fontSizeKey the font size key
 * @param theme the current theme
 */
export function lineHeight(fontSizeKey: keyof Theme['fontSizes'], theme: Theme) {
	const { fontSizes } = theme;
	const fontSizeKeys = Object.keys(fontSizes) as (keyof Theme['fontSizes'])[];
	const fontSizeKeyIndex = fontSizeKeys.indexOf(fontSizeKey);
	const lineHeightKeyIndex = Math.min(fontSizeKeyIndex + 1, fontSizeKeys.length - 1);
	const lineHeightKey = fontSizeKeys[lineHeightKeyIndex];

	const fontSize = numberFromValue(fontSizes[fontSizeKey]);
	const lineHeight = numberFromValue(fontSizes[lineHeightKey]);

	return Number(lineHeight / fontSize).toFixed(3);
}
