import { IFrame } from '~/lib/data-contract';

/**
 * Ex:
 * const { data: frame } = useFrame();
 * getBrandNameFromFrame(frame, 'kah') -> Kähler
 *
 * Falls back to brandNo if not found, which falls back to Rosendahl.com if not provided
 */
export const getBrandNameFromFrame = (frame: IFrame, brandNo = 'Rosendahl.com') => {
	return frame.verticalSelector?.verticals?.find(({ brand }) => brand?.brandNo === brandNo)?.text ?? brandNo;
};
