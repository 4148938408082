import { useEffect } from 'react';

export const ciccTemplateReservedId = 'cicc-template';

export const CookieConsentCICCTemplate = () => {
	useEffect(() => {
		(window as any).CookieInformation?.loadConsent?.(); // Reload cookie consent script if this div is present
	}, []);
	return <div id={ciccTemplateReservedId}></div>;
};
