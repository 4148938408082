import { ChangeEvent, FocusEventHandler, forwardRef, InputHTMLAttributes, ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CSSProp } from '~/theme';
import { useInputField } from '../../hooks/useInputField';
import { HelpText } from '../HelpText/HelpText';
import { InvalidMessage } from '../InvalidMessage/InvalidMessage';
import {
	InputBoxHeight,
	StyledAdditionalContent,
	StyledInput,
	StyledInputBox,
	StyledInputLabel,
	StyledInputLabelContent,
	StyledInputLabelText,
	StyledInputPrefix,
	StyledInputRoot,
	StyledInputWrap,
} from './styled';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export type InputFieldProps = InputProps & {
	/**
	 * Adds a label to the input field. This is required for accessibilty.
	 */
	label?: string;

	/**
	 * Include label, but keep it visually hidden (for accessibility)
	 */
	hideLabel?: boolean;

	/**
	 * Add an additional help text below the input field.
	 */
	helpText?: string;

	/**
	 * Prefix value with custom text
	 */
	prefix?: string;

	/**
	 * Add an additional help text below the input field.
	 */
	invalidMessage?: string;

	/**
	 * Set styling to indicate input is invalid.
	 * Also shows the `invalidMessage` if provided
	 */
	isInvalid?: boolean;

	/**
	 * This component is controlled - do not provide value
	 */
	// value: string;

	/**
	 * When active the label will move up above the actual input field
	 */
	isActive?: boolean;

	/**
	 * When provided this will overrule internal focus state
	 */
	inFocus?: boolean;

	/**
	 * Will set height of input field
	 *
	 * `'default'`: 58px
	 *
	 * `'compact'`: 50px (same height as buttons)
	 *
	 */
	height?: InputBoxHeight;

	/**
	 * Append a string or a component to the input field
	 */
	append?: ReactNode;

	/**
	 * Like append, but in the beginning
	 */
	prepend?: ReactNode;

	/**
	 * Take over rendering of the input field to fully customize (actual input field will be placed
	 * visually hidden above this)
	 */
	renderInput?: (value: string) => ReactNode;

	/**
	 * If it's a Selector
	 */
	isSelector?: boolean;

	/**
	 * Custom css for wrapper
	 */
	fwdCss?: CSSProp;

	withDisabledStyling?: boolean;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
	(
		{
			label,
			hideLabel,
			helpText,
			prefix,
			invalidMessage,
			id,
			children,
			isActive,
			inFocus,
			isInvalid,
			height = 'default',
			append,
			prepend,
			renderInput,
			disabled,
			placeholder,
			fwdCss,
			onClick,
			onClickCapture,
			type,
			onBlur,
			className,
			withDisabledStyling,
			isSelector,
			onChange,
			value = '',
			...rest
		},
		ref,
	) => {
		const inputRef = useRef<HTMLInputElement | null>(null);

		const [isValidityValid, setIsValidityValid] = useState(true);
		const [hasFocus, setHasFocus] = useState(false);
		const [_value, setValue] = useState<string | undefined>(typeof value === 'string' ? value : undefined);

		const { fieldId, helpTextId, invalidMessageId, describedById, showHelpText, showInvalidMessage } = useInputField({
			id,
			helpText,
			isInvalid,
			invalidMessage,
		});

		useEffect(() => {
			if (inputRef.current && typeof value === 'string' && value.length) {
				inputRef.current.value = value;
				setValue(value);
			}
		}, [value]);

		const { defaultValue } = rest;

		const pristine = typeof _value === 'undefined';
		const hasValue = !!_value || !!(pristine && defaultValue);

		const isInputActive = !!isActive || hasFocus || hasValue || !!inFocus;
		const isValid = !isInvalid && !showInvalidMessage && isValidityValid;

		const onFocusHandler = () => setHasFocus(true);
		const onBlurHandler: FocusEventHandler<HTMLInputElement> = (e) => {
			setHasFocus(false);
			setIsValidityValid(inputRef.current?.validity.valid ? true : false);
			onBlur?.(e);
		};

		const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			setValue(e.target.value);
			onChange?.(e);
		};

		useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

		const inputRefCallback = useCallback((node: HTMLInputElement | null) => {
			if (node !== null) {
				inputRef.current = node;
				setValue(node.value);
			}
		}, []);

		return (
			<StyledInputRoot
				className={className}
				css={fwdCss}
			>
				<StyledInputBox
					key={fieldId}
					isValid={isValid}
					hasValue={hasValue}
					hasFocus={hasFocus || inFocus}
					isActive={isInputActive}
					inactive={disabled || withDisabledStyling}
					height={height}
					onClick={onClick}
					onClickCapture={onClickCapture}
					type={type}
				>
					<StyledInputLabel
						htmlFor={fieldId}
						aria-label={hideLabel ? label : undefined}
					>
						{label && !hideLabel ? (
							<StyledInputLabelContent
								hasFocus={hasFocus || inFocus}
								isActive={isInputActive}
								isValid={isValid}
								inactive={disabled || withDisabledStyling}
							>
								<StyledInputLabelText>{label}</StyledInputLabelText>
							</StyledInputLabelContent>
						) : null}
					</StyledInputLabel>

					{prepend ? (
						<StyledAdditionalContent
							hasFocus={hasFocus || inFocus}
							isActive={isInputActive}
							isValid={isValid}
							children={prepend}
						/>
					) : null}

					{prefix ? <StyledInputPrefix hide={!!label && !hideLabel && !isInputActive}>{prefix}&nbsp;</StyledInputPrefix> : null}

					<StyledInputWrap isSelector={isSelector}>
						{_value && renderInput ? renderInput(_value) : null}
						<StyledInput
							hide={!!_value && !!renderInput}
							withLabel={!!label && !hideLabel}
							withPrefix={!!prefix}
							withAppend={!!append}
							isActive={isInputActive}
							id={fieldId}
							aria-describedby={describedById}
							ref={inputRefCallback}
							onFocus={onFocusHandler}
							onBlur={onBlurHandler}
							disabled={disabled}
							withDisabledStyling={withDisabledStyling}
							placeholder={placeholder}
							type={type}
							onChange={handleChange}
							{...rest}
						/>
					</StyledInputWrap>

					{children}

					{append ? (
						<StyledAdditionalContent
							hasFocus={hasFocus || inFocus}
							isActive={isInputActive}
							isValid={isValid}
							children={append}
						/>
					) : null}
				</StyledInputBox>
				{showInvalidMessage ? (
					<InvalidMessage
						id={invalidMessageId}
						children={invalidMessage}
					/>
				) : null}
				{showHelpText ? (
					<HelpText
						id={helpTextId}
						children={helpText}
					/>
				) : null}
			</StyledInputRoot>
		);
	},
);
