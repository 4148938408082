import { useEffect } from 'react';
import { useCookie } from 'react-use';
import { isSSR } from '~/shared/utils/platform/utils/platform';

export const useGaCookie = () => {
	const [gaCookie, setGaCookie] = useCookie('_ga');

	useEffect(() => {
		if (isSSR) {
			return;
		}

		if (gaCookie) {
			// no op when gaCookie is present on first load
			return;
		}

		// we start an interval, and make it run until the _ga cookie is set.
		const interval = setInterval(() => {
			// we split the cookie string and look for the _ga cookie
			const parts = document.cookie.split('; ');
			const specific = parts.find((part) => part.startsWith('_ga='));

			// we check if the _ga cookie was found.
			if (specific) {
				const [, value] = specific.split('=');

				// we update the useCookie hook, so the newly added _ga cookie is in the state
				setGaCookie(value);

				// we clear the interval, so we are not looking for the cookie anymore
				clearInterval(interval);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return gaCookie;
};
