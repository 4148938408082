import { useCallback, useEffect, useMemo } from 'react';
import { useWishlistTracking } from '~/features/tracking/hooks/useWishlistTracking';
import { useAddToWishlist } from '~/features/wishlist/hooks/useAddToWishlist';
import { useRemoveFromWishlist } from '~/features/wishlist/hooks/useRemoveFromWishlist';
import { useWishlist } from '~/features/wishlist/hooks/useWishlist';
import { IVariationDetails } from '~/lib/data-contract';
import { useNotification } from '~/shared/hooks/useNotification/useNotification';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';

let userNotified = false;

export const useWishlistHandlers = (variation: IVariationDetails) => {
	const wishlist = useWishlist();
	const addToWishlist = useAddToWishlist();
	const removeFromWishlist = useRemoveFromWishlist();
	const notification = useNotification();
	const { translate } = useTranslation();

	const track = useWishlistTracking(variation);

	const isInWishlist = useMemo(() => {
		return Number(wishlist.data?.items.findIndex((item) => item.variation.sku === variation.sku)) > -1;
	}, [variation.sku, wishlist.data?.items]);

	useEffect(() => {
		if (wishlist.status === 'error' && !userNotified) {
			userNotified = true;
			notification.push({
				severity: 'error',
				text: translate('wishlist.error-loading'),
			});
		}
	}, [wishlist.status]);

	const handleWishlistClick = useCallback(() => {
		const args = {
			variation: variation,
		};

		if (isInWishlist) {
			removeFromWishlist.mutate(args);
		} else {
			track();
			addToWishlist.mutate(args);
		}
	}, [variation, isInWishlist]);

	return {
		isInWishlist,
		handleWishlistClick,
	};
};
