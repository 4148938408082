import { memo } from 'react';
import { ILink } from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import useMainMenuState from './../../../useMainMenuState';
import MainMenuToggle from './../../MainMenuToggle/MainMenuToggle';
import { INavigationList, NavigationItemState } from './../MainMenuNavigation.def';
import MainMenuNavigationLink from './../MainMenuNavigationLink/MainMenuNavigationLink';
import { StyledNavigationLevel1, StyledNavigationLevel1Wrapper } from './NavigationLevel1.styled';
import NavigationLevel1Item from './NavigationLevel1Item/NavigationLevel1Item';

const NavigationLevel1 = memo(({ nodes, parentLink, isOpen, trackingHierarchy, hasMetaHeader }: INavigationList) => {
	const { translate } = useTranslation();
	const activeItemLevel1 = useMainMenuState((state) => state.activeItemLevel1);

	const hasSubLists = nodes.filter((node) => !!node.children?.length).length > 0;

	const getLinkState = (id: string): NavigationItemState => {
		return id === activeItemLevel1 ? 'active' : 'inactive';
	};

	const getParentLinkNode = (link: ILink) => {
		const { url: linkUrl, text: linkText, ...linkProps } = link;

		return (
			<li>
				<MainMenuNavigationLink
					{...linkProps}
					href={linkUrl}
					text={translate('navigation.view-parent', { parent: linkText })}
					linkState={!hasSubLists ? 'active' : activeItemLevel1 ? 'inactive' : 'default'}
					isSubMenu
					tracking={{
						hierarchy: trackingHierarchy,
					}}
				/>
			</li>
		);
	};

	return (
		<StyledNavigationLevel1Wrapper
			isOpen={isOpen}
			hasMetaHeader={hasMetaHeader}
			isLastLevel={!hasSubLists}
		>
			<StyledNavigationLevel1 isLastLevel={!hasSubLists}>
				{!hasSubLists ? <MainMenuToggle isSubLevel /> : null}

				{parentLink ? getParentLinkNode(parentLink) : null}

				{nodes.map(({ id, link, children }) => (
					<NavigationLevel1Item
						key={id}
						id={id}
						link={link}
						state={activeItemLevel1 ? getLinkState(id) : 'default'}
						subListNodes={children}
						trackingHierarchy={trackingHierarchy}
					/>
				))}
			</StyledNavigationLevel1>
		</StyledNavigationLevel1Wrapper>
	);
});

export default NavigationLevel1;
