import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { N60Footer } from '~/features/navigation/components/N60Footer/N60Footer';
import { StyledFooter } from '~/features/navigation/components/N60Footer/styled';
import { breakpoints } from '~/theme';
import { getColumn } from '../../../../theme/lib/grid';
import { CheckoutHeader } from './CheckoutHeader/CheckoutHeader';

export type CheckoutLayoutProps = {
	children?: ReactNode;
};
export const StyledCheckout = styled.main({
	display: 'grid',
	gridTemplateRows: `80px minmax(calc(100vh - 80px - 60px), auto) auto`,
	gap: '30px',

	[breakpoints.lg]: {
		gridTemplateRows: `100px minmax(calc(100vh - 100px - 60px), auto) auto`,
	},
});

export const StyledCheckoutContent = styled.div(({ theme }) => ({
	paddingLeft: theme.space[4],
	paddingRight: theme.space[4],

	[breakpoints.md]: {
		paddingLeft: getColumn(0.5),
		paddingRight: getColumn(0.5),
	},

	[breakpoints.lg]: {
		paddingLeft: getColumn(1),
		paddingRight: getColumn(1),
	},
}));

export const StyledCheckoutFooter = styled.div({
	[`${StyledFooter}`]: {
		gridColumnStart: 'auto',
		gridColumnEnd: 'auto',
	},
});

export const CheckoutLayout = ({ children }: CheckoutLayoutProps) => {
	return (
		<StyledCheckout>
			<CheckoutHeader />
			<StyledCheckoutContent>{children}</StyledCheckoutContent>
			<StyledCheckoutFooter>
				<N60Footer></N60Footer>
			</StyledCheckoutFooter>
		</StyledCheckout>
	);
};
