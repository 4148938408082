import { UaEcommerceProduct } from '~/features/tracking/model';
import { IVariation, IVariationDetails } from '~/lib/data-contract';

export const createProductTrackingObject = (variation: IVariationDetails | IVariation, opts?: CreateProductTrackingObjectOptions) => {
	const obj: UaEcommerceProduct = {
		id: variation?.sku?.toLowerCase(),
		name: variation?.name?.toLowerCase(),
		category: variation.itemSubType?.name?.toLowerCase() || '',
		brand: variation.brand?.name?.toLowerCase() || '',
		variant: variation.color?.colorName?.toLowerCase() || '',
		price: String(variation?.discountedPrice || variation?.price),
		dimension10: variation.designCollection?.name?.toLowerCase() || '',
		dimension11: variation.mainMaterial?.name?.toLowerCase() || '',
		dimension12: variation.sizeAttribute?.toLowerCase() || '',
	};

	const { quantity, position, listName } = opts || {};

	if (typeof listName === 'string') {
		obj.list = listName.toLowerCase();
	}

	if (typeof position === 'number') {
		obj.position = position;
	}

	if (typeof quantity === 'number') {
		obj.quantity = quantity;
	}

	return obj;
};
type CreateProductTrackingObjectOptions = {
	quantity?: number;
	position?: number;
	listName?: string;
};
