import { IBasket, IVariationDetails } from '~/lib/data-contract';
import { add, multiply, subtract } from '../../../shared/utils/math/math';
import { findLineItemIndex } from './findLineItemIndex';

export const generateAddMutation = (prev: IBasket, variation: IVariationDetails, quantity: number): IBasket => {
	const mutation: IBasket = {
		...prev,
		lineItems: [...prev.lineItems],
		quantitySum: prev.quantitySum + quantity,
	};

	const existingLineIndex = findLineItemIndex(mutation.lineItems, variation.sku);
	const existingLine = mutation.lineItems[existingLineIndex];

	const linePriceDiff = multiply(variation.price, quantity);
	const discountedLinePriceDiff = typeof variation.discountedPrice === 'number' ? multiply(variation.discountedPrice, quantity) : undefined;
	const discountDiff = typeof variation.discountedPrice === 'number' ? multiply(subtract(variation.price, variation.discountedPrice), quantity) : undefined;

	if (existingLine) {
		const { linePrice, discountedLinePrice, discount, quantity: existingQuantity } = existingLine;

		mutation.lineItems[existingLineIndex] = {
			...existingLine,
			linePrice: add(linePrice, linePriceDiff),
			discountedLinePrice: typeof discountedLinePrice === 'number' && discountedLinePriceDiff ? add(discountedLinePrice, discountedLinePriceDiff) : undefined,
			discount: typeof discount === 'number' && discountDiff ? add(discount, discountDiff) : undefined,
			quantity: add(existingQuantity, quantity),
		};
	} else {
		mutation.lineItems.push({
			id: variation.id,
			unitPrice: variation.price,
			linePrice: linePriceDiff,
			discountedLinePrice: discountedLinePriceDiff,
			discount: discountDiff,
			giftWrappingEnabled: false,
			giftWrappingSelected: false,
			sustainablePackagingEnabled: false,
			sustainablePackagingSelected: false,
			itemReturned: false,
			variation,
			quantity,
			isGift: false,
		});
	}

	const priceDiff = discountedLinePriceDiff ?? linePriceDiff;
	mutation.totals.total = add(mutation.totals.total, discountedLinePriceDiff ?? priceDiff);
	mutation.totals.subTotal = add(mutation.totals.subTotal, priceDiff);
	if (discountDiff) {
		mutation.totals.discountOrderLines = add(mutation.totals.discountOrderLines, discountDiff);
		mutation.totals.discountTotal = add(mutation.totals.discountTotal, discountDiff);
	}

	return mutation;
};
