import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Content, Overlay } from '@radix-ui/react-dialog';
import { transparentize } from 'color2k';
import { breakpoints } from '~/theme';
import { easingGradient } from '~/theme/utils/easingGradient';
import { StyledResetButton } from '../Button/styled';

export const dialogOverlayEnter = keyframes({
	from: {
		opacity: 0,
	},
});

export const dialogOverlayExit = keyframes({
	to: {
		opacity: 0,
	},
});

export const StyledDialogOverlayCover = styled(Overlay)(({ theme }) => ({
	position: 'fixed',
	zIndex: theme.zIndices.dialogOverlay + 1,
	top: 0,
	bottom: 0,
	right: 0,
	left: 0,
	background: transparentize(theme.colors.black, 0.5),

	'&[data-state="open"]': {
		animation: `${dialogOverlayEnter} 0.6s ease-in-out`,
	},

	'&[data-state="closed"]': {
		animation: `${dialogOverlayExit} 0.3s ease-in-out`,
	},
}));

export const dialogContentEnter = keyframes({
	from: {
		transform: `translate(var(--translateOpenFrom))`,
		opacity: 0,
	},
});

export const dialogContentExit = keyframes({
	to: {
		opacity: 0,
		transform: `translate(var(--translateCloseTo))`,
	},
});

export type StyledDialogContentProps = {
	side?: 'left' | 'right';
};

export const StyledDialogContent = styled(Content)<StyledDialogContentProps>(({ theme, side = 'right' }) => {
	const isRightAligned = side === 'right';

	return {
		position: 'fixed',
		zIndex: theme.zIndices.dialogOverlay + 1,
		bottom: 0,
		left: 0,
		width: '100vw',
		height: '100%',
		overflowY: 'auto',
		display: 'grid',
		gridTemplateRows: `${theme.space.dialog.topOffset.xs} 1fr`,
		gridTemplateColumns: `${theme.space.dialog.width.xs}`,
		'--translateOpenFrom': `0, calc(${theme.space.dialog.width.xs} * 0.5)`,
		'--translateCloseTo': `0, calc(${theme.space.dialog.width.xs} * 0.05)`,

		'&:after': {
			content: '""',
			position: 'fixed',
			top: theme.space.dialog.topOffset.xs,
			bottom: 0,
			left: 0,
			right: 0,
			background: theme.colors.white,
			zIndex: -1,
			pointerEvents: 'none',

			[breakpoints.sm]: {
				content: 'none',
				display: 'none',
			},
		},

		[breakpoints.sm]: {
			top: 0,
			bottom: 'unset',
			paddingTop: theme.space.dialog.topOffset.sm,
			display: 'grid',
			gridTemplateColumns: side === 'right' ? `1fr ${theme.space.dialog.width.sm}` : `${theme.space.dialog.width.sm} 1fr`,
			gridTemplateRows: '1fr',
			'--translateOpenFrom': isRightAligned ? `calc(${theme.space.dialog.width.sm} * 0.1), 0` : `calc((${theme.space.dialog.width.sm} * 0.1) * -1), 0`,
			'--translateCloseTo': isRightAligned ? `calc(${theme.space.dialog.width.sm} * 0.05), 0` : `calc((${theme.space.dialog.width.sm} * 0.05) * -1), 0`,
		},

		'&[data-state="open"]': {
			animation: `${dialogContentEnter} 0.5s ${theme.animations.easeOutCirc}`,
		},

		'&[data-state="closed"]': {
			animation: `${dialogContentExit} 0.3s ${theme.animations.easeSmooth}`,
		},
	};
});

export const StyledCloseButton = styled(StyledResetButton)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	right: 0,
	display: 'block',
	margin: theme.space[4],
	padding: 0,
}));

const afterFillAvailableBrowserFix = css(`
	&:after {
		height: 100%;
		height: -webkit-fill-available;
		height: -moz-available;
	}
`);

export type StyledDialogPaneProps = {
	side?: 'left' | 'right';
};

export const StyledDialogPane = styled.div<StyledDialogPaneProps>(afterFillAvailableBrowserFix, ({ theme, side = 'right' }) => {
	const isRightAligned = side === 'right';

	return {
		position: 'relative',
		padding: `${theme.space.dialog.topPadding.xs} ${theme.space[4]} ${theme.space[8]}`,
		background: theme.colors.white,
		display: 'flex',
		flexDirection: 'column',

		table: {
			width: '100%',
		},

		'&:after': {
			content: '""',
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			backgroundImage: easingGradient(['rgba(255,255,255,0)', 'rgba(255,255,255,1)'], 'to bottom'),
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center bottom',
			backgroundSize: `100% ${theme.space[8]}`,
			zIndex: 10,
			pointerEvents: 'none',

			[breakpoints.sm]: {
				backgroundSize: `100% ${theme.space[7]}`,
				width: theme.space.dialog.width.sm,
				[isRightAligned ? 'left' : 'right']: 'unset',
			},
		},

		[breakpoints.sm]: {
			gridColumn: isRightAligned ? 'unset' : 1,
			gridRow: isRightAligned ? 'unset' : 1,
			padding: theme.space[7],
			paddingTop: theme.space.dialog.topPadding.sm,
		},
	};
});
