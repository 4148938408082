import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { ShouldNotForwardProps } from '~/shared/utils/styled/shouldNotForwardProps';
import { mediaQueries } from '~/theme';

type StyledAccordionContentProps = {
	disableAnimation?: boolean;
};

const openAnimation = keyframes({
	from: { height: 0, transform: 'translatey(-5px)', opacity: 0.5, padding: 0 },
	to: { height: 'var(--radix-accordion-content-height)' },
});

const closeAnimation = keyframes({
	from: { height: 'var(--radix-accordion-content-height)' },
	to: { height: 0, transform: 'translatey(-5px)', opacity: 0.5, padding: 0 },
});

export const StyledAccordion = styled(RadixAccordion.Item)(({ theme }) => ({
	width: '100%',
	borderTop: `1px solid ${theme.colors.grey40}`,
	borderBottom: `1px solid ${theme.colors.grey40}`,
	display: 'block',
	listStyle: 'none',

	'& + &': {
		borderTop: 'none',
	},
}));

export const StyledAccordionTrigger = styled(RadixAccordion.Trigger)(({ theme }) => ({
	display: 'grid',
	gap: theme.space[4],
	gridTemplateColumns: '1fr min-content',
	alignItems: 'center',
	padding: `${theme.space[4]} 0`,

	// Button reset
	backgroundColor: 'transparent',
	border: 'none',
	width: '100%',
	cursor: 'pointer',
	textAlign: 'left',
}));

export const StyledAccordionIconWrapper = styled.div(({ theme }) => ({
	/**
	 * Handle icon animations through the data-state
	 * @see https://www.radix-ui.com/docs/primitives/components/accordion#rotated-icon-when-open
	 */
	transition: `transform ${theme.animations.timingMedium} ${theme.animations.easeOutCirc}`, //'transform 300ms',
	'[data-state=open] &': { transform: 'rotate(180deg)' },
}));

export const StyledAccordionContent = styled.div(({ theme }) => ({
	paddingBottom: theme.space[4],
}));

export const StyledAccordionContainer = styled(RadixAccordion.Content, {
	/**
	 * Prevent passing `disableAnimation` to `RadixAccordion.PageContent`.
	 * This will couse an invalid prop error
	 */
	shouldForwardProp: ShouldNotForwardProps(['disableAnimation']),
})<StyledAccordionContentProps>(({ disableAnimation, theme }) => ({
	overflow: 'hidden',
	animationDuration: disableAnimation ? '0ms' : '250ms',
	animationTimingFunction: theme.animations.easeSmooth,

	[mediaQueries.reducedMotion]: {
		animationDuration: '0ms',
	},

	'&[data-state="open"]': {
		animationName: openAnimation,
	},
	'&[data-state="closed"]': {
		animationName: closeAnimation,
	},
}));
