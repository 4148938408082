import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { BASKET_KEY } from '../service/keys';

export const useBasketKey = () => {
	const market = useMarket();
	const key = [BASKET_KEY, market.market];

	return {
		queryKey: key,
		addKey: [...key, 'ADD'],
		updateKey: [...key, 'UPDATE'],
		deleteKey: [...key, 'DELETE'],
		setBasketKey: [...key, 'SET_BASKET'],
	};
};
