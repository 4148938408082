import { AbortSignal } from 'node-fetch/externals';
import { IMarket } from '~/shared/utils/market/types';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { fetcher } from '~/shared/utils/request/utils/fetcher';
import { BASKET_ABANDONED_GET } from './endpoints';

type AbandonedBasketItem = IMarket & {
	variation: string;
	quantity: number;
};

interface GetAbandonedBasketResponse {
	items: AbandonedBasketItem[];
}

const getAbandonedBasket = async (basketRef: string, abortSignal?: AbortSignal) => {
	const url = buildURL(BASKET_ABANDONED_GET.replace('{{basketRef}}', basketRef));

	const request = fetcher<GetAbandonedBasketResponse>(url, {
		signal: abortSignal,
	});

	try {
		return await request();
	} catch(e) {
		return;
	}
};

export default getAbandonedBasket;
