import { WISHLIST_URL } from '~/features/wishlist/constants';
import { useWishlistKey } from '~/features/wishlist/hooks/useWishlistKey';
import { IWishlist } from '~/lib/data-contract';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { useGetRequest } from '~/shared/utils/request/hooks/useGetRequest';
import { buildURL } from '~/shared/utils/request/utils/buildURL';

export const useWishlist = () => {
	const { market, culture } = useMarket();
	const { queryKey } = useWishlistKey();

	return useGetRequest<IWishlist, Error>(
		buildURL(WISHLIST_URL, {
			market,
			culture,
		}),
		{
			queryKey,
		},
	);
};
