import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import IAspectRatioProps from './AspectRatio.def';

export const StyledAspectRatioContent = styled.div({
	position: 'relative',
});

export const StyledAspectRatio = styled.div<IAspectRatioProps>(
	({ ratio }) => ({
		position: 'relative',
		display: 'block',
		overflow: 'hidden',
		width: '100%',

		/**
		 * Usage of pseudo-elements for aspect ratio inspired by:
		 * https://www.trysmudford.com/blog/minimum-aspect-ratio/
		 *
		 * Has 2 benifits:
		 * 1) Allows for content to make container grow (see article)
		 * 2) A side-effect of this approach, is that it is not reliant
		 *    on the containing elment to have `width: 100%`, so setting
		 *    a custom `width` or `max-width` is supported.
		 */
		'&:before': {
			content: '""',
			float: 'left',
			paddingBottom: `${100 / ratio}%`,
		},
		'&:after': {
			content: '""',
			display: 'table',
			clear: 'both',
		},
	}),
	ifProp('asMinHeight', {}, () => ({
		[`${StyledAspectRatioContent}`]: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
	})),
);
