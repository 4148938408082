import { IValidatedBasket } from '~/lib/data-contract';
import { IMarket } from '~/shared/utils/market/types';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { deleteRequest } from '~/shared/utils/request/utils/deleteRequest';
import { BasketError } from './BasketError';
import { BASKET_LINE_URL } from './endpoints';

export async function removeItem(sku: string, market: IMarket) {
	const url = buildURL(`${BASKET_LINE_URL}/${sku}`, { ...market });

	const request = await deleteRequest<IValidatedBasket>(url);
	const response = await request();

	// TODO: Delete item endpoint returns success: true, even when it fails.
	if (!response.success) {
		throw new BasketError(response?.validationMessages?.[0]);
	}

	return response;
}
