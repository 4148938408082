import { memo, useEffect, useRef, useState } from 'react';
import { ILink } from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import MainMenuToggle from './../../MainMenuToggle/MainMenuToggle';
import { INavigationList } from './../MainMenuNavigation.def';
import MainMenuNavigationLink from './../MainMenuNavigationLink/MainMenuNavigationLink';
import { StyledNavigationLevel2, StyledNavigationLevel2Wrapper } from './NavigationLevel2.styled';
import NavigationLevel2Item from './NavigationLevel2Item/NavigationLevel2Item';

const NavigationLevel2 = memo(({ nodes, parentLink, isOpen, trackingHierarchy }: Omit<INavigationList, 'hasMetaHeader'>) => {
	const { translate } = useTranslation();
	const gridRef = useRef<HTMLUListElement | null>(null);
	const [columnsCount, setColumnsCount] = useState<number>(2);

	const setCalculatedColumns = () => {
		if (!gridRef?.current) {
			return;
		}

		const gridHasOverflow = gridRef.current.scrollHeight > gridRef.current.clientHeight;
		if (gridHasOverflow) {
			setColumnsCount(columnsCount + 1);
		}
	};

	useEffect(() => {
		if (isOpen) {
			setCalculatedColumns();
		} else if (columnsCount > 2) {
			setColumnsCount(2);
		}
	}, [isOpen, columnsCount]);

	const getParentLinkNode = (link: ILink) => {
		const { url: linkUrl, text: linkText, ...linkProps } = link;

		return (
			<li>
				<MainMenuNavigationLink
					{...linkProps}
					href={linkUrl}
					text={translate('navigation.view-parent', { parent: linkText })}
					linkState="active"
					tracking={{
						hierarchy: trackingHierarchy,
					}}
				/>
			</li>
		);
	};

	return (
		<StyledNavigationLevel2Wrapper isOpen={isOpen}>
			<MainMenuToggle isSubLevel />

			<StyledNavigationLevel2
				ref={gridRef}
				columnCount={columnsCount}
			>
				{parentLink ? getParentLinkNode(parentLink) : null}

				{nodes.map(({ id, link, children }) => (
					<NavigationLevel2Item
						key={id}
						link={link}
						subListNodes={children}
						trackingHierarchy={trackingHierarchy}
					/>
				))}
			</StyledNavigationLevel2>
		</StyledNavigationLevel2Wrapper>
	);
});

export default NavigationLevel2;
