import { IWishlist } from '~/lib/data-contract';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { deleteRequest } from '~/shared/utils/request/utils/deleteRequest';
import { REMOVE_FROM_WISHLIST_URL } from '../constants';
import { WishlistMarketOptions } from '../model';

export const removeFromWishlist = async (sku: string, opts: WishlistMarketOptions) => {
	const request = deleteRequest<IWishlist>(
		buildURL(REMOVE_FROM_WISHLIST_URL.replace('{{sku}}', sku), {
			...opts,
			sku,
		}),
	);

	const response = await request();

	if (!response) {
		throw new Error();
	}

	return response;
};
