import { IOrderDraftResponse } from '~/lib/data-contract';

export const orderDraftPaymentMethodUpdate = (orderDraft: IOrderDraftResponse | undefined, paymentOptionId: string): IOrderDraftResponse | undefined => {
	if (!orderDraft?.data) {
		return;
	}
	return {
		...orderDraft,
		data: {
			...orderDraft?.data,
			paymentOptions:
				orderDraft.data.paymentOptions.map((option) => ({
					...option,
					selected: option.id === paymentOptionId,
				})) || [],
		},
	};
};
