import styled from '@emotion/styled';
import { StyledHelpText } from '../HelpText/styled';

export const StyledInvalidMessage = styled(StyledHelpText)(({ theme }) => ({
	fontSize: theme.fontSizes['xs'],
	color: theme.colors.negative,
	[`+ ${StyledHelpText}`]: {
		display: 'none',
	},
}));
