import { memo } from 'react';
import { INavigationItem } from './../../MainMenuNavigation.def';
import MainMenuNavigationLink from './../../MainMenuNavigationLink/MainMenuNavigationLink';

const NavigationLevel3Item = memo(({ link, trackingHierarchy }: Pick<INavigationItem, 'link' | 'trackingHierarchy'>) => {
	const { url: linkUrl, text: linkText, ...linkProps } = link;

	return (
		<li>
			<MainMenuNavigationLink
				{...linkProps}
				href={linkUrl}
				text={linkText}
				linkState={'default'}
				tracking={{
					hierarchy: {
						...trackingHierarchy,
						fourthLevel: linkText,
					},
				}}
			/>
		</li>
	);
});

export default NavigationLevel3Item;
