import { fetcher } from './fetcher';

/**
 * Simpler version of a POST request, supplies content type and serializes body to json
 */
export const postRequest = <ResponseBody = unknown, RequestBody = unknown>(url: string, body?: RequestBody, form?: boolean) => {
	const opts: Parameters<typeof fetcher>[1] = {
		method: 'POST',
		headers: {
			'Content-Type': !form ? 'application/json; charset=utf-8' : 'application/x-www-form-urlencoded',
		},
	};

	if (body) {
		opts['body'] = typeof body === 'string' ? body : JSON.stringify(body);
	}

	return fetcher<ResponseBody>(url, opts);
};
