import { useId } from '@radix-ui/react-id';
import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { Headline } from '~/shared/components/Headline/Headline';
import { AccordionContext } from '../../context/AccordionContext';
import { StyledAccordion, StyledAccordionContainer, StyledAccordionContent, StyledAccordionIconWrapper, StyledAccordionTrigger } from './styled';

export type AccordionItemChildrenRenderProps = {
	isOpen: boolean;
	toggle: () => void;
	open: () => void;
	close: () => void;
	id: string;
};

export type AccordionItemProps = {
	/**
	 * Text for the headline OR a component that will replace the default header and be used to trigger for the accordion
	 */
	header?: string | ReactNode;

	/**
	 * Controlls if the accordion is open or not
	 */
	initAsOpen?: boolean;

	/**
	 * Override the unique generated id
	 */
	id?: string;

	/**
	 * Overwrite the default icon
	 */
	icon?: ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);

	/**
	 * Accordion content
	 */
	children?: React.ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);

	/**
	 * Class name for injected styles from emotion.
	 */
	className?: string;
};

/**
 * @description
 * Wraps content (children) in an accordion.
 * Should always be used inside a <Accordion>
 *
 * @example
 * <AccordionItem
 *  icon={<Icon icon="instagram" size="lg" />}
 *  header="Woah this is a cool accordion" />
 *  <p>Accordion body here!</p>
 * </AccordionItem>
 *
 * @example
 * <AccordionItem
 *  icon={<Icon icon="instagram" size="lg" />}
 *  header="Woah this is a cool accordion" />
 *  {({ isOpen }) => (
 *    `This accordion is ${isOpen ? 'open' : 'closed' }!`
 *  )}
 * </AccordionItem>
 */

export const AccordionItem = ({ id: initialId, header, icon, initAsOpen = false, children, className }: AccordionItemProps) => {
	// Ensures present id. Fallback unique id
	const id = useId(initialId);

	const { states, renderAsList, disableAnimation, ...context } = useContext(AccordionContext);

	const isOpen = useMemo(() => states.includes(id), [states]);
	const SemanticTag = useMemo(() => (renderAsList ? 'li' : 'div'), [renderAsList]);

	const methods = useMemo(() => {
		return {
			toggle: () => context.toggle(id),
			open: () => context.open(id),
			close: () => context.close(id),
		};
	}, [id]);

	useEffect(() => {
		initAsOpen && methods.open();
	}, []);

	return (
		<StyledAccordion
			className={className}
			asChild
			value={id}
		>
			<SemanticTag>
				<StyledAccordionTrigger type="button">
					{typeof header === 'string' ? (
						<Headline
							size={5}
							children={header}
						/>
					) : (
						header
					)}
					{!!icon && <StyledAccordionIconWrapper>{typeof icon === 'function' ? icon({ id, isOpen, ...methods }) : icon}</StyledAccordionIconWrapper>}
				</StyledAccordionTrigger>
				<StyledAccordionContainer disableAnimation={disableAnimation}>
					<StyledAccordionContent>{typeof children === 'function' ? children({ id, isOpen, ...methods }) : children}</StyledAccordionContent>
				</StyledAccordionContainer>
			</SemanticTag>
		</StyledAccordion>
	);
};
