import { memo } from 'react';
import { useMedia } from 'react-use';
import MainMenuSearchButton from '~/features/navigation/components/N20MainMenu/components/MainMenuNavigation/MainMenuSearchButton/MainMenuSearchButton';
import { lgCondition } from '~/theme/lib/breakpoints';
import useMainMenuState from './../../useMainMenuState';
import { MENU_ID } from './../MainMenuToggle/MainMenuToggle';
import IMainMenuNavigation from './MainMenuNavigation.def';
import { StyledMainMenuNavigation, StyledMainMenuNavigationWrapper } from './MainMenuNavigation.styled';
import NavigationHotLinks from './NavigationHotLinks/NavigationHotLinks';
import NavigationMain from './NavigationMain/NavigationMain';

const MainMenuNavigation = memo(({ nodes, hasMetaHeader, currentVertical }: IMainMenuNavigation) => {
	const isMinBreakpointLarge = useMedia(lgCondition, false);

	const menuIsOpen = useMainMenuState((state) => state.menuIsOpen);
	const clearMainMenuStates = useMainMenuState((state) => state.clearMainMenuStates);

	return (
		<StyledMainMenuNavigationWrapper
			id={MENU_ID}
			isOpen={menuIsOpen}
			hasMetaHeader={hasMetaHeader}
			role="menubar"
			aria-label="Main navigation"
			aria-hidden={!isMinBreakpointLarge && !menuIsOpen}
		>
			<MainMenuSearchButton onOpen={clearMainMenuStates} />

			<StyledMainMenuNavigation>
				<NavigationMain
					nodes={nodes}
					hasMetaHeader={hasMetaHeader}
					currentVertical={currentVertical}
				/>
			</StyledMainMenuNavigation>

			<NavigationHotLinks />
		</StyledMainMenuNavigationWrapper>
	);
});

export default MainMenuNavigation;
