import { StockState } from '../../../../lib/data-contract';

const getStockStateString = (stockAvailable: boolean, stockState?: number): StockState => {
	if (stockState === 0) {
		return 'available';
	}

	if (stockState === 1) {
		return 'preorder';
	}

	if (stockState === 2) {
		return 'notifyStock';
	}

	if (stockState === 3) {
		return 'comingSoon';
	}

	if (stockState === 4) {
		return 'outOfStock';
	}

	return stockAvailable ? 'available' : 'outOfStock';
};

export default getStockStateString;
