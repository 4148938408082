import { ImageLoader } from 'next/image';
import { allowedSizes, getComputedAttribute } from './shared';

/**
 * Kontainer/ImageBank ids
 *
 * 6821 Webshop 96x192 png
 * 6834 Webshop 384x768 png
 * 6835 Webshop 750x1500 png
 * 6836 Webshop 1080x2160 png
 * 6837 Webshop 1920x3840 png
 * 6838 Webshop 3840x7680 png
 * 7018 Webshop 96x192 jpg
 * 7019 Webshop 384x768 jpg
 * 7020 Webshop 750x1500 jpg
 * 7021 Webshop 1080x2160 jpg
 * 7022 Webshop 1920x3840 jpg
 * 7023 Webshop 3840x7680 jpg
 */

const conversionMapPng: Record<number, number> = {
	96: 6821,
	384: 6834,
	750: 6835,
	1080: 6836,
	1920: 6837,
	3840: 6838,
};

const conversionMapJpg: Record<number, number> = {
	96: 7018,
	384: 7019,
	750: 7020,
	1080: 7021,
	1920: 7022,
	3840: 7023,
};

export const imageBankLoader: ImageLoader = ({ src, width, quality = 75 }) => {
	const parts = src.split('/');
	const ext = parts[parts.length - 1].split('.')[1];
	const isWithSingleHash = parts.length === 6;
	const computedWidth = getComputedAttribute(allowedSizes, width);

	const params = new URLSearchParams();

	switch (true) {
		case isWithSingleHash && ['jpg', 'jpeg'].includes(ext): {
			params.append('d', String(conversionMapJpg[computedWidth]));
			break;
		}
		case isWithSingleHash && ext === 'png': {
			params.append('d', String(conversionMapPng[computedWidth]));
			break;
		}
		default: {
			params.append('w', String(computedWidth));
			// Image bank requires a height to work, while next/image doesn't.
			// Setting the height to a larger number than the width
			// will make the width decide the image dimensions.
			params.append('h', String(computedWidth * 2));
			params.append('q', String(quality));
		}
	}

	return src + '?' + params.toString();
};
