import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { MarketSelectorDialogContent } from '~/features/markets/components/MarketSelectorDialogContent/MarketSelectorDialogContent';
import { MarketSelectorTrigger } from '~/features/markets/components/MarketSelectorTrigger/MarketSelectorTrigger';
import { IPaymentIcon } from '~/lib/data-contract';
import { Dialog } from '~/shared/components/Dialog/Dialog';
import { RawHtml } from '~/shared/components/RawHtml/RawHtml';
import { Text } from '~/shared/components/Text/Text';
import { useRouteTransition } from '~/shared/utils/route-transition/hooks/useRouteTransition';
import { StyledFooterSub, StyledPayments } from './styled';

export type FooterSubnavigationProps = {
	copyright?: string;
	paymentIcons?: IPaymentIcon[];
	marketName?: string;
};

export const FooterSubnavigation = ({ copyright, paymentIcons, marketName }: FooterSubnavigationProps) => {
	const [isMarketDialogOpen, setIsMarketDialogOpen] = useState(false);
	const { loading: transitionLoading } = useRouteTransition();

	useUpdateEffect(() => {
		if (!transitionLoading && isMarketDialogOpen) {
			setIsMarketDialogOpen(false);
		}
	}, [transitionLoading]);

	return (
		<StyledFooterSub>
			{copyright ? <RawHtml html={copyright} /> : null}
			<StyledPayments>
				{paymentIcons?.map((item) => (
					<Text
						key={item.id}
						variant={'bodyXs'}
					>
						{item.title}
					</Text>
				))}
			</StyledPayments>
			<Dialog
				open={isMarketDialogOpen}
				onOpenChange={setIsMarketDialogOpen}
			>
				<MarketSelectorTrigger marketName={marketName} />
				<MarketSelectorDialogContent />
			</Dialog>
		</StyledFooterSub>
	);
};
