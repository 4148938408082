import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Head from 'next/head';
import { useScrollbarWidth } from 'react-use';
import { useFocusMethod } from '~/features/accessibility/hooks/useFocusMethod';

import { CheckoutLayout } from '~/features/checkout/components/CheckoutLayout/CheckoutLayout';
import { NAVIGATION_MAIN_DEFAULT_SECONDARY_ID } from '~/features/navigation/components/N20MainMenu/components/MainMenuNavigation/NavigationMain/NavigationMain';
import N20MainMenu from '~/features/navigation/components/N20MainMenu/N20MainMenu';
import { N21MetaHeader } from '~/features/navigation/components/N21MetaHeader/N21MetaHeader';
import { N60Footer } from '~/features/navigation/components/N60Footer/N60Footer';
import { ILink, INavigationNode, IPages, IVerticalMenu } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useNavigation } from '~/shared/utils/navigation/hooks/useNavigation';
import { PageContent } from '~/templates/blocks/components/PageContent/PageContent';
import { breakpoints } from '~/theme';
import { PageLoadingIndicator } from './PageLoadingIndicator';

type Props = {
	children?: React.ReactNode;
	title?: string;
	isCheckout: boolean;
	page: IPages;
};

interface StyledLayoutProps {
	hasMetaHeader: boolean;
}

export const StyledLayout = styled.div<StyledLayoutProps>(({ theme, hasMetaHeader }) => ({
	position: 'relative',
	display: 'grid',
	gridTemplateColumns: 'minmax(0, 1fr)',
	gridTemplateRows: `${hasMetaHeader ? theme.space.mainNavigationTopOffset : ''} ${theme.space.topMenuHeight} auto auto`,
	minHeight: '100vh',

	[breakpoints.lg]: {
		gridTemplateRows: `${hasMetaHeader ? theme.space.mainNavigationTopOffset : ''} ${theme.space.topMenuHeight} auto auto`,
	},
}));

const createCommerceVerticalMenuPoint = (menuNodes: INavigationNode[] = [], link: ILink): IVerticalMenu => {
	return {
		id: NAVIGATION_MAIN_DEFAULT_SECONDARY_ID,
		link: link,
		subMenu: menuNodes,
		type: 'verticalMenu',
	}
};

export const Layout = ({ children, title = 'Rosendahl.com', isCheckout = false, page }: Props): JSX.Element => {
	const scrollbarWidth = useScrollbarWidth();
	const { colors, textStyles } = useTheme();

	const {
		data: { header: { home } = {}, navigation: { metaMenu, mainMenu } = {}, vertical },
	} = useFrame();
	const {
		data: { verticals = [] },
	} = useNavigation();

	// Enable focus method tracking.
	useFocusMethod(true);

	const isCommerceSite = vertical && !vertical.brandNo;
	const hasMetaHeader = !isCommerceSite && !!metaMenu?.items?.length;

	const menuNodes: IVerticalMenu[] = isCommerceSite ? [createCommerceVerticalMenuPoint(mainMenu, home || verticals[0].link), ...verticals] : verticals

	return (
		<>
			<Head>
				<title>{title}</title>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="initial-scale=1.0, width=device-width"
				/>
			</Head>
			<style
				jsx
				global
			>{`
				html {
					--scrollbar-width: ${scrollbarWidth}px;
				}

				body {
					font-family: ${textStyles.body.fontFamily};
					font-size: ${textStyles.body.fontSize};
					color: ${colors.bodyText};
				}
			`}</style>
			{isCheckout ? (
				<CheckoutLayout>{children}</CheckoutLayout>
			) : (
				<StyledLayout hasMetaHeader={hasMetaHeader}>
					<PageLoadingIndicator />
					{metaMenu?.items?.length ? (
						<N21MetaHeader
							items={metaMenu.items}
							moduleBackgroundColor={metaMenu.moduleBackgroundColor}
						/>
					) : null}
					<N20MainMenu
						menuNodes={menuNodes}
						hasMetaHeader={hasMetaHeader}
						currentVertical={vertical}
					/>
					<PageContent page={page}>{children}</PageContent>
					<N60Footer></N60Footer>
				</StyledLayout>
			)}
		</>
	);
};
