import { memo } from 'react';
import { INavigationItem } from './../../MainMenuNavigation.def';
import MainMenuNavigationLink from './../../MainMenuNavigationLink/MainMenuNavigationLink';
import NavigationLevel3 from './../../NavigationLevel3/NavigationLevel3';

const NavigationLevel2Item = memo(({ link, subListNodes, trackingHierarchy: parentHierarchy }: Omit<INavigationItem, 'id' | 'state' | 'hasMetaHeader'>) => {
	const { url: linkUrl, text: linkText, ...linkProps } = link;

	const hasSubNavList = !!subListNodes?.length;

	const trackingHierarchy = {
		...parentHierarchy,
		thirdLevel: linkText,
	};

	return (
		<li>
			<MainMenuNavigationLink
				{...linkProps}
				href={linkUrl}
				text={linkText}
				linkState="active"
				tracking={{
					hierarchy: trackingHierarchy,
				}}
			/>

			{hasSubNavList ? (
				<NavigationLevel3
					nodes={subListNodes}
					trackingHierarchy={trackingHierarchy}
				/>
			) : null}
		</li>
	);
});

export default NavigationLevel2Item;
