import { useMemo } from 'react';
import { usePreviousDistinct } from 'react-use';
import { IBasket, ILineItem, IVariationDetails } from '~/lib/data-contract';
import { multiply, subtract } from '~/shared/utils/math/math';

const isDiscountedLineItem = (
	lineItem: ILineItem,
): lineItem is Omit<Required<ILineItem>, 'variation'> & {
	variation: Required<IVariationDetails>;
} => {
	return !!lineItem.discount && !!lineItem.discountedLinePrice && !!lineItem.variation.discountedPrice;
};

const calculateLineItemDiscount = (
	lineItem: Omit<ILineItem, 'variation'> & {
		variation: Required<IVariationDetails>;
	},
) => {
	return multiply(lineItem.quantity, subtract(lineItem.variation.price, lineItem.variation.discountedPrice));
};

type UseBasketUpdateDetailsArgs = {
	basket?: IBasket;
	lastAddedItem?: IVariationDetails;
};

type UseBasketUpdateDetailsReturn = {
	lastAddedLine?: ILineItem;
	remainingLineItems?: ILineItem[];
};

export const useBasketUpdateDetails = ({ basket, lastAddedItem }: UseBasketUpdateDetailsArgs) => {
	const prevBasket = usePreviousDistinct(basket, (compare) => compare?.quantitySum !== basket?.quantitySum);

	return useMemo<UseBasketUpdateDetailsReturn>(() => {
		const getLastAdded = (item: ILineItem) => item.variation.id === lastAddedItem?.id;

		let remainingLineItems: ILineItem[] | undefined;
		let lastAddedLine = basket?.lineItems.find(getLastAdded);
		const prevLastAddedLine = prevBasket?.lineItems.find(getLastAdded);

		if (lastAddedLine && lastAddedLine.id === prevLastAddedLine?.id) {
			// item added was already in basket.
			// we want to show the recently added quantity.
			lastAddedLine = {
				...lastAddedLine,
				quantity: lastAddedLine.quantity - prevLastAddedLine.quantity,
			};

			if (isDiscountedLineItem(lastAddedLine)) {
				lastAddedLine.discountedLinePrice = multiply(lastAddedLine.variation.discountedPrice, lastAddedLine.quantity);

				lastAddedLine.discount = calculateLineItemDiscount(lastAddedLine);
			}

			lastAddedLine.linePrice = multiply(lastAddedLine.variation.price, lastAddedLine.quantity);

			remainingLineItems = basket?.lineItems.map((item) => {
				if (item.id === lastAddedLine?.id) {
					const existingLineItem = {
						...item,
						quantity: item.quantity - lastAddedLine.quantity,
					};

					if (isDiscountedLineItem(existingLineItem)) {
						existingLineItem.discountedLinePrice = multiply(existingLineItem.variation.discountedPrice, existingLineItem.quantity);

						existingLineItem.discount = calculateLineItemDiscount(existingLineItem);
					}

					existingLineItem.linePrice = multiply(existingLineItem.variation.price, existingLineItem.quantity);

					return existingLineItem;
				}

				return item;
			});
		} else {
			remainingLineItems = basket?.lineItems.filter((item) => item.variation.id !== lastAddedItem?.id);
		}

		return {
			lastAddedLine,
			remainingLineItems,
		};
	}, [basket, prevBasket, lastAddedItem]);
};
