import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Accordion } from '~/shared/components/Accordion/Accordion';
import { AccordionItem } from '~/shared/components/Accordion/components/AccordionItem/AccordionItem';
import { StyledAccordionTrigger } from '~/shared/components/Accordion/components/AccordionItem/styled';
import { breakpoints } from '~/theme';

export const StyledFooterMenuMobile = styled(Accordion)({
	display: 'block',

	[breakpoints.md]: {
		display: 'none',
	},
});
export const StyledFooterMenuDesktop = styled.div<{ hasBorder?: boolean }>(
	{
		position: 'relative',
		display: 'none',

		[breakpoints.md]: {
			display: 'block',
		},
	},
	ifProp('hasBorder', ({ theme }) => ({
		':before': {
			position: 'absolute',
			content: '""',
			height: '100%',
			width: '1px',
			background: theme.colors.grey60,
			left: 'calc(var(--grid-gutter) * -1)',
		},
	})),
);

export const StyledFooterAccordionItem = styled(AccordionItem)(({ theme }) => ({
	borderTop: `1px solid ${theme.colors.grey60}`,
	borderBottomWidth: 0,

	[`${StyledAccordionTrigger}`]: {
		color: theme.colors.white,
		padding: `${theme.space[2]} 0`,
	},
}));
