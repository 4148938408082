import { StockState, TranslationKey } from '../../../../lib/data-contract';

const getStockStateTranslationKey = (state: StockState): TranslationKey => {
	if (state === 'available') {
		return 'product.stock.available';
	}

	if (state === 'comingSoon') {
		return 'product.stock.coming-soon';
	}

	if (state === 'notifyStock') {
		return 'product.stock.notify';
	}

	if (state === 'preorder') {
		return 'product.stock.preorder';
	}

	return 'product.stock.out-of-stock';
};

export default getStockStateTranslationKey;
