import styled from '@emotion/styled';
import { WishlistButton } from '~/features/wishlist/components/WishlistButton/WishlistButton';
import { StyledButton } from '~/shared/components/Button/styled';
import Link from '~/shared/components/Link/Link';
import { breakpoints } from '~/theme';

export const StyledProductCardContainer = styled.div(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: '100%',

	[`${StyledButton}`]: {
		margin: `${theme.space[2]} ${theme.space[1]} 0`,

		[breakpoints.md]: {
			margin: `${theme.space[3]} ${theme.space[2]} 0`,
		},
	},
}));

export const StyledProductCardPriceContainer = styled.div(({ theme }) => ({
	display: 'flex',
	gap: theme.space['1'],

	[breakpoints.md]: {
		display: 'block',
	},
}));

export const StyledProductCardAnchor = styled(Link)(({ theme }) => ({
	'&:after': {
		zIndex: theme.zIndices.productCardLink,
		content: '""',
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
	},
}));

export const StyledProductCardTaglineContainer = styled.div(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.space[1],
	flexWrap: 'wrap',
}));

export const StyledProductCardNewProductBadge = styled.span(({ theme }) => ({
	fontSize: theme.fontSizes['3xs'],
	lineHeight: theme.lineHeights.single,
	padding: theme.space[1],
	background: theme.colors.grey30,
	color: theme.colors.dark,
	textTransform: 'uppercase',
}));

export const StyledProductCardHeader = styled.div(({ theme }) => ({
	position: 'absolute',
	top: 0,
	right: 0,
	left: 0,
	zIndex: theme.zIndices.productCardVariants,
	pointerEvents: 'none',
	display: 'grid',
	gridTemplateColumns: '1fr minmax(0, auto)',
	alignItems: 'start',
}));

export const StyledProductCardWishlistButton = styled(WishlistButton)(({ theme }) => ({
	padding: theme.space['1'],
	paddingLeft: 0,

	[breakpoints.md]: {
		padding: theme.space['2'],
		paddingLeft: 0,
	},
}));
