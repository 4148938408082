import { IValidatedBasket } from '~/lib/data-contract';
import { IMarket } from '~/shared/utils/market/types';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { postRequest } from '~/shared/utils/request/utils/postRequest';
import { BASKET_LINE_URL } from './endpoints';

export async function addItem(sku: string, quantity: number, market: IMarket) {
	const url = buildURL(BASKET_LINE_URL, {
		...market,
		sku,
		quantity,
	});

	const request = postRequest<IValidatedBasket>(url);

	const response = await request();
	if (!response.success) {
		throw new Error(response.validationMessages?.[0] || 'unknown');
	}

	return response;
}
