import { useEffect, useMemo } from 'react';
import { useGetRequest } from '~/shared/utils/request/hooks/useGetRequest';
import { usePage } from '~/templates/pages/hooks/usePage';
import { requestDefaults } from '../../request/utils/requestDefaults';
import { Translations } from '../model/translationModel';
import { getTranslationQueryKey } from '../utils/getTranslationQueryKey';
import { translateFactory } from '../utils/translateFactory';

let previousTranslations: Translations;

/**
 * Translation hook, will load initial translations provided by context on initial load, and fetch new values when culture changes
 * Always try to provide a value, even if it's from the previously loaded translations, but the isLoading will indicate
 * if it's serving stale data
 *
 * Use double curly brackets for a dynamic value in the translated text {{property}} and pass an object in the code with matching property name
 */
export const useTranslation = () => {
	const { culture } = usePage();

	const queryKey = getTranslationQueryKey(culture);

	const { data } = useGetRequest<Translations>('/api/proxy/content/translations', {
		params: { culture },
		queryKey,
		keepPreviousData: true,
		staleTime: requestDefaults.cacheTime, // Never stale until cache expires
		cacheTime: requestDefaults.cacheTime,
		refetchOnMount: false,
	});

	useEffect(() => {
		if (data && previousTranslations !== data) {
			previousTranslations = data;
		}
	}, [data]);

	const translate = useMemo(() => ({ translate: translateFactory(data ?? previousTranslations) }), [data]);

	return translate;
};
