import { ReactNode } from 'react';

export type TableHeadProps = {
	children: ReactNode | ReactNode[];
};

export const TableHead = ({ children }: TableHeadProps) => (
	<thead>
		<tr>{children}</tr>
	</thead>
);
