import { IShippingAddressRequest, IShippingDetailsResponse } from '~/lib/data-contract';
import { IMarket } from '~/shared/utils/market/model/marketModel';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { putRequest } from '~/shared/utils/request/utils/putRequest';
import { getIsStepValid } from '../utils/getIsStepValid';
import { UpdateShippingAddressError } from './UpdateShippingAddressError';

export const updateShippingAddress = async (data: IShippingAddressRequest | null, market: IMarket, ignoreCompanyNameError?: boolean) => {
	const url = buildURL('/api/proxy/checkout/shipping/address', {
		...market,
	});

	const request = putRequest<IShippingDetailsResponse, IShippingAddressRequest | null>(url, data);

	let response: ReturnTypeAwaited<typeof request>;

	try {
		response = await request();
	} catch (err) {
		throw new UpdateShippingAddressError({ unknownError: true });
	}

	if (ignoreCompanyNameError) {
		delete response.fieldErrors.shippingAddress?.companyName;
	}

	const isStepValid = getIsStepValid('delivery', response.fieldErrors, response.errors);
	if (!isStepValid) {
		const { shippingAddress } = response.fieldErrors || {};
		const general = response.errors;
		throw new UpdateShippingAddressError({ shippingAddress, general });
	}

	return response;
};
