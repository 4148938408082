import { useEffect, useState } from 'react';
import { IBasket } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { useBasket } from '../../hooks/useBasket';
import useBasketPaneState from '../../hooks/useBasketPaneState';
import { useBasketUpdateDetails } from '../../hooks/useBasketUpdateDetails';
import { PaneContent } from './PaneContent';

function findBasketLineItem(basket?: IBasket, lineItemId?: string) {
	if (!basket || !lineItemId) return undefined;

	return basket?.lineItems.find(({ id }) => id == lineItemId);
}

export const AddToBasketPane = () => {
	const [isMutatingQuantity, setIsMutatingQuantity] = useState(false);

	const { translate } = useTranslation();
	const { lastAddedItem, removeItem, updateItem, error, data, isMutating } = useBasket();
	const { closeDialog } = useBasketPaneState();
	const { data: frame } = useFrame();

	const { lastAddedLine } = useBasketUpdateDetails({
		basket: data,
		lastAddedItem,
	});

	// "lastAddedLine" returns the changed quantity rather then // the absolute value
	const lineItem = findBasketLineItem(data, lastAddedLine?.id);

	useEffect(() => {
		const closingTimeout = setTimeout(closeDialog, 5000);
		return () => clearTimeout(closingTimeout);
	}, [lineItem?.quantity]);

	if (!lineItem) {
		return <div>{error || translate('basket.add-item-error')}</div>;
	}

	function handleRemovingItem() {
		if (!lineItem) return;

		closeDialog();
		setIsMutatingQuantity(true);

		setTimeout(() => {
			removeItem({ variation: lineItem.variation });
		}, 200);
	}

	function handleUpdatingItem(quantity: number) {
		if (!lineItem) return;

		setIsMutatingQuantity(true);

		updateItem(
			{
				variation: lineItem.variation,
				quantity,
			},
			{
				onSettled: () => setIsMutatingQuantity(false),
			},
		);
	}

	function onQuantityChangeHandler(quantity: number) {
		quantity ? handleUpdatingItem(quantity) : handleRemovingItem();
	}

	return (
		<PaneContent
			isLoading={isMutating && !isMutatingQuantity}
			errorMessage={error}
			lineItem={lineItem}
			basketLink={frame.header?.basket}
			onQuantityChange={onQuantityChangeHandler}
		/>
	);
};
