import { FC, MouseEvent, SyntheticEvent, useCallback, useRef, useState } from 'react';
import { isMobileDevice } from '~/shared/utils/platform';
import IImageMagnifier, { ImageMagnifierSize, ImageMagnifierTransform } from './ImageMagnifier.def';
import { StyledImageMagnifier, StyledImageMagnifierCursorWindow, StyledImageMagnifierWrapper } from './ImageMagnifier.styled';

const getTranslateValue = (scale: number, offsetPct: number) => {
	return `-${(1 - 1 / scale) * offsetPct}%`;
};

const getImageOffsetPct = (offsetPctX: number, offsetPctY: number, imageRatioWidth: number, imageRatioHeight: number, focal: IImageMagnifier['focal']) => {
	const widthIsCompromised = imageRatioWidth > imageRatioHeight;
	const heightIsCompromised = imageRatioHeight > imageRatioWidth;

	let imgOffsetPctX = offsetPctX;
	if (widthIsCompromised) {
		const croppedWidth = (1 - imageRatioWidth / imageRatioWidth) * 100;
		const imgOffsetX = croppedWidth / 2;
		const heightRatioCompromised = imageRatioWidth / imageRatioWidth;
		imgOffsetPctX = (imgOffsetPctX - imgOffsetX) * heightRatioCompromised;
	}

	let imgOffsetPctY = offsetPctY;
	if (heightIsCompromised) {
		const croppedHeight = (1 - imageRatioWidth / imageRatioHeight) * 100;
		const imgOffsetY = croppedHeight / 2;
		const heightRatioCompromised = imageRatioHeight / imageRatioWidth;
		imgOffsetPctY = (imgOffsetPctY - imgOffsetY) * heightRatioCompromised;
	}

	if (focal) {
		if (typeof focal.x === 'number') {
			imgOffsetPctX += (focal.x - 50) * imageRatioWidth;
		}

		if (typeof focal.y === 'number') {
			imgOffsetPctY += (focal.y - 50) * imageRatioHeight;
		}
	}

	return {
		x: imgOffsetPctX,
		y: imgOffsetPctY,
	};
};

const ImageMagnifier: FC<IImageMagnifier> = ({ imageUrl, alt, loader, imageProps, imageStyles, focal, type = 'cover', activateOnClick }) => {
	const [showMagnifier, setShowMagnifier] = useState(false);
	const [imageUrlHashed, setImageUrlHashed] = useState(imageUrl);
	const [imageSizeOriginal, setImageSizeOriginal] = useState<ImageMagnifierSize>();
	const [imageTransform, setImageTransform] = useState<ImageMagnifierTransform>({ scale: '1', translateX: '0', translateY: '0' });
	const [magnifierActivated, setMagnifierActivated] = useState(!activateOnClick);
	const toggleIsMagnified = () => {
		setMagnifierActivated(!magnifierActivated);
	};

	const shouldShowMagnifier = showMagnifier && magnifierActivated;

	const cursorWindowRef = useRef<HTMLDivElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);

	const handleImageOnLoad = (e: SyntheticEvent<HTMLImageElement>) => {
		const hashedImgSrc = e.currentTarget.src;

		const newImg = new Image();
		newImg.onload = () => {
			setImageSizeOriginal({ width: newImg.width, height: newImg.height });
		};
		newImg.src = hashedImgSrc;

		setImageUrlHashed(hashedImgSrc);
	};

	const handleMouseEnter = () => {
		setShowMagnifier(true);
	};

	const handleMouseLeave = () => {
		setShowMagnifier(false);
	};

	const handleMouseMove = useCallback(
		(event: MouseEvent) => {
			if (!event.currentTarget) {
				return;
			}

			const targetArea = event.currentTarget.getBoundingClientRect();

			const documentScrollX = window.document.documentElement.scrollLeft;
			const documentScrollY = window.document.documentElement.scrollTop;
			const offsetPctX = ((event.pageX - targetArea.left - documentScrollX) / targetArea.width) * 100;
			const offsetPctY = ((event.pageY - targetArea.top - documentScrollY) / targetArea.height) * 100;

			if (type === 'cover') {
				if (!imageRef.current) {
					return;
				}

				// Potentially improve make dynamic zoom based on container and window size
				const scale = 3;
				setImageTransform({
					scale: `${scale}`,
					translateX: getTranslateValue(scale, offsetPctX),
					translateY: getTranslateValue(scale, offsetPctY),
				});

				imageRef.current;

				return;
			}

			if (!cursorWindowRef.current || !imageSizeOriginal) {
				return;
			}

			const imageRatioWidth = targetArea.width / imageSizeOriginal.width;
			const imageRatioHeight = targetArea.height / imageSizeOriginal.height;

			const { x: imgOffsetPctX, y: imgOffsetPctY } = getImageOffsetPct(offsetPctX, offsetPctY, imageRatioWidth, imageRatioHeight, focal);

			cursorWindowRef.current.style.left = `${offsetPctX}%`;
			cursorWindowRef.current.style.top = `${offsetPctY}%`;
			cursorWindowRef.current.style.backgroundPosition = `${imgOffsetPctX}% ${imgOffsetPctY}%`;
		},
		[cursorWindowRef.current, imageSizeOriginal],
	);

	return (
		<StyledImageMagnifierWrapper
			onMouseEnter={handleMouseEnter}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			onClick={activateOnClick && !isMobileDevice ? toggleIsMagnified : undefined}
		>
			{type === 'loop' ? (
				<StyledImageMagnifierCursorWindow
					ref={cursorWindowRef}
					imageUrl={imageUrlHashed}
					showMagnifier={shouldShowMagnifier}
				/>
			) : null}

			<StyledImageMagnifier
				ref={imageRef}
				loader={loader}
				src={imageUrl}
				alt={alt}
				isMagnified={activateOnClick ? shouldShowMagnifier : undefined}
				{...imageProps}
				style={{
					...imageStyles,
					transform:
						type === 'cover' && shouldShowMagnifier ? `scale(${imageTransform.scale}) translate3d(${imageTransform.translateX}, ${imageTransform.translateY}, 0)` : 'scale(1) translate3d(0, 0, 0)',
				}}
				onLoad={type === 'loop' ? handleImageOnLoad : undefined}
			/>
		</StyledImageMagnifierWrapper>
	);
};

export default ImageMagnifier;
