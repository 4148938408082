import styled from '@emotion/styled';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { breakpoints } from '~/theme';

export const StyledMarketButton = styled(StyledResetButton)(({ theme }) => ({
	lineHeight: 'inherit',
	order: -1,
	display: 'flex',
	gap: theme.space[1],
	alignItems: 'center',
	minWidth: '280px',
	padding: `${theme.space[1]} ${theme.space[2]}`,
	border: '1px solid #929192',
	height: `${theme.space[7]}`,
	borderRadius: `calc(${theme.space[7]} / 2)`,
	marginBottom: theme.space[4],
	color: theme.colors.white,
	justifyContent: 'space-between',
	...theme.textStyles.bodyXs,
	fontSize: theme.fontSizes['2xs'],

	[breakpoints.md]: {
		order: 'initial',
		marginBottom: 0,
		minWidth: '190px',
		fontSize: theme.fontSizes.xs,
	},
}));

export const StyledMarketIconAndName = styled.span(({ theme }) => ({
	display: 'flex',
	gap: theme.space[1],
	alignItems: 'center',
	lineHeight: 1,
}));
