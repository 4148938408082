import { getHomeUrl } from '~/features/navigation/utils/getHomeUrl';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useNavigation } from '~/shared/utils/navigation/hooks/useNavigation';
import { StyledLogo, StyledLogoLink, StyledMainMenuLogoTagLine } from './MainMenuLogo.styled';

const MainMenuLogo = () => {
	const {
		data: { header: { tagLine } = {} },
	} = useNavigation();
	const { data: { header: { home } = {} } } = useFrame();

	return (
		<StyledLogoLink href={getHomeUrl(home)}>
			<StyledLogo />
			<StyledMainMenuLogoTagLine>{tagLine}</StyledMainMenuLogoTagLine>
		</StyledLogoLink>
	);
};

export default MainMenuLogo;
