import ChevronDown from '~/icons/chevron-down.svg';
import { INavigationNode } from '~/lib/data-contract';
import { Icon } from '~/shared/components/Icon/Icon';
import { Text } from '~/shared/components/Text/Text';
import { FooterNavigation } from '../FooterNavigation/FooterNavigation';
import { StyledFooterAccordionItem, StyledFooterMenuDesktop, StyledFooterMenuMobile } from './styled';

export type FooterNavigationContainerProps = {
	title?: string;
	navigationItems?: INavigationNode[];
	enableMultiColumns?: boolean;
	hasBorder?: boolean;
};

export const FooterNavigationContainer = ({ navigationItems, title, enableMultiColumns, hasBorder }: FooterNavigationContainerProps) => {
	if (!title && !navigationItems) {
		return null;
	}

	return (
		<>
			<StyledFooterMenuMobile>
				<StyledFooterAccordionItem
					header={
						<Text
							as="h4"
							variant="bodySmBold"
						>
							{title}
						</Text>
					}
					initAsOpen={false}
					icon={
						<Icon size={'lg'}>
							<ChevronDown />
						</Icon>
					}
				>
					<FooterNavigation navigationItems={navigationItems}></FooterNavigation>
				</StyledFooterAccordionItem>
			</StyledFooterMenuMobile>

			<StyledFooterMenuDesktop hasBorder={hasBorder}>
				<FooterNavigation
					title={title}
					navigationItems={navigationItems}
					enableMultiColumns={enableMultiColumns}
				></FooterNavigation>
			</StyledFooterMenuDesktop>
		</>
	);
};
