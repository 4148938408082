import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledDetails = styled.div(({ theme }) => ({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	flex: '1 0 auto',
	boxSizing: 'border-box',
	padding: theme.space['1'],
	paddingBottom: '0',

	[breakpoints.md]: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		padding: theme.space['2'],
		paddingBottom: '0',
	},
}));

export const StyledDetailsLeft = styled.div({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
});

export const StyledDetailsRight = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 0 auto',

	[breakpoints.md]: {
		marginLeft: '8px',
		marginTop: '18px',
		textAlign: 'right',
	},
});
