import { ResetEcommerceEvent } from '~/features/tracking/model';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';

export const useEcommerceReset = () => {
	return () => {
		pushToDataLayer<ResetEcommerceEvent>({
			ecommerce: null,
		});
	};
};
