import ChevronLeft from '~/icons/chevron-left.svg';
import { StyledN55BreadcrumbIcon, StyledN55BreadcrumbLink } from '../../../templates/blocks/components/N55Breadcrumb/styled';

export const BreadcrumbLink = ({ url, text, className, omitIcon }: { url: string; text: string; className?: string; omitIcon?: boolean }) => {
	return (
		<StyledN55BreadcrumbLink
			href={url}
			isCustomStyle
			className={className}
		>
			{!omitIcon ? (
				<StyledN55BreadcrumbIcon>
					<ChevronLeft />
				</StyledN55BreadcrumbIcon>
			) : null}
			<span>{text}</span>
		</StyledN55BreadcrumbLink>
	);
};
