import LinkIcon from '~/icons/link.svg';
import { ICallToAction } from '~/lib/data-contract';
import { Icon } from '~/shared/components/Icon/Icon';
import { StyledCallToAction } from './styled';

export type CallToActionProps = Partial<ICallToAction> & {
	layout?: 'vertical' | 'horizontal';
	className?: string;
	children?: React.ReactNode;
	shade?: 'dark' | 'light';
};

export const CallToAction = ({ target = '_self', text, title, url, layout = 'horizontal', className, children, shade }: CallToActionProps) => (
	<>
		{url ? (
			<StyledCallToAction
				href={url}
				target={target}
				title={title}
				layout={layout}
				className={className}
				shade={shade}
			>
				{text}
				{children}
				<Icon aspectRatio={2}>
					<LinkIcon />
				</Icon>
			</StyledCallToAction>
		) : null}
	</>
);
