import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';
import { StyledMainMenuNavigationLink } from './../MainMenuNavigationLink/MainMenuNavigationLink.styled';

const wrapperPaddingDesktopVertical = themeBase.space[5];
const wrapperPaddingDesktopHorizontal = themeBase.space[6];
const gridColumnWidth = '175px';
const gridColumnGap = themeBase.space[8];
const gridPaddingAdditional = themeBase.space[7];

export const StyledNavigationLevel2Wrapper = styled.div<{ isOpen?: boolean }>(
	{
		paddingTop: themeBase.space[1],
		paddingBottom: themeBase.space[3],
		paddingLeft: themeBase.space[4],
		background: themeBase.colors.navigationBackground,
		textTransform: 'none',

		[breakpoints.lg]: {
			position: 'absolute',
			top: '0',
			left: `calc(-${gridColumnWidth} * 2 - ${gridColumnGap} - ${gridPaddingAdditional} - ${wrapperPaddingDesktopHorizontal} * 2 - 2px)`, // offset 2px for border right, ensuring out of screen
			zIndex: '-1',
			height: `100%`,
			borderRight: `1px solid ${themeBase.colors.grey30}`,
			padding: `${wrapperPaddingDesktopVertical} ${wrapperPaddingDesktopHorizontal}`,
			overflow: 'hidden',
			background: themeBase.colors.grey10,
			transition: `left ease-out ${themeBase.animations.timingMedium}`,
		},
	},
	ifProp('isOpen', {
		[breakpoints.lg]: {
			left: 'var(--navigation-pane-width)',
		},
	}),
);

export const StyledNavigationLevel2 = styled.ul<{ columnCount: number }>(
	{
		display: 'grid',
		gridTemplateColumns: '100%',
		gap: themeBase.space[1],

		[breakpoints.lg]: {
			maxHeight: '100%',
			gap: `${themeBase.space[6]} ${gridColumnGap}`,
			gridAutoRows: 'max-content',
			paddingRight: gridPaddingAdditional,

			[`${StyledMainMenuNavigationLink}`]: {
				height: 'auto',
				width: 'auto',
			},

			// Parent link aka. "See all"
			'> li:first-of-type': {
				order: '1',
				gridColumn: '1',
			},
		},
	},
	ifProp('columnCount', ({ columnCount }) => ({
		gridTemplateColumns: `repeat(${columnCount}, ${gridColumnWidth})`,
	})),
);
