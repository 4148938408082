import { GA4EcommerceProduct } from '~/features/tracking/model';
import { IVariation, IVariationDetails } from '~/lib/data-contract';
import { subtract } from '~/shared/utils/math/math';

export const createGA4ProductTrackingObject = ({ variation, quantity, itemListId, itemListName, currency, index }: CreateGA4ProductTrackingObjectArgs) => {
	const obj: GA4EcommerceProduct = {
		item_id: variation?.sku,
		item_name: variation?.name?.toLowerCase(),
		item_category: variation?.itemSubType?.name?.toLowerCase() || '',
		item_category2: variation?.designCollection?.name?.toLowerCase() || '',
		item_category3: variation?.mainMaterial?.name?.toLowerCase() || '',
		item_category4: variation?.sizeAttribute?.toLowerCase() || '',
		item_brand: variation?.brand?.name?.toLowerCase() || '',
		item_variant: variation?.color?.colorName?.toLowerCase() || '',
		price: variation.price,
		affiliation: 'webshop',
		currency: currency.toUpperCase(),
	};

	if (typeof itemListId === 'string') {
		obj.item_list_id = itemListId.toLowerCase();
	}

	if (typeof itemListName === 'string') {
		obj.item_list_name = itemListName.toLowerCase();
	}

	if (typeof index === 'number') {
		obj.index = index;
	}

	if (typeof quantity === 'number') {
		obj.quantity = quantity;
	}

	if (variation.discountedPrice) {
		obj.discount = subtract(variation.price, variation.discountedPrice);
	}

	return obj;
};

export type CreateGA4ProductTrackingObjectArgs = {
	variation: IVariationDetails | IVariation;
	currency: string;
	quantity?: number;
	itemListId?: string;
	itemListName?: string;
	index?: number;
};
