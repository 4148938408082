import { isTag } from 'domhandler';
import parse, { attributesToProps, DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { CSSProperties, useMemo } from 'react';
import { M100RichTextLayout } from '~/lib/data-contract';
import { Button } from '~/shared/components/Button/Button';
import { Headline, HeadlineProps } from '~/shared/components/Headline/Headline';
import Link from '~/shared/components/Link/Link';
import { Table } from '~/shared/components/Table/components/Table';
import { TableBody } from '~/shared/components/Table/components/TableBody';
import { TableCell } from '~/shared/components/Table/components/TableCell';
import { TableHead } from '~/shared/components/Table/components/TableHead';
import { TableHeadCell } from '~/shared/components/Table/components/TableHeadCell';
import { TableRow } from '~/shared/components/Table/components/TableRow';
import { Text } from '~/shared/components/Text/Text';
import { TextColumns } from '~/shared/components/TextColumns/TextColumns';
import { CallToAction } from '../../CallToAction/CallToAction';
import { ciccTemplateReservedId, CookieConsentCICCTemplate } from '../../CookieConsent/CookieConsentCICCTemplate';
import { StyledPAsDiv, StyledRichText, StyledRichTextProps } from '../styled';

export const DIGIT_REGEX = /\D/g;

type RegularTextTags = (typeof regularTextTags)[number];
const regularTextTags = ['p', 'b', 'strong', 'i', 'em', 'u', 'blockquote', 'code', 'pre', 'li'] as const;

const parsePTagWithArrowLink = (node: DOMNode) => {
	if (!isTag(node)) {
		return false;
	}
	const { children } = node;
	if (children.length !== 1 || !isTag(children[0])) {
		return false;
	}
	return children[0].attribs['data-epi-link-style'] === 'ARROW';
};

const withLayout = (layout = M100RichTextLayout.left): HTMLReactParserOptions => {
	const options: HTMLReactParserOptions = {
		replace: (domNode) => {
			if (!isTag(domNode)) {
				return;
			}
			const { attribs, children, name } = domNode;
			const props = attributesToProps(attribs);

			if (name === 'div' && attribs.id === ciccTemplateReservedId) {
				return <CookieConsentCICCTemplate />;
			}

			const alignment = 'data-epi-align' in props ? (props['data-epi-align'] as CSSProperties['textAlign']) : undefined;

			if ('data-epi-column-parent' in props) {
				const columnCount = Number(props['data-epi-column-parent']);

				return (
					<TextColumns
						layout={layout}
						columns={columnCount}
						children={domToReact(children, options)}
					/>
				);
			}

			if (regularTextTags.includes(name as RegularTextTags)) {
				const tag = name as RegularTextTags;

				if (parsePTagWithArrowLink(domNode)) {
					// figure can't be inside p, and the arrow link has one
					// So instead render a div with the p styling
					return <StyledPAsDiv>{domToReact(children, options)}</StyledPAsDiv>;
				}

				return (
					<Text
						{...props}
						alignment={alignment}
						children={domToReact(children, options)}
						as={tag}
					/>
				);
			}

			if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(name)) {
				const size = Number(name.replace(DIGIT_REGEX, '')) as HeadlineProps['size'];
				return (
					<Headline
						size={size}
						alignment={alignment}
						{...props}
						children={domToReact(children, options)}
					/>
				);
			}

			if (name === 'a') {
				if (!('data-epi-link-style' in props)) {
					return (
						<Link
							href={props.href}
							{...props}
							children={domToReact(children, options)}
						/>
					);
				}

				const linkStyle = props['data-epi-link-style'];

				if (linkStyle === 'BUTTON') {
					return (
						<Button
							as="a"
							variant="filled"
							children={domToReact(children, options)}
							{...props}
						/>
					);
				}

				if (linkStyle === 'ARROW') {
					const { style, ...rest } = props;

					return (
						<CallToAction
							text={String(domToReact(children, options))}
							title={props.title}
							url={props.href}
							{...rest}
						/>
					);
				}
			}

			if (name === 'table') {
				return (
					<Table
						{...props}
						children={domToReact(children, options)}
					/>
				);
			}

			if (name === 'tbody') {
				return (
					<TableBody
						{...props}
						children={domToReact(children, options)}
					/>
				);
			}

			if (name === 'thead') {
				return (
					<TableHead
						{...props}
						children={domToReact(children, options)}
					/>
				);
			}

			if (name === 'tr') {
				return (
					<TableRow
						{...props}
						children={domToReact(children, options)}
					/>
				);
			}

			if (name === 'td') {
				const grandParentEl = domNode.parent?.parent as any;

				if (grandParentEl?.name === 'thead') {
					return (
						<TableHeadCell
							{...props}
							children={domToReact(children, options)}
						/>
					);
				}

				return (
					<TableCell
						{...props}
						children={domToReact(children, options)}
					/>
				);
			}
		},
	};

	return options;
};

export type UseRawHtmlProps = StyledRichTextProps & {
	layout?: M100RichTextLayout;
};

export const useRawHtml = (html: string, props?: UseRawHtmlProps) => {
	return useMemo(() => {
		const markup = parse(html, withLayout(props?.layout));
		return (
			<StyledRichText
				{...props}
				children={markup}
			/>
		);
	}, [html]);
};
