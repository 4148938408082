/// Fetched 2024-03-06T19:10:23.7846593Z
/// From: http://localhost:57527/typescript/interfaces

/* eslint @typescript-eslint/no-empty-interface: "off" */

export interface IWishlist extends IIdentifiable, ILocalizable, IGlobalizable {
	created: string;
	items: IWishlistItem[];
}

export interface IWishlistItem extends IIdentifiable {
	variation: IVariationDetails;
}

export interface ISearchResultItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'searchResultItem';
	searchTitle: string;
	searchUrl: string;
	searchDescription?: string;
}

export interface IBrand extends IIdentifiable {
	name: string;
}

export interface ICategory extends IIdentifiable {}

export interface ICategoryDesignCollection extends ICategory, IDesignCollection {}

export interface ICategoryItemCategory extends ICategory, IItemCategory {}

export interface ICategoryItemType extends ICategory, IItemType {}

export interface IColor extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'color';
	colorId: string;
	colorName?: string;
	mainColorId: string;
	mainColorName?: string;
	mainCustomColorCode?: string;
	mainHexColorCode?: string;
	showColorNameOnPdp: boolean;
}

export interface IDesignCollection extends IIdentifiable {
	name: string;
	url?: string;
	brandNo?: string;
	description?: string;
}

export interface IDesigner extends IIdentifiable {
	name: string;
}

export interface IItemCategory extends IIdentifiable {
	name?: string;
	url?: string;
}

export interface IItemType extends IIdentifiable {
	name: string;
	url?: string;
}

export interface IMaterial extends IIdentifiable {
	name: string;
}

export interface IOccasion extends IIdentifiable {
	name: string;
}

export interface IVariation extends IIdentifiable, Omit<IRenderable, 'type'>, ILocalizable, IGlobalizable {
	type: 'variation';
	sku: string;
	name: string;
	url?: string;
	image?: IImageMedia;
	m550ImageUrl?: string;
	price: number;
	discountedPrice?: number;
	priceExVat: number;
	discountedPriceExVat?: number;
	brandNo?: string;
	sizeAttribute?: string;
	volumeL?: number;
	diameterCm?: number;
	heightCm?: number;
	depthCm?: number;
	widthCm?: number;
	color?: IColor;
	designCollection?: IDesignCollection;
	itemSubType?: IItemType;
	brand?: IBrand;
	mainMaterial?: IMaterial;
	isInPreorderState: boolean;
	preorderNextShippingDate?: string;
	moodImage?: IImageMedia;
	manualOrderItem: boolean;
}

export interface IVariationDetails extends Omit<IVariation, 'type'>, Omit<IProductFilterItem, 'type'>, Omit<ISearchResultItem, 'type'>, IM140Promotion {
	type: 'variationDetails';
	splashes: ISplash[];
	images: IImageMedia[];
	moodImages: IImageMedia[];
	variations: IVariation[];
	usps: string[];
	occasions: IOccasion[];
	video?: IVideoMedia;
	stampImage?: IImageMedia;
	stampLink?: ILink;
	categoryLink?: ILink;
	productGuide?: IProductGuide;
	designer?: IDesigner;
	itemMainType?: IItemType;
	itemCategory?: ICategoryItemCategory;
	itemSubCategory?: ICategoryItemCategory;
	itemMainCategory?: ICategoryItemCategory;
	description?: string;
	certificate: number;
	stockAvailable: boolean;
	newArrival: boolean;
	stockState: number;
	mostPopularScore: number;
	discountedPriceValidFrom?: string;
	discountedPriceValidUntil?: string;
	showSchedulingAndStatusOnProductCardOptions?: 0 | 1 | 2; // 0 = none, 1 = ShowDatesOnProductCard, 2 = ShowUntilSoldOutOnProductCard
}

export interface IIdentifiable {
	id: string;
}

export interface IFacet extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'facet';
	label: string;
	values: IFacetValue[];
}

export interface IFacetValue extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'facetValue';
	label: string;
	selected: boolean;
	count: number;
}

export interface IFilterPageItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'filterPageItem';
}

export interface IFilterPagePromotion extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'filterPagePromotion';
	item: IFilterPagePromotionItem;
	index: number;
}

export interface IFilterPagePromotionItem extends Omit<IFilterPageItem, 'type'> {
	type: 'filterPagePromotionItem';
	spaces: number;
}

export interface ISortOption extends IIdentifiable {
	selected: boolean;
	label: string;
}

export interface IMetaPage extends Omit<IPage, 'type'>, IMetadata {
	type: 'metaPage';
}

export interface IModulePage extends Omit<IMetaPage, 'type'> {
	type: 'modulePage';
}

export interface IPage extends IIdentifiable, Omit<IRenderable, 'type'>, ILocalizable, IGlobalizable {
	type: 'page';
	statusCode: number;
	pageElements: IPageElement[];
	breadcrumb?: IBreadcrumb;
	verticalId?: string;
}

export interface IPersonalizedPage extends IIdentifiable, Omit<IRenderable, 'type'>, ILocalizable, IGlobalizable {
	type: 'personalizedPage';
	pageElements: IPageElement[];
}

export interface IP90BasketPage extends Omit<IModulePage, 'type'> {
	type: 'p90BasketPage';
	emptyBasketHeadline?: IHeadline;
	emptyBasket?: IRichText;
	sustainablePackagingReadMoreHeadline?: IHeadline;
	sustainablePackagingReadMoreText?: IRichText;
	sustainablePackagingHeadline?: string;
	sustainablePackagingAddLabel?: string;
	sustainablePackagingRichText?: IRichText;
	sustainablePackagingImageMedia?: IImageMedia;
	sustainablePackagingPrice: number;
	sustainablePackagingFreeAmount: number;
	openingHoursHeadline?: IHeadline;
	openingHours?: IRichText;
	usps?: IP90BasketUspItem[];
	linksHeadline?: IHeadline;
	links?: ILink[];
}

export interface IP90BasketUspItem extends IIdentifiable {
	icon: Icon;
	text: string;
}

export interface IP80FavoritePage extends Omit<IModulePage, 'type'> {
	type: 'p80FavoritePage';
}

export interface IP70FindStoresPage extends Omit<IModulePage, 'type'> {
	type: 'p70FindStoresPage';
}

export interface IP60ModulePage extends Omit<IModulePage, 'type'> {
	type: 'p60ModulePage';
}

export interface IP50ProductDetailPage extends Omit<IModulePage, 'type'> {
	type: 'p50ProductDetailPage';
	variationDetails: IVariationDetails;
	usps?: IP90BasketUspItem[];
}

export interface IP40ProductListPage extends Omit<IModulePage, 'type'>, ITheme {
	type: 'p40ProductListPage';
	categoriesModule?: IM20CategoriesModule;
	productFilterModule?: IM170ProductFilterModule;
}

export interface IP35CategoryDetailPage extends Omit<IModulePage, 'type'> {
	type: 'p35CategoryDetailPage';
	category: ICategory;
	categoriesModule?: IM20CategoriesModule;
	productFilterModule?: IM170ProductFilterModule;
}

export interface IP30CategoryListPage extends Omit<IModulePage, 'type'> {
	type: 'p30CategoryListPage';
	categoriesModule?: IM20CategoriesModule;
}

export interface IP20FrontPage extends Omit<IModulePage, 'type'> {
	type: 'p20FrontPage';
}

export interface IP200MemberPage extends Omit<IModulePage, 'type'> {
	type: 'p200MemberPage';
	memberPageElements: IPageElement[];
	memberMenu: INavigationNode[];
}

export interface IP181MarketingUpdatePage extends Omit<IModulePage, 'type'> {
	type: 'p181MarketingUpdatePage';
}

export interface IP180MarketingConfirmationPage extends Omit<IModulePage, 'type'> {
	type: 'p180MarketingConfirmationPage';
}

export interface IP170VerticalPage extends Omit<IModulePage, 'type'> {
	type: 'p170VerticalPage';
}

export interface IP160ArticlePage extends Omit<IModulePage, 'type'>, IArticleItem {
	type: 'p160ArticlePage';
	articleElements: IPageElement[];
}

export interface IP150ErrorPage extends Omit<IModulePage, 'type'> {
	type: 'p150ErrorPage';
}

export interface IP140NotFoundPage extends Omit<IModulePage, 'type'> {
	type: 'p140NotFoundPage';
}

export interface IP130ContactPage extends Omit<IModulePage, 'type'> {
	type: 'p130ContactPage';
}

export interface IP120ProfilePage extends Omit<IModulePage, 'type'> {
	type: 'p120ProfilePage';
}

export interface IP110OrderConfirmationPage extends Omit<IModulePage, 'type'> {
	type: 'p110OrderConfirmationPage';
	headline?: IHeadlineRichText;
	headlineManualFlow?: IHeadlineRichText;
	headlineOrderCancelled?: IHeadlineRichText;
	text?: IRichText;
	textManualFlow?: IRichText;
	textOrderCancelled?: IRichText;
}

export interface IP110FaqPage extends Omit<IModulePage, 'type'> {
	type: 'p110FaqPage';
}

export interface IP10SearchPage extends Omit<IModulePage, 'type'> {
	type: 'p10SearchPage';
	productFilterModule?: IM170ProductFilterModule;
}

export interface IP100CheckoutPage extends Omit<IModulePage, 'type'> {
	type: 'p100CheckoutPage';
	termsAndConditionsRichText?: IRichText;
	manualFlowRichText?: IRichText;
	openingHoursHeadline?: IHeadline;
	openingHours?: IRichText;
	usps?: IP90BasketUspItem[];
	linksHeadline?: IHeadline;
	links?: ILink[];
	paymentIcons?: IImageMedia[];
}

export interface IModule extends Omit<IPageElement, 'type'> {
	type: 'module';
}

export interface IOptionalSkeletonModule extends Omit<ISkeletonModule, 'type'> {
	type: 'optionalSkeletonModule';
}

export interface IRequiredSkeletonModule extends Omit<ISkeletonModule, 'type'> {
	type: 'requiredSkeletonModule';
}

export interface ISkeletonModule extends Omit<IPageElement, 'type'> {
	type: 'skeletonModule';
}

export interface IM90QuoteModule extends Omit<IModule, 'type'>, IHeight {
	type: 'm90QuoteModule';
	quote?: string;
	author?: string;
}

export interface IM80MediaAndTextBundleModule extends Omit<IModule, 'type'>, ITheme, IModuleWithHeadline {
	type: 'm80MediaAndTextBundleModule';
	headline?: IHeadlineRichText;
	callToAction?: ICallToAction;
	items: IM75MediaAndTextItem[];
}

export interface IM75MediaAndTextModule extends Omit<IModule, 'type'>, IM75MediaAndTextItem, ITheme, IModuleWithHeadline {
	type: 'm75MediaAndTextModule';
	label?: IHeadline;
	mediaVerticalAlignment: VerticalAlignment;
	longText?: IRichText;
}

export interface IM71MediaBundleModule extends Omit<IModule, 'type'> {
	type: 'm71MediaBundleModule';
	mediaItems: IM70MediaItem[];
}

export interface IM70MediaModule extends Omit<IModule, 'type'>, IM70MediaItem {
	type: 'm70MediaModule';
	textColor: ModuleForegroundColor;
	link?: ILink;
}

export interface IM60UspModule extends Omit<IModule, 'type'> {
	type: 'm60UspModule';
	items?: IM60UspItem[];
}

export interface IM50ShopTheLookModule extends Omit<IModule, 'type'>, ITheme, IModuleWithHeadline {
	type: 'm50ShopTheLookModule';
	items?: IFilterPageItem[];
	image?: IImageMedia;
	video?: IVideoMedia;
	headline?: IHeadlineRichText;
	text?: IRichText;
	callToAction?: ICallToAction;
	label?: IHeadline;
}

export interface IM40ProductPromotionModule extends Omit<IModule, 'type'> {
	type: 'm40ProductPromotionModule';
}

export interface IM30ProductCarouselBaseModule extends Omit<IModule, 'type'>, IBrandEffect, IModuleWithHeadline {
	type: 'm30ProductCarouselBaseModule';
	headline?: IHeadlineRichText;
	callToAction?: ICallToAction;
	raptorModule?: RaptorModules;
}

export interface IM30ProductCarouselModule extends Omit<IM30ProductCarouselBaseModule, 'type'> {
	type: 'm30ProductCarouselModule';
	items: IFilterPageItem[];
}

export interface IM30ProductCarouselSkeletonModule extends Omit<IM30ProductCarouselBaseModule, 'type'>, Omit<IRequiredSkeletonModule, 'type'> {
	type: 'm30ProductCarouselSkeletonModule';
}

export interface IM25CategorySpotItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'm25CategorySpotItem';
	label?: IHeadline;
	headline: IHeadlineRichText;
	image: IImageMedia;
	callToAction?: ICallToAction;
	brandNo?: string;
	textColor: ModuleForegroundColor;
}

export interface IM25CategorySpotModule extends Omit<IModule, 'type'>, ITheme, IModuleWithHeadline {
	type: 'm25CategorySpotModule';
	label?: IHeadline;
	headline?: IHeadlineRichText;
	links?: ILink[];
	promotionLeft: IM25CategorySpotItem;
	promotionRight: IM25CategorySpotItem;
}

export interface ICategoryItem extends Omit<IFilterPageItem, 'type'> {
	type: 'categoryItem';
	selected: boolean;
	link: ILink;
	image?: IImageMedia;
}

export interface IM20CategoriesModule extends Omit<IModule, 'type'>, IBrandEffect, IModuleWithHeadline {
	type: 'm20CategoriesModule';
	headline?: IHeadline;
	items: ICategoryItem[];
	categoryImages: boolean;
}

export interface IM200FormsModule extends Omit<IModule, 'type'>, IModuleWithHeadline {
	type: 'm200FormsModule';
	headline?: IHeadline;
	form?: IForm;
}

export interface IM170ProductFilterModule extends Omit<IModule, 'type'> {
	type: 'm170ProductFilterModule';
	filter: IFilter;
}

export interface IProductFilterItem extends Omit<IFilterPageItem, 'type'> {
	type: 'productFilterItem';
}

export interface IM160ArticleFilterModule extends Omit<IModule, 'type'>, IModuleWithHeadline {
	type: 'm160ArticleFilterModule';
	headline?: IHeadline;
	callToAction?: ICallToAction;
	articleItems?: IArticleFilterItem[];
	categories?: ICategory[];
}

export interface IM15HeroPromotionModule extends Omit<IModule, 'type'>, IHeight, IPlacement {
	type: 'm15HeroPromotionModule';
}

export interface IM150HeadlineModule extends Omit<IModule, 'type'>, IModuleWithHeadline {
	type: 'm150HeadlineModule';
	headline?: IHeadline;
}

export interface IM140Promotion extends IIdentifiable {}

export interface IM120AccordionListModule extends Omit<IModule, 'type'>, IModuleWithHeadline {
	type: 'm120AccordionListModule';
	headline?: IHeadline;
	accordionItems: IAccordionItem[];
}

export interface IM110NewsletterSignupInterest extends IIdentifiable {
	label?: string;
}

export interface IM110NewsletterSignupModule extends Omit<IModule, 'type'> {
	type: 'm110NewsletterSignupModule';
	antiForgery: IAntiForgery;
}

export interface IM110NewsletterSignupSkeletonModule extends Omit<IModule, 'type'>, IModuleWithHeadline, Omit<IRequiredSkeletonModule, 'type'> {
	type: 'm110NewsletterSignupSkeletonModule';
	headline: IHeadlineRichText;
	body: IRichText;
	signupEmailPlaceholder?: string;
	signupNamePlaceholder?: string;
	signupSubmit: string;
	modalHeadline: IHeadlineRichText;
	modalBody: IRichText;
	modalInterestsHeadline: string;
	interests: IM110NewsletterSignupInterest[];
	modalTerms: IRichText;
	permissionEmailText: IRichText;
	permissionEmailVersion: number;
	permissionProfilingText: IRichText;
	permissionProfilingVersion: number;
	modalSubmit: string;
	submitHeadline: IHeadlineRichText;
	submitBody: IRichText;
	fulfillmentHeadline: IHeadlineRichText;
	fulfillmentBody?: IRichText;
	alternativeFulfillmentBody?: IRichText;
	fulfillmentButtonLabel: string;
	extraSubmitButtonLabel: string;
}

export interface IM10HeroModule extends Omit<IModule, 'type'>, IModuleWithHeadline {
	type: 'm10HeroModule';
	image?: IImageMedia;
	desktopLayout: HeroContentLayout;
	mobileLayout: HeroContentLayout;
	textColor: ModuleForegroundColor;
	addOverlay: boolean;
	video?: IVideoMedia;
	headline?: IHeadlineRichText;
	label?: IHeadline;
	callToAction?: ICallToAction;
}

export interface IM100RichTextModule extends Omit<IModule, 'type'>, ITheme, IModuleWithHeadline {
	type: 'm100RichTextModule';
	headline?: IHeadlineRichText;
	text?: IRichText;
	layout: M100RichTextLayout;
}

export interface ICallToAction extends ILink {
	style: CallToActionStyle;
}

export interface IFrame extends IIdentifiable, ILocalizable, Omit<IRenderable, 'type'> {
	type: 'frame';
	environment: string;
	header?: IHeader;
	footer?: IFooter;
	navigation?: INavigation;
	search?: ISearch;
	market?: IMarket;
	marketSelector?: IMarketSelector;
	vertical?: IVertical;
	verticalSelector?: IVerticalSelector;
	settings?: ISiteSettings;
}

export interface IHeader extends IIdentifiable {
	tagLine?: string;
	logo?: ILogoMedia;
	findStores?: ILink;
	favorites?: ILink;
	checkout?: ILink;
	basket?: ILink;
	home?: ILink;
	notFound?: ILink;
}

export interface ILink extends IIdentifiable {
	text: string;
	title?: string;
	url: string;
	target?: string;
}

export interface IPageElement extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'pageElement';
	noSpaceAbove: boolean;
	anchorId: string;
	name: string;
}

export interface ISearch extends IIdentifiable {
	searchResultsLink?: ILink;
	quickLinks?: ILink[];
}

export interface IBrandVertical extends Omit<IVertical, 'type'> {
	type: 'brandVertical';
	brandNo?: string;
	useFirstPackshotOnM550: boolean;
	logo?: ILogoMedia;
	defaultProductImage?: IImageMedia;
	menuImage?: IImageMedia;
}

export interface IVertical extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'vertical';
	name: string;
	url: string;
}

export interface IVerticalSelectorItem extends ILink {
	brand?: IBrandVertical;
}

export interface IGlobalMenu extends IIdentifiable, ILocalizable, IGlobalizable {
	header?: IHeader;
	verticals?: IVerticalMenu[];
}

export interface IMetaMenu extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'metaMenu';
	moduleBackgroundColor?: string;
	items?: IMetaMenuItem[];
}

export interface IMetaMenuItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'metaMenuItem';
	richText?: IRichText;
}

export interface INavigation extends IIdentifiable {
	metaMenu?: IMetaMenu;
	mainMenu?: INavigationNode[];
}

export interface INavigationNode extends IIdentifiable {
	children?: INavigationNode[];
	link: ILink;
}

export interface IVerticalMenu extends Omit<INavigationNode, 'type' | 'children'> {
	type: 'verticalMenu';
	subMenu?: INavigationNode[];
}

export interface ICountry extends IIdentifiable {
	name: string;
	vat: number;
	image?: IImageMedia;
	marketId?: string;
	defaultPhonePrefix?: string;
	postalCodeRegex?: string;
}

export interface ICurrency extends IIdentifiable {
	symbol?: string;
}

export interface IMarket extends IIdentifiable {
	contentId: number;
	name?: string;
	disallowBreweries: boolean;
	commerceMarketId?: string;
	defaultLanguage?: string;
	languages?: string[];
	generateSitemap: boolean;
	pimCountry?: string;
	giftWrappingEnabled: boolean;
	giftWrappingPrice: number;
	giftWrappingFreeAmount: number;
	sustainablePackagingPrice: number;
	sustainablePackagingFreeAmount: number;
	sustainablePackagingEnabled: boolean;
	currency?: ICurrency;
	countries?: ICountry[];
	paymentOptions?: IPaymentOption[];
	deliveryUrl?: string;
	shippingOptions?: IShippingOption[];
	maxOrderValue: number;
	skipMarketingProfileOptIn: boolean;
	url?: string;
}

export interface IPaymentIcon extends IIdentifiable {
	title?: string;
	image?: IImageMedia;
}

export interface IMarketSelectorLanguage extends ILink, ILocalizable {}

export interface IMarketSelectorMarket extends IIdentifiable {
	label: string;
	languages: IMarketSelectorLanguage[];
	image?: IImageMedia;
}

export interface IForm extends IIdentifiable {
	antiForgery?: IAntiForgery;
	steps?: IFormStep[];
	labels?: IFormLabels;
}

export interface IFormElement extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'formElement';
	name?: string;
	label?: string;
	inputType: FormElementInputType;
	placeholder?: string;
	value?: string;
	required: boolean;
	requiredMessage?: string;
	regex?: string;
	regexMessage?: string;
}

export interface IFormGroup extends IIdentifiable {
	label?: string;
	columns: number;
	elements?: IFormElement[];
}

export interface IFormStep extends IIdentifiable {
	label?: string;
	groups?: IFormGroup[];
}

export interface ISelectFormElement extends Omit<IFormElement, 'type'> {
	type: 'selectFormElement';
	allowMultiSelect: boolean;
	options: ISelectOption[];
}

export interface IFooter extends IIdentifiable {
	leftHeadline?: string;
	leftText?: IRichText;
	centerHeadline?: string;
	centerMenu?: INavigationNode[];
	rightHeadline?: string;
	rightMenu?: INavigationNode[];
	copyright?: string;
	socialMediaIcons?: IFooterSocialMediaIcon[];
	paymentIcons?: IPaymentIcon[];
}

export interface IFooterSocialMediaIcon extends IIdentifiable {
	icon: SocialMediaIcon;
	link?: ILink;
}

export interface IAreaCategory extends Omit<ICategory, 'type'> {
	type: 'areaCategory';
}

export interface ICategory extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'category';
	label?: string;
}

export interface ITopicCategory extends Omit<ICategory, 'type'> {
	type: 'topicCategory';
}

export interface ITypeCategory extends Omit<ICategory, 'type'> {
	type: 'typeCategory';
}

export interface IBasket extends IIdentifiable, ILocalizable, IGlobalizable {
	created: string;
	quantitySum: number;
	totals: ITotals;
	lineItems: ILineItem[];
	shippingCountryId?: string;
	isInPreorderState: boolean;
	preorderNextShippingDate?: string;
	appliedVouchers: string[];
	manualFlow: boolean;
}

export interface ILineItem extends IIdentifiable {
	variation: IVariationDetails;
	quantity: number;
	unitPrice: number;
	linePrice: number;
	discountedLinePrice?: number;
	discount?: number;
	giftWrappingEnabled: boolean;
	giftWrappingPrice?: number;
	giftWrappingSelected: boolean;
	greetingCardText?: string;
	sustainablePackagingEnabled: boolean;
	sustainablePackagingPrice?: number;
	sustainablePackagingSelected: boolean;
	itemReturned: boolean;
	isGift: boolean;
}

export interface IOrder extends IBasket {
	orderNumber?: string;
	orderStatus?: string;
	orderIsCancelled: boolean;
	userInfo?: IUserInfo;
	shippingAddress?: IAddress;
	billingAddress?: IAddress;
	shippingOption?: IShippingOption;
	shippingCountry?: IShippingCountry;
	paymentOption?: IPaymentOption;
	billingAddressAsShipping: boolean;
}

export interface IPaymentOption extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'paymentOption';
	selected: boolean;
	active: boolean;
	name: string;
	description: string;
	reePayHandleId: string;
	images: IImageMedia[];
	minOrderValue?: number;
	maxOrderValue?: number;
}

export interface IServicePoint extends IIdentifiable {
	name: string;
	postalCode: string;
	city: string;
	address: string;
	lat: number;
	lng: number;
	openingHours?: string[];
}

export interface IShippingCountry extends ICountry {
	url?: string;
}

export interface IShippingOption extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'shippingOption';
	selected: boolean;
	active: boolean;
	name: string;
	rate: number;
	freeAmount?: number;
	minDeliveryDays: number;
	maxDeliveryDays: number;
	deliveryOptionType: DeliveryOptionType;
	shippingProviderName: ShippingProviderName;
	image: IImageMedia;
	axShippingTypeCode?: string;
}

export interface IValidatedBasket extends IIdentifiable {
	basket: IBasket;
	success: boolean;
	validationMessages?: string[];
}

export interface IValidatedPurchaseOrder extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'validatedPurchaseOrder';
	success: boolean;
	orderId?: string;
	paymentId?: string;
	redirectUrl?: string;
	validationMessages?: string[];
	fieldErrors: IFieldErrors;
}

export interface IProfile extends IIdentifiable {
	name?: string;
}

export interface IM65UspPromotion extends Omit<IFilterPagePromotionItem, 'type'>, IM140Promotion, Omit<IProductFilterItem, 'type'> {
	type: 'm65UspPromotion';
	headline?: IHeadlineRichText;
	body?: IHeadlineRichText;
	textColor: ModuleForegroundColor;
	image?: IImageMedia;
	video?: IVideoMedia;
	link?: ILink;
}

export interface IM560TabListItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'm560TabListItem';
	title?: string;
}

export interface IM560TabListItemProductDescription extends Omit<IM560TabListItemRichText, 'type'> {
	type: 'm560TabListItemProductDescription';
}

export interface IM560TabListItemProductSpecs extends Omit<IM560TabListItem, 'type'> {
	type: 'm560TabListItemProductSpecs';
	items: IM550ProductSpec[];
}

export interface IM560TabListItemRichText extends Omit<IM560TabListItem, 'type'> {
	type: 'm560TabListItemRichText';
	longBody: IRichText;
	shortBody?: IRichText;
}

export interface IM560TabListModule extends Omit<IModule, 'type'> {
	type: 'm560TabListModule';
	moduleBackgroundColor?: string;
	items: IM560TabListItem[];
}

export interface IM550ProductCareSpec extends Omit<IM550ProductSpec, 'type'> {
	type: 'm550ProductCareSpec';
	readMoreLink?: ILink;
}

export interface IM550ProductSpec extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'm550ProductSpec';
	key: string;
	value: any;
}

export interface IM550ProductSpecsModule extends Omit<IModule, 'type'>, ITheme {
	type: 'm550ProductSpecsModule';
	variationDetails: IVariationDetails;
	items: IM550ProductSpec[];
	image: IImageMedia;
	alignImageAtTop: boolean;
}

export interface IM210PreferenceCenterModule extends Omit<IModule, 'type'> {
	type: 'm210PreferenceCenterModule';
	antiForgery: IAntiForgery;
	profile?: IMarketingUpdateProfile;
}

export interface IM210PreferenceCenterSkeletonModule extends Omit<IModule, 'type'>, IModuleWithHeadline, Omit<IRequiredSkeletonModule, 'type'> {
	type: 'm210PreferenceCenterSkeletonModule';
	permissionEmailText: IRichText;
	permissionEmailVersion: number;
	permissionProfilingText: IRichText;
	permissionProfilingVersion: number;
	headline?: IHeadlineRichText;
	body?: IRichText;
	interests: IM110NewsletterSignupInterest[];
}

export interface ICategoryCategoryItem extends Omit<ICategoryItem, 'type'> {
	type: 'categoryCategoryItem';
}

export interface ICategoryContentCategoryItem extends Omit<ICategoryItem, 'type'> {
	type: 'categoryContentCategoryItem';
}

export interface IDesignCollectionCategoryItem extends Omit<ICategoryItem, 'type'> {
	type: 'designCollectionCategoryItem';
}

export interface IM190BrandOverviewItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'm190BrandOverviewItem';
	link: ILink;
	moduleBackgroundColor?: string;
	brandNo: string;
}

export interface IM190BrandOverviewModule extends Omit<IModule, 'type'>, IModuleWithHeadline, ITheme {
	type: 'm190BrandOverviewModule';
	headline?: IHeadlineRichText;
	text?: IRichText;
	shopAllCallToAction?: ICallToAction;
	brands: IM190BrandOverviewItem[];
	items: IM190StoryItem[];
}

export interface IM190StoryItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'm190StoryItem';
	link: ILink;
	image: IImageMedia;
}

export interface IM180BrandOverviewItem extends IIdentifiable, Omit<IRenderable, 'type'> {
	type: 'm180BrandOverviewItem';
	brandNo?: string;
	link?: ILink;
	hoverLogo?: IImageMedia;
}

export interface IM180BrandOverviewModule extends Omit<IModule, 'type'>, IModuleWithHeadline {
	type: 'm180BrandOverviewModule';
	headline?: IHeadline;
	text?: IRichText;
	shopAllCallToAction?: ICallToAction;
	items: IM180BrandOverviewItem[];
}

export interface IM140ContentSpotItem extends IM140Promotion {
	titleHeadline: IHeadline;
	subTitleHeadline?: IHeadline;
	image?: IImageMedia;
	video?: IVideoMedia;
	link?: ILink;
	textColor: ModuleForegroundColor;
}

export interface IM140ContentSpotModule extends Omit<IModule, 'type'>, Omit<IFilterPagePromotionItem, 'type'>, Omit<IProductFilterItem, 'type'> {
	type: 'm140ContentSpotModule';
	moduleBackgroundColor?: string;
	textColor: ModuleForegroundColor;
	titleHeadline: IHeadlineRichText;
	subTitleHeadline?: IHeadline;
	text?: IRichText;
	callToAction?: ICallToAction;
	backgroundImage?: IImageMedia;
	promotionHorizontalAlignment: HorizontalAlignmentSplit;
	promotion: IM140Promotion;
	textHorizontalAlignment: HorizontalAlignment;
}

export interface IM1000CookieInformationModule extends Omit<IModule, 'type'> {
	type: 'm1000CookieInformationModule';
	showCookiePolicy: boolean;
	showPrivacyControls: boolean;
}

export interface IColorFacetValue extends Omit<IFacetValue, 'type'> {
	type: 'colorFacetValue';
	customColorCode?: string;
	hexColorCode?: string;
}

export interface IStore extends IIdentifiable {
	name: string;
	streetName1: string;
	streetName2?: string;
	zipCode: string;
	city: string;
	country: string;
	validFromDate: string;
	validToDate: string;
	email?: string;
	websiteUrl?: string;
	phoneNo?: string;
	latitude?: number;
	longitude?: number;
	brandNos: string[];
	marketId: string;
}

export interface IRenderable {
	type: 'renderable';
}

export interface IRedirect extends Omit<IRenderable, 'type'> {
	type: 'redirect';
	destination: string;
	permanent: boolean;
}

export interface IImageMedia extends Omit<IMedia, 'type'> {
	type: 'imageMedia';
	alt?: string;
	caption?: string;
	width: number;
	height: number;
	focalX?: number;
	focalY?: number;
	origin: ImageMediaOrigin;
}

export interface IMedia extends Omit<IRenderable, 'type'> {
	type: 'media';
	src: string;
}

export interface IVideoMedia extends Omit<IMedia, 'type'> {
	type: 'videoMedia';
	width: number;
	height: number;
	duration: number;
	/**
	 * Should the video loop
	 */
	loop: boolean;
	/**
	 * Should the video start on load
	 */
	autoPlay: boolean;
	origin: VideoMediaOrigin;
}

export interface IRedirect extends Omit<IRenderable, 'type'> {
	type: 'redirect';
	destination: string;
	permanent: boolean;
}

export interface IOrderDraftResponse extends Omit<IRenderable, 'type'> {
	type: 'orderDraftResponse';
	basket?: IValidatedBasket;
	data?: IOrderDraft;
	fieldErrors: IFieldErrors;
	errors: string[];
}

export interface IShippingDetailsResponse extends Omit<IRenderable, 'type'> {
	type: 'shippingDetailsResponse';
	data: IShippingDetails;
	fieldErrors: IFieldErrors;
	errors: string[];
}

export interface IBillingDetailsResponse extends Omit<IRenderable, 'type'> {
	type: 'billingDetailsResponse';
	data?: IBillingDetails;
	fieldErrors?: IFieldErrors;
	errors?: string[];
}

export interface ILocalizable {
	culture: string;
}

export interface IGlobalizable {
	market: string;
}

export interface IHeadline extends IText, IStyleableHeadline {}

export interface IText {
	text: string;
}

export interface IRequest {}

export interface IShippingAddressRequest extends IRequest {
	servicePointId?: string;
	address?: IAddress;
}

export interface IShippingServicePointRequest extends IRequest {
	shippingOptionId: string;
	countryCode?: string;
	postalCode?: string;
	latitude?: string;
	longitude?: string;
}

export interface ISplash extends IResponse {
	type: SplashType;
	text?: string;
}

export interface IStockResponse extends IResponse {
	success: boolean;
	stockState: StockState;
	expectedShippingDate?: string;
	phase?: string;
	availabilityDate?: string;
}

export interface IResponse {}

export interface IFilter {
	facets: IFacet[];
	sorting: ISorting;
	page: IFilterPage;
}

export interface ISearchResult {
	categories: ISearchResultCategory[];
}

export interface IAutoCompleteResult {
	suggestions?: string[];
}

export interface IFormSubmitResult {
	isSuccess: boolean;
	redirectUrl?: string;
	message?: string;
	validationFail: boolean;
	invalidFields?: IFormElementValidationInfo[];
}

export interface IMetadata {
	metaTitle: string;
	metaTags?: IMetaTag[];
}

export interface ITheme extends IBrandEffect {
	moduleBackgroundColor?: string;
}

export interface IBrandEffect {
	brandEffect: boolean;
}

export interface IArticleItem {
	media?: IResponsiveMedia;
	headline?: IHeadline;
	text?: IRichText;
	publishedDate?: string;
	categories?: ICategory[];
}

export interface IHeight {
	/**
	 * The height of the module
	 */
	height: ModuleHeight;
}

export interface IModuleWithHeadline {}

export interface IM75MediaAndTextItem {
	headline?: IHeadlineRichText;
	image?: IImageMedia;
	video?: IVideoMedia;
	text: IRichText;
	callToAction?: ICallToAction;
}

export interface IM70MediaItem {
	image?: IImageMedia;
	video?: IVideoMedia;
	text?: IHeadlineRichText;
}

export interface IPlacement {
	/**
	 * Horizontally align content
	 */
	horizontalAlignment: HorizontalAlignment;
	/**
	 * Vertically align content
	 */
	verticalAlignment: VerticalAlignment;
}

export interface IStyleableHeadline {
	tag: HeaderTag;
	style?: HeaderTag;
}

export interface IProductGuide {
	content?: IRichText;
	buttonLabel?: string;
}

export interface IBreadcrumb {
	items?: ILink[];
}

export interface IRichText {
	html: string;
}

export type Icon = 'menu_classic' | 'bag_solid' | 'bag' | 'check' | 'chevron_down' | 'chevron_left' | 'chevron_right' | 'chevron_up_down' | 'chevron_up' | 'circle_filled' | 'circle_outline' | 'close' | 'creditcard' | 'delivery_bike' | 'delivery_truck' | 'eco' | 'edit' | 'eye_hide_02' | 'eye_show_01' | 'eye_show_02' | 'favorit_solid' | 'favorit' | 'filter' | 'giftwrapping' | 'globe' | 'info' | 'link_l' | 'link_r' | 'menu_rdi' | 'menu' | 'minus' | 'package' | 'pin_solid_line' | 'pin_solid' | 'pin' | 'play' | 'plus' | 'profile' | 'receipt' | 'recycle' | 'return' | 'search' | 'selected' | 'shops' | 'temp' | 'v_devider';

export interface IHeadlineRichText extends IRichText, IStyleableHeadline {}

export enum VerticalAlignment {
	top = 'top',
	center = 'center',
	bottom = 'bottom',
}

export type ModuleForegroundColor = 'none' | 'light' | 'dark';

export interface IM60UspItem {
	icon: Icon;
	headline?: IHeadlineRichText;
}

export type RaptorModules = 'getOverallTopSelling' | 'getOverallTopVisits' | 'getTopSellingInCategory' | 'getTopViewedInCategory' | 'getRelatedItems' | 'getSimilarItems' | 'getRelatedItemsForBasket' | 'getMostPopularRightNow' | 'getCookieHistory' | 'getUserRecommendations' | 'getUserIdHistory' | 'getTopViewedInBrands' | 'getTopPurchasedInBrands';

export interface IArticleFilterItem extends IArticleItem {
	link?: ILink;
}

export interface IAccordionItem {
	headline: string;
	body: IRichText;
}

export interface IAntiForgery {
	token?: string;
}

export enum HeroContentLayout {
	left_center = 'left_center',
	left_bottom = 'left_bottom',
	center_center = 'center_center',
}

export enum M100RichTextLayout {
	left = 'left',
	center = 'center',
	right = 'right',
	split = 'split',
}

export type CallToActionStyle = 'default' | 'button' | 'arrow';

export interface IMarketSelector {
	markets: IMarketSelectorMarket[];
}

export interface IVerticalSelector {
	verticals?: IVerticalSelectorItem[];
	allVerticals?: ILink;
}

export interface ISiteSettings {
	googleTagManagerId?: string;
	googleMapsApiKey?: string;
	loginUrl?: string;
}

export interface ILogoMedia {
	logoImage?: IImageMedia;
	logoImageLight?: IImageMedia;
	maxHeightScale: number;
	maxHeightScaleDesktop: number;
}

export interface INavigationPromotion {
	subTitle?: IHeadline;
	title?: IHeadlineRichText;
	image: IImageMedia;
	callToAction?: ICallToAction;
}

export enum SingleLevelNavigationColumns {
	one = 'one',
	two = 'two',
	three = 'three',
}

export interface IFormLabels {
	step?: string;
	totalSteps?: string;
	nextStepButton?: string;
	previousStepButton?: string;
	submitButton?: string;
}

export enum FormElementInputType {
	text = 'text',
	textarea = 'textarea',
	number = 'number',
	email = 'email',
	checkbox = 'checkbox',
	radio = 'radio',
	date = 'date',
	file = 'file',
	submit = 'submit',
	select = 'select',
}

export interface ISelectOption {
	text?: string;
	value?: string;
	checked: boolean;
}

export enum SocialMediaIcon {
	facebook = 'facebook',
	instagram = 'instagram',
}

export interface ITotals {
	orderLinesTotal: number;
	discountOrder: number;
	discountOrderLines: number;
	discountTotal: number;
	subTotal: number;
	giftWrappingSubTotal: number;
	giftWrappingDiscount: number;
	giftWrappingTotal: number;
	giftWrappingTax: number;
	sustainablePackagingSubTotal: number;
	sustainablePackagingDiscount: number;
	sustainablePackagingTotal: number;
	sustainablePackagingTax: number;
	shippingSubTotal: number;
	shippingDiscount: number;
	shippingTax: number;
	shippingTotal: number;
	vatDiscountOrder: number;
	vatSubTotal: number;
	vatShipping: number;
	vatGiftWrapping: number;
	vatSustainablePackaging: number;
	vatTotal: number;
	taxTotal: number;
	total: number;
}

export interface IUserInfo {
	email?: string;
	phoneNumber?: string;
	phoneNumberPrefix?: string;
}

export interface IAddress {
	name?: string;
	companyName?: string;
	address?: string;
	city?: string;
	postalCode?: string;
	countryCode?: string;
	countryName?: string;
	regionCode?: string;
	regionName?: string;
}

export type DeliveryOptionType = 'private' | 'business' | 'parcelShop';

export enum ShippingProviderName {
	bring = 'bring',
	gLS = 'gLS',
	airmee = 'airmee',
	dHL = 'dHL',
	fedex = 'fedex',
}

export interface IFieldErrors {
	userInfo?: { [key: string]: boolean };
	billingAddress?: { [key: string]: boolean };
	shippingAddress?: { [key: string]: boolean };
}

export interface IMarketingUpdateProfile {
	email?: string;
	name?: string;
	gender?: string;
	postalCode?: string;
	mobile?: string;
	birthDate?: string;
	favoriteBrandAJ?: boolean;
	favoriteBrandBW?: boolean;
	favoriteBrandHOL?: boolean;
	favoriteBrandJUN?: boolean;
	favoriteBrandKAH?: boolean;
	favoriteBrandKAY?: boolean;
	favoriteBrandLYP?: boolean;
	favoriteBrandROS?: boolean;
	permissionEmail?: boolean;
	permissionEmailVersion?: number;
	permissionSms?: boolean;
	permissionSmsVersion?: number;
	permissionProfiling?: boolean;
	permissionProfilingVersion?: number;
}

export enum HorizontalAlignmentSplit {
	left = 'left',
	right = 'right',
}

export enum HorizontalAlignment {
	left = 'left',
	center = 'center',
	right = 'right',
}

export type ImageMediaOrigin = 'cms' | 'imagebank';

export type VideoMediaOrigin = 'youtube' | 'vimeo';

export interface IOrderDraft {
	userInfo?: IUserInfo;
	servicePointId?: string;
	shippingAddress?: IAddress;
	billingAddress?: IAddress;
	shippingOptions: IShippingOption[];
	shippingCountries: IShippingCountry[];
	paymentOptions: IPaymentOption[];
	billingAddressAsShipping: boolean;
}

export interface IShippingDetails {
	address?: IAddress;
	shippingOptions?: IShippingOption[];
	servicePointId?: string;
}

export interface IBillingDetails {
	userInfo?: IUserInfo;
	address?: IAddress;
	shippingOptions?: IShippingOption[];
}

export type SplashType = 'splash' | 'sale';

export type StockState = 'available' | 'preorder' | 'notifyStock' | 'comingSoon' | 'outOfStock';

export interface ISorting {
	queryParameter: string;
	options: ISortOption[];
}

export interface IFilterPage {
	total: number;
	take: number;
	skip: number;
	nextPageSkip: number;
	pageSize: number;
	items: IFilterPageItem[];
}

export interface ISearchResultCategory {
	category: SearchResultCategory;
	items: ISearchResultItem[];
	total: number;
}

export interface IFormElementValidationInfo {
	id?: string;
	name?: string;
	label?: string;
	validationMessage?: string;
	validator?: string;
}

export interface IMetaTag {
	tag: string;
}

export interface IResponsiveMedia {
	caption?: string;
	portrait?: IMedia;
	landscape?: IMedia;
	square?: IMedia;
	video?: IVideoMedia;
}

export enum ModuleHeight {
	narrow = 'narrow',
	normal = 'normal',
	tall = 'tall',
}

export type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

export enum SearchResultCategory {
	contentPage = 'contentPage',
	articlePage = 'articlePage',
	newsPage = 'newsPage',
	product = 'product',
	accessory = 'accessory',
}
export type IPages = IP90BasketPage | IP80FavoritePage | IP70FindStoresPage | IP60ModulePage | IP50ProductDetailPage | IP40ProductListPage | IP35CategoryDetailPage | IP30CategoryListPage | IP20FrontPage | IP200MemberPage | IP181MarketingUpdatePage | IP180MarketingConfirmationPage | IP170VerticalPage | IP160ArticlePage | IP150ErrorPage | IP140NotFoundPage | IP130ContactPage | IP120ProfilePage | IP110OrderConfirmationPage | IP110FaqPage | IP10SearchPage | IP100CheckoutPage;
export type IModules = IM90QuoteModule | IM80MediaAndTextBundleModule | IM75MediaAndTextModule | IM71MediaBundleModule | IM70MediaModule | IM60UspModule | IM50ShopTheLookModule | IM40ProductPromotionModule | IM30ProductCarouselBaseModule | IM30ProductCarouselModule | IM30ProductCarouselSkeletonModule | IM25CategorySpotModule | IM20CategoriesModule | IM200FormsModule | IM170ProductFilterModule | IM160ArticleFilterModule | IM15HeroPromotionModule | IM150HeadlineModule | IM120AccordionListModule | IM110NewsletterSignupModule | IM110NewsletterSignupSkeletonModule | IM10HeroModule | IM100RichTextModule | IM560TabListModule | IM550ProductSpecsModule | IM210PreferenceCenterModule | IM210PreferenceCenterSkeletonModule | IM190BrandOverviewModule | IM180BrandOverviewModule | IM140ContentSpotModule | IM1000CookieInformationModule;
export type IM560TabListItems = IM560TabListItemProductDescription | IM560TabListItemProductSpecs | IM560TabListItemRichText;
