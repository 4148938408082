import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { getFluidFontSize } from '~/theme/lib/getClamp';
import { LineItemContainer } from '../LineItemContainer/LineItemContainer';

export const imageSizes = '96px'; // The difference between resolutions is small enough that a single size is enough

export const StyledLineItem = styled(LineItemContainer)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'auto minmax(0, 1fr) auto',
	alignItems: 'center',
	gap: theme.space[2],
	padding: `${theme.space[1]} 0`,
}));

export type StyledLineItemTitleProps = {
	bold?: boolean;
};

export const StyledLineItemTitle = styled.h3<StyledLineItemTitleProps>(
	({ theme }) => ({
		...theme.textStyles.bodyXs,
	}),
	ifProp('bold', ({ theme }) => ({
		fontWeight: theme.fontWeights.bold,
	})),
);

export const StyledLineItemThumbnail = styled.div(({ theme }) => ({
	width: getFluidFontSize(70, 100),
	background: theme.colors.productCardThumbnail,
}));

export const StyledLineItemPreOrder = styled.span(({ theme }) => ({
	display: 'block',
	fontSize: theme.fontSizes['2xs'],
	lineHeight: 'normal',
	marginTop: '0.3em', // to adjust for lineHeight spacing from other text elements having 1.66 instead of normal (1.3)
}));
