import { useTheme } from '@emotion/react';
import Head from 'next/head';
import { FC } from 'react';
import { useFrame } from '../../utils/frame/hooks/useFrame';

const MetaBackground: FC<{ pageId: string }> = (pageId) => {
	const { colors: themeColors } = useTheme();
	const {
		data: { navigation: { metaMenu } = {} },
	} = useFrame();

	return (
		<Head>
			<meta
				key={`tag-${pageId}-page-theme`}
				name="theme-color"
				content={metaMenu?.moduleBackgroundColor ?? themeColors.bodyBackground}
			/>
		</Head>
	);
};

export default MetaBackground;
