import styled from '@emotion/styled';
import { transparentize } from 'color2k';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { BaseThemeType } from '~/theme/lib/themes/themeBase';

export const StyledContainer = styled.div<{
	size?: keyof BaseThemeType['fontSizes'];
}>(({ theme, size = 'sm' }) => ({
	fontSize: theme.fontSizes[size],
	color: theme.colors.grey60,
	backgroundColor: '#FEFEFE',
	boxShadow: 'inset 0px 0px 0px 1px currentColor',
	height: '2.2223em', // Matched the default selector height
	transition: 'color 0.1s',
}));

export const StyledContent = styled.div(({ theme }) => ({
	outline: 0,
	border: 'none',
	width: '100%',
	boxSizing: 'border-box',
	textAlign: 'left',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: 2,
	height: '100%',
	fontFamily: 'inherit',
	color: theme.forms.textColor,
	backgroundColor: 'transparent',
	WebkitTapHighlightColor: 'transparent',

	'&::placeholder': {
		transition: '0.1s color',
		color: theme.forms.textColorDimmed,
	},
}));
export const StyledButton = styled(StyledResetButton)(({ theme }) => ({
	'--button-fill': theme.colors.buttonLight,
	'--button-text': theme.colors.buttonDark,
	'--button-fill-transparent': transparentize(theme.colors.buttonDark, 1),
	display: 'flex',
	aspectRatio: '1',
	height: 'inherit',
	textAlign: 'center',
	fontSize: 'inherit',
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden',
	color: 'var(--button-text)',
	backgroundColor: 'transparent',
	transition: `color ${theme.animations.timingShort}`,

	'&:before': {
		background: 'linear-gradient(to bottom, var(--button-text) 0%, var(--button-text) 50%, var(--button-fill-transparent) 50%, var(--button-fill-transparent) 100%)',
		content: '""',
		position: 'absolute',
		left: 0,
		right: 0,
		top: '-100%',
		bottom: 0,
		transition: `transform ${theme.animations.timingShort}`,
		zIndex: 0,
	},

	svg: {
		position: 'relative',
		zIndex: 1,
		width: '1.75em',
		height: '1.75em',
	},

	path: {
		stroke: 'currentcolor',
	},

	':is(:focus-visible,:hover)': {
		color: 'var(--button-fill)',

		'&:before': {
			transform: 'translateY(50%)',
			transitionDuration: '150ms',
		},
	},
}));
