import styled from '@emotion/styled';

export const StyledLineItemContainer = styled.li(({ theme }) => ({
	'--shared-border': '1px solid',
	borderTop: 'var(--shared-border)',
	borderBottom: 'var(--shared-border)',
	borderColor: theme.colors.grey,
	marginTop: '-1px',
}));

export const LineItemContainer = StyledLineItemContainer;
