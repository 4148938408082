import { IOrderDraftResponse } from '~/lib/data-contract';

export const orderDraftShippingMethodUpdate = (orderDraft: IOrderDraftResponse | undefined, shippingOptionId: string): IOrderDraftResponse | undefined => {
	if (!orderDraft?.data) {
		return;
	}
	return {
		...orderDraft,
		data: {
			...orderDraft.data,
			shippingOptions:
				orderDraft.data.shippingOptions.map((option) => ({
					...option,
					selected: option.id === shippingOptionId,
				})) || [],
		},
		/* basket: {
		 // TODO: Optimistic update on basket pricing according to delivery method
		 } */
	};
};
