import { useMemo } from 'react';
import { useFrame } from '../utils/frame/hooks/useFrame';
import { useMarket } from '../utils/market/hooks/useMarket';

/**
 * Provide a number to transform to a formatted string
 * Supply negative values instead of formatting it where used, as each currency formatting defines how the negative is shown (ex: '-£5.00' and '-5,00 kr.')
 * @param keepCurrencyCode If it should show, for example, "199,95 kr." or simply "199,95", defaults to remove the currency code
 */
export const useFormattedPrice = (value?: number, keepCurrencyCode = false) => {
	const {
		data: { market: frameMarket },
	} = useFrame();
	const { culture, market } = useMarket();

	return useMemo(() => {
		const currency = frameMarket?.currency?.id;

		if (typeof value !== 'number') {
			return undefined;
		}
		if (!currency) {
			return String(value);
		}
		if (keepCurrencyCode) {
			return Intl.NumberFormat(`${culture}-${market}`, {
				style: 'currency',
				currency,
			}).format(value);
		}

		const valueInParts = Intl.NumberFormat(`${culture}-${market}`, {
			style: 'currency',
			currency,
		}).formatToParts(value);

		return valueInParts
			.filter(({ type }) => type !== 'literal' && type !== 'currency')
			.map(({ value }) => value)
			.join('');
	}, [frameMarket?.currency?.id, value, keepCurrencyCode, culture, market]);
};
