import { IBillingDetailsResponse, IOrderDraftResponse } from '~/lib/data-contract';

export const orderDraftBillingDetailsUpdate = (orderDraft: IOrderDraftResponse | undefined, response: IBillingDetailsResponse): IOrderDraftResponse | undefined => {
	if (!orderDraft?.data || !response.data) {
		return;
	}
	return {
		...orderDraft,
		data: {
			...orderDraft.data,
			billingAddress: response.data.address,
			userInfo: response.data.userInfo,
			shippingOptions: response.data.shippingOptions || [],
		},
		fieldErrors: {
			...orderDraft.fieldErrors,
			billingAddress: {},
			userInfo: {},
		},
	};
};
