import { IBasket, ILineItem, IVariationDetails } from '~/lib/data-contract';
import { add, multiply, subtract } from '~/shared/utils/math/math';
import { findLineItemIndex } from './findLineItemIndex';

export type GenerateTotalsArgs = {
	mutation: IBasket;
	itemToUpdate: ILineItem;
	quantityDiff: number;
	discountDiff?: number;
};

export const generateTotals = ({ mutation, itemToUpdate, quantityDiff, discountDiff }: GenerateTotalsArgs) => {
	const { total, discountTotal, orderLinesTotal } = mutation.totals;

	mutation.totals = {
		...mutation.totals,
		total: add(total, multiply(itemToUpdate.variation.discountedPrice || itemToUpdate.variation.price, quantityDiff)),
		discountTotal: discountDiff ? add(discountTotal, multiply(discountDiff, quantityDiff)) : discountTotal,
		orderLinesTotal: add(orderLinesTotal, multiply(itemToUpdate.unitPrice, quantityDiff)),
	};

	mutation.quantitySum = mutation.quantitySum + quantityDiff;

	return mutation;
};

export const generateUpdateMutation = (prev: IBasket, variation: IVariationDetails, quantity: number) => {
	if (!prev) {
		return prev;
	}

	const mutation: IBasket = {
		...prev,
		lineItems: [...prev.lineItems],
	};

	const itemIndex = findLineItemIndex(mutation.lineItems, variation.sku);
	const itemToUpdate = mutation.lineItems[itemIndex];

	if (!mutation.lineItems[itemIndex] || itemToUpdate.quantity === quantity) {
		// item to update wasn't found in the basket or quantity didn't change,
		// so we return the mutation unmodified
		return mutation;
	}

	const quantityDiff = quantity - itemToUpdate.quantity;
	const discountDiff = itemToUpdate.variation.discountedPrice && subtract(itemToUpdate.variation.price, itemToUpdate.variation.discountedPrice);

	if (quantity === 0) {
		// new quantity was 0, so we remove the line item
		mutation.lineItems = mutation.lineItems.filter((lineItem) => lineItem.variation.sku !== variation.sku);

		generateTotals({
			mutation,
			itemToUpdate,
			quantityDiff,
			discountDiff,
		});

		return mutation;
	}

	const isMoreThanOne = quantity > 1;
	const updatedLinePrice = multiply(itemToUpdate.unitPrice, quantity);
	const updatedDiscount = discountDiff && multiply(discountDiff, quantity);
	const updatedDiscountedLinePrice = itemToUpdate.variation.discountedPrice && multiply(itemToUpdate.variation.discountedPrice, quantity);

	mutation.lineItems[itemIndex] = {
		...itemToUpdate,
		linePrice: updatedLinePrice,
		discountedLinePrice: updatedDiscountedLinePrice,
		discount: updatedDiscount,
		sustainablePackagingEnabled: !isMoreThanOne && itemToUpdate.sustainablePackagingEnabled,
		sustainablePackagingSelected: !isMoreThanOne && itemToUpdate.sustainablePackagingSelected,
		quantity,
	};

	generateTotals({
		mutation,
		itemToUpdate,
		quantityDiff,
		discountDiff,
	});

	return mutation;
};
