import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';
import { StyledMainMenuNavigationLink } from './../MainMenuNavigationLink/MainMenuNavigationLink.styled';

const listPaddingDesktopVertical = themeBase.space[5];
const listPaddingDesktopHorizontal = themeBase.space[6];

const finalLevelGridColumnWidth = '150px';
const finalLevelGridColumnGap = themeBase.space[8];
const finalLevelPaddingAdditional = themeBase.space[7];

const leftHidden = 'calc((var(--navigation-pane-width) * -1) - 2px)'; // offset 2px for border right, ensuring out of screen
const openAnimation = keyframes({
	from: { left: leftHidden },
	to: { left: 0 },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - textTransform inside ifProp breaks TS rules
export const StyledNavigationLevel1Wrapper = styled.div<{ isOpen?: boolean; isLastLevel: boolean; hasMetaHeader: boolean }>(
	{
		paddingTop: themeBase.space[1],
		paddingBottom: themeBase.space[2],
		paddingLeft: themeBase.space[4],
		background: themeBase.colors.navigationBackground,

		[breakpoints.lg]: {
			position: 'absolute',
			top: 'calc(100% + 1px)', // offset 1px for border on top bar
			left: leftHidden,
			width: 'var(--navigation-pane-width)',
			height: `calc(100vh - ${themeBase.space.topMenuHeight})`,
			borderRight: `1px solid ${themeBase.colors.grey30}`,
			padding: `${listPaddingDesktopVertical} ${listPaddingDesktopHorizontal}`,
			background: themeBase.colors.grey10,
			transition: `left ease-out ${themeBase.animations.timingMedium}`,
		},
	},
	ifProp('isLastLevel', {
		[breakpoints.lg]: {
			left: `calc(-${finalLevelGridColumnWidth} * 2 - ${finalLevelGridColumnGap} - ${finalLevelPaddingAdditional} - ${listPaddingDesktopHorizontal} * 2 - 2px)`, // offset 2px for border right, ensuring out of screen
			width: 'auto',
			paddingRight: `calc(${listPaddingDesktopHorizontal} + ${finalLevelPaddingAdditional})`, // Adding additional padding to match finalLevel UI (NavigationLevel2.styled.ts)
		},
	}),
	ifProp('isOpen', {
		[breakpoints.lg]: {
			animation: `${openAnimation} ease-out ${themeBase.animations.timingMedium} forwards`,
		},
	}),
	ifProp('hasMetaHeader', {
		[breakpoints.lg]: {
			height: `calc(100vh - ${themeBase.space.topMenuHeight} - ${themeBase.space.mainNavigationTopOffset})`,
		}
	}),
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - textTransform inside ifProp breaks TS rules
export const StyledNavigationLevel1 = styled.ul<{ isLastLevel: boolean }>(
	{
		display: 'grid',
		gridTemplateColumns: '100%',
		gap: themeBase.space[2],

		[breakpoints.lg]: {
			display: 'flex',
			flexDirection: 'column',
			gap: themeBase.space[2],

			[`> li > ${StyledMainMenuNavigationLink}`]: {
				textTransform: 'uppercase',
			},
		},
	},
	ifProp('isLastLevel', {
		[breakpoints.lg]: {
			display: 'grid',
			gridTemplateColumns: `repeat(2, ${finalLevelGridColumnWidth})`,
			gridAutoRows: 'max-content',
			gap: `${themeBase.space[2]} ${finalLevelGridColumnGap}`,

			// Parent link aka. "See all"
			'> li:first-of-type': {
				order: '1',
				gridColumn: '1',
				marginTop: themeBase.space[5],
			},
		},
	}),
);
