import HeartIcon from '~/icons/heart.svg';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden/VisuallyHidden';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { StyledWishlistButton } from './styled';

export type WishlistButtonProps = {
	active?: boolean;
	onClick: () => void;
	className?: string;
};

export const WishlistButton = (props: WishlistButtonProps) => {
	const { translate } = useTranslation();
	const label = props.active ? translate('wishlist.remove') : translate('wishlist.add');
	return (
		<StyledWishlistButton
			{...props}
			title={label}
		>
			<HeartIcon />
			<VisuallyHidden>{label}</VisuallyHidden>
		</StyledWishlistButton>
	);
};
