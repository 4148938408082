import { useBasket } from '~/features/basket/hooks/useBasket';
import { SearchDialogContent } from '~/features/search/components/SearchDialogContent/SearchDialogContent';
import { useSearchState } from '~/features/search/hooks/useSearchState';
import { useWishlist } from '~/features/wishlist/hooks/useWishlist';
import Bag from '~/icons/bag.svg';
import Heart from '~/icons/heart.svg';
import Pin from '~/icons/pin.svg';
import Search from '~/icons/search.svg';
import { Dialog as SearchDialog } from '~/shared/components/Dialog/Dialog';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden/VisuallyHidden';
import { useNavigation } from '~/shared/utils/navigation/hooks/useNavigation';
import { isBrowser } from '~/shared/utils/platform';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { StyledMainMenuHotLinks, StyledMainMenuHotLinksButton, StyledMainMenuHotLinksCounter, StyledMainMenuHotLinksLink, StyledMainMenuHotLinksSearchLabel } from './MainMenuHotLinks.styled';

const MainMenuHotLinks = () => {
	const { translate } = useTranslation();
	const {
		data: { header: { favorites, findStores, basket } = {} },
	} = useNavigation();
	const { data: { quantitySum: basketItemsCount = 0 } = {} } = useBasket();
	const { data: { items: itemsWishList = [] } = {} } = useWishlist();
	const {
		isDialogOpen: isSearchOpen,
		openDialog: openSearch,
		closeDialog: closeSearch,
	} = useSearchState(({ isDialogOpen, openDialog, closeDialog }) => ({
		isDialogOpen,
		openDialog,
		closeDialog,
	}));

	const openSearchDialog = () => {
		if (isBrowser) {
			window.document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
		}

		openSearch();
	};

	return (
		<StyledMainMenuHotLinks>
			<SearchDialog open={isSearchOpen}>
				<StyledMainMenuHotLinksButton onClick={openSearchDialog}>
					<Search />
					<StyledMainMenuHotLinksSearchLabel>{translate('search.menu-label')}</StyledMainMenuHotLinksSearchLabel>
				</StyledMainMenuHotLinksButton>
				<SearchDialogContent onCloseClick={closeSearch} />
			</SearchDialog>

			{favorites ? (
				<StyledMainMenuHotLinksLink
					href={favorites.url}
					title={favorites.title}
					hideOnMobile
				>
					<Heart />
					<VisuallyHidden>{favorites.text}</VisuallyHidden>
					<StyledMainMenuHotLinksCounter>{itemsWishList.length}</StyledMainMenuHotLinksCounter>
				</StyledMainMenuHotLinksLink>
			) : null}

			{findStores ? (
				<StyledMainMenuHotLinksLink
					href={findStores.url}
					title={findStores.title}
					hideOnMobile
				>
					<Pin />
					<VisuallyHidden>{findStores.text}</VisuallyHidden>
				</StyledMainMenuHotLinksLink>
			) : null}

			{basket ? (
				<StyledMainMenuHotLinksLink
					href={basket.url}
					title={basket.title}
				>
					<Bag />
					<StyledMainMenuHotLinksCounter>{basketItemsCount}</StyledMainMenuHotLinksCounter>
					<VisuallyHidden>{basket.text}</VisuallyHidden>
				</StyledMainMenuHotLinksLink>
			) : null}
		</StyledMainMenuHotLinks>
	);
};

export default MainMenuHotLinks;
