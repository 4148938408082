import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { useBasket } from '../../hooks/useBasket';
import { useBasketUpdateDetails } from '../../hooks/useBasketUpdateDetails';
import { PaneContent } from './PaneContent';

export type Props = {
	textContent: string;
};

export const AddToBasketTextPane = ({ textContent }: Props) => {
	const { translate } = useTranslation();
	const { lastAddedItem, error, data, isMutating } = useBasket();

	const { lastAddedLine } = useBasketUpdateDetails({
		basket: data,
		lastAddedItem,
	});

	const isError = !isMutating && !lastAddedLine;

	if (isError || !lastAddedLine) {
		return <div>{error || translate('basket.add-item-error')}</div>;
	}

	return <PaneContent textContent={textContent} />;
};
