import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useKeyPressEvent } from 'react-use';
import { Dialog } from '~/shared/components/Dialog/Dialog';
import { DialogContent } from '~/shared/components/Dialog/DialogContent';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import UseBasketPaneState from './../../hooks/useBasketPaneState';
import { AddToBasketPane } from './../AddToBasketPane/AddToBasketPane';
import { AddToBasketTextPane } from './../AddToBasketTextPane/AddToBasketTextPane';

const BasketPane: FC = () => {
	const router = useRouter();
	const { isDialogOpen, contentType, openDialog, closeDialog } = UseBasketPaneState();
	const { translate } = useTranslation();

	const toggleDialog = () => {
		if (isDialogOpen) {
			closeDialog();

			return;
		}

		openDialog();
	};

	useKeyPressEvent('Escape', () => {
		if (isDialogOpen) {
			closeDialog();
		}
	});

	useEffect(() => {
		router.events.on('routeChangeStart', closeDialog);

		return () => {
			router.events.off('routeChangeStart', closeDialog);
		};
	}, []);

	return (
		<Dialog
			open={isDialogOpen}
			onOpenChange={toggleDialog}
		>
			<DialogContent>
				{contentType === 'manualOrder' ? (
					<AddToBasketTextPane textContent={translate('basket.xxl-delivery-info')} />
				) : contentType === 'preorder' ? (
					<AddToBasketTextPane textContent={translate('basket.preorder-delivery-info')} />
				) : (
					<AddToBasketPane />
				)}
			</DialogContent>
		</Dialog>
	);
};

export default BasketPane;
