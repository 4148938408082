import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export type StyledLabelProps = {
	hideOnMobile?: boolean;
	hideOnDesktop?: boolean;
};

const StyledLabel = styled.span<StyledLabelProps>(
	({ theme }) => ({
		display: 'block',
		fontSize: theme.fontSizes.xs,
		lineHeight: theme.lineHeights.compact,
		color: theme.colors.productCardLabel,
		marginTop: theme.space[1],

		[breakpoints.md]: {
			marginTop: theme.space[2],
		},
	}),
	ifProp('hideOnMobile', {
		display: 'none',

		[breakpoints.md]: {
			display: 'block',
		},
	}),
	ifProp('hideOnDesktop', {
		display: 'block',

		[breakpoints.md]: {
			display: 'none',
		},
	}),
);

export const Label = StyledLabel;
