import styled from '@emotion/styled';
import Link from '~/shared/components/Link/Link';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

export const StyledNavigationHotLinksWrapper = styled.div({
	display: 'grid',
	gridTemplateColumns: '100%',
	gap: themeBase.space[2],
	padding: themeBase.space[3],
	background: themeBase.colors.grey10,

	[breakpoints.lg]: {
		display: 'none',
	},
});

export const StyledNavigationHotLinksLink = styled(Link)({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	color: 'currentColor',
	gap: themeBase.space[1],
	textDecoration: 'none',
	textTransform: 'uppercase',
	fontSize: 12,

	svg: {
		width: 24,
		height: 24,
	},
	[breakpoints.lg]: {
		gap: themeBase.space[1],
	},
});
