import create from 'zustand';

type UseListName = {
	prevListId?: string;
	prevListName?: string;
	setPrevListId: (listId?: string) => void;
	setPrevListName: (listName?: string) => void;
};

export const useListState = create<UseListName>((set) => ({
	prevListId: undefined,
	prevListName: undefined,
	setPrevListId: (listId) => set({ prevListId: listId }),
	setPrevListName: (listName) => set({ prevListName: listName }),
}));
