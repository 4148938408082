import styled from '@emotion/styled';
import { AccordionTrigger } from '@radix-ui/react-accordion';
import { ifProp } from 'styled-tools';
import { SearchInputField } from '~/features/search/components/SearchInputField/SearchInputField';
import { breakpoints } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';
import { StyledMainMenuNavigationLink } from './MainMenuNavigationLink/MainMenuNavigationLink.styled';

export const StyledMainMenuNavigationWrapper = styled.div<{ isOpen: boolean; hasMetaHeader: boolean }>(
	{
		position: 'fixed',
		top: themeBase.space.topMenuHeight,
		left: 'calc(var(--navigation-pane-width) * -1)',
		zIndex: themeBase.zIndices.mainMenu,
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		width: 'var(--navigation-pane-width)',
		height: `calc(100% - ${themeBase.space.topMenuHeight})`,
		overflow: 'hidden',
		background: themeBase.colors.navigationBackground,
		transition: `left ease-out ${themeBase.animations.timingMedium}`,

		[breakpoints.lg]: {
			position: 'unset',
			height: 'unset',
			width: 'fit-content',
			marginLeft: themeBase.space[2],
		},

		'@media (min-width: 1400px)': {
			marginLeft: themeBase.space[6],
		},
	},
	ifProp('isOpen', {
		left: 0,
	}),
	ifProp('hasMetaHeader', {
		top: `calc(${themeBase.space.topMenuHeight} + ${themeBase.space.mainNavigationTopOffset})`,
		height: `calc(100% - ${themeBase.space.topMenuHeight} - ${themeBase.space.mainNavigationTopOffset})`,
	}),
);

export const StyledMainMenuAccordionTrigger = styled(AccordionTrigger)({
	// Button reset
	display: 'block',
	backgroundColor: 'transparent',
	border: 'none',
	width: '100%',
	cursor: 'pointer',
	textAlign: 'left',
	padding: '0',

	[breakpoints.lg]: {
		height: '100%',
	},
});

const targetLinkSelected = `ul:hover > li:hover > ${StyledMainMenuNavigationLink}`;
const targetAccordionSelected = `ul:hover > li:hover > ${StyledMainMenuAccordionTrigger} ${StyledMainMenuNavigationLink}`;
const targetAccordionActive = `li[data-state="open"] > ${StyledMainMenuAccordionTrigger} ${StyledMainMenuNavigationLink}`;

const siblingLinkSelected = `ul:hover > li:not(:hover) > ${StyledMainMenuNavigationLink}`;
const siblingAccordionSelected = `ul:hover > li:not(:hover) > ${StyledMainMenuAccordionTrigger} ${StyledMainMenuNavigationLink}`;

export const StyledMainMenuNavigation = styled.nav({
	overflow: 'auto',

	[`${targetLinkSelected}, ${targetAccordionSelected}, ${targetAccordionActive}`]: {
		// Important due to specificity war with isOpen rules from NavigationSecondary.styled
		// and side effects !important from MainMenuNavigationLink.styled
		// color: `${themeBase.colors.black} !important`,
		color: themeBase.colors.black,
		fontWeight: themeBase.fontWeights.bold,
	},

	[`${siblingLinkSelected}, ${siblingAccordionSelected}`]: {
		color: themeBase.colors.grey60,
	},
});

export const StyledSearchInputField = styled(SearchInputField)({
	padding: `${themeBase.space[1]} ${themeBase.space[3]}`,
	marginBottom: themeBase.space[2],

	[breakpoints.lg]: {
		display: 'none',
	},
});
