import getStockStateTranslationKey from '~/features/products/utils/stockState/getStockStateTranslationKey';
import { StockState } from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { Button, ButtonProps } from '../Button/Button';

export type AddToBasketProps = ButtonProps & {
	title?: string;
	isStockAvailable: boolean;
	stockState: StockState;
	isManualOrder: boolean;
	amount?: number;
	onConfirm: (amount: number) => void;
};

/**
 * Use this to show a button with an action area, to trigger a dropdown menu
 * At the moment it's only used for adding to basket. Could easily be extended to other actions
 */
export const AddToBasket = ({ title, disabled = false, isStockAvailable, stockState, isManualOrder, onConfirm, amount = 1, ...buttonProps }: AddToBasketProps) => {
	const { translate } = useTranslation();

	const isInPreorder = stockState == 'preorder';
	const isPurchasable = isStockAvailable || isInPreorder;

	const getTitle = () => {
		if (!isPurchasable) {
			return translate(getStockStateTranslationKey(stockState));
		}

		if (isInPreorder) {
			return translate('product.stock.preorder');
		}

		if (isManualOrder) {
			return translate('basket.custom-add-to-basket');
		}

		return translate('basket.add-to-basket');
	};

	return (
		<Button
			onClick={isPurchasable ? () => onConfirm(amount) : undefined}
			textAlignment={isPurchasable ? 'left' : 'center'}
			disabled={!isPurchasable || disabled}
			{...buttonProps}
		>
			{title ?? getTitle()}
		</Button>
	);
};
