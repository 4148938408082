import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { switchProp } from 'styled-tools';
import { Severity } from '~/shared/hooks/useNotification/useNotificationModel';

export const StyledList = styled(motion.ul)(({ theme }) => ({
	position: 'fixed',
	bottom: `calc(${theme.space[10]} + ${theme.space[3]})`,
	top: 0,
	right: theme.space[5],
	width: '300px',
	margin: 'auto',
	display: 'flex',
	flexDirection: 'column-reverse',
	alignItems: 'end',
	gap: theme.space[1],
	zIndex: theme.zIndices.notifications,
	pointerEvents: 'none',
}));

export const StyledNotification = styled(motion.div)<{ variant: Severity }>(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		background: theme.colors.black,
		color: theme.colors.white,
		fontSize: theme.fontSizes['xs'],
		zIndex: 100,
		overflow: 'hidden',
		pointerEvents: 'auto',
		cursor: 'pointer',
	}),
	switchProp('variant', {
		error: ({ theme }) => ({
			backgroundColor: theme.colors.negative,
		}),
		warning: ({ theme }) => ({
			backgroundColor: theme.colors.black,
		}),
		info: ({ theme }) => ({
			backgroundColor: theme.colors.black,
		}),
		success: ({ theme }) => ({
			backgroundColor: theme.colors.positive,
		}),
	}),
);

export const StyleContent = styled.div(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: `${theme.space[2]} 0 ${theme.space[2]} ${theme.space[4]}`,
	...theme.textStyles.bodySm,
}));

export const StyledCloseButton = styled.button(({ theme: { colors, space, fontSizes } }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginLeft: 'auto',
	background: 'none',
	border: 'none',
	color: colors.white,
	padding: space[4],
	cursor: 'pointer',
	maxWidth: 580,
	fontSize: fontSizes.xs,
}));
