import { Close, DialogContentProps as RadixDialogContentProps } from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import CloseIcon from '~/icons/close.svg';
import { Icon } from '../Icon/Icon';
import { StyledCloseButton, StyledDialogContent, StyledDialogPane } from './styled';

export type DialogContentProps = Omit<RadixDialogContentProps, 'asChild'> & {
	onCloseClick?: () => void;
	side?: 'left' | 'right';
};

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(({ children, onCloseClick, side = 'right', ...props }, forwardedRef) => {
	const handleOnOpenAutoFocus = (event: Event) => {
		if (props.onOpenAutoFocus) {
			props.onOpenAutoFocus(event);
		} else {
			event.preventDefault();
		}
	};

	return (
		<StyledDialogContent
			{...props}
			onOpenAutoFocus={handleOnOpenAutoFocus}
			ref={forwardedRef}
			side={side}
		>
			<Close asChild>
				<div
					onClick={onCloseClick}
					aria-hidden
				></div>
			</Close>
			<StyledDialogPane side={side}>
				{children}
				<Close asChild>
					<StyledCloseButton onClick={onCloseClick}>
						<Icon size="lg">
							<CloseIcon />
						</Icon>
					</StyledCloseButton>
				</Close>
			</StyledDialogPane>
		</StyledDialogContent>
	);
});
