import { useContext } from 'react';
import { RouteTransitionContext } from '../context/RouteTransitionProvider';

/**
 * Indicates if a new route is loading.
 * Ex: true right after clicking a link, false right after the SSR response
 */
export const useRouteTransition = () => {
	return useContext(RouteTransitionContext);
};
