import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { DehydratedState } from 'react-query/hydration';
import { useBasket } from '~/features/basket/hooks/useBasket';
import { UpsertItemMutationArgs } from '~/features/basket/models/basketModel';
import getAbandonedBasket from '~/features/basket/service/getAbandonedBasket';
import { PRODUCT_URL } from '~/features/products/services/endpoints';
import { useRaptorPageView } from '~/features/raptor/hooks/useRaptorPageView';
import { useAddOrRemoveProductTracking } from '~/features/tracking/hooks/useAddOrRemoveProductTracking';
import { useGenericTracking } from '~/features/tracking/hooks/useGenericTracking';
import { useVirtualPageViewTracking } from '~/features/tracking/hooks/useVirtualPageViewTracking';
import { IFrame, IPages, IVariationDetails } from '~/lib/data-contract';
import { Meta } from '~/shared/components/Meta/Meta';
import MetaBackground from '~/shared/components/Meta/MetaBackground';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { fetcher } from '~/shared/utils/request/utils/fetcher';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { Translations } from '~/shared/utils/translation/model/translationModel';
import { getDehydratedState } from '../../utils/getDehydratedState';
import { getIsInitialLoad } from '../../utils/getIsInitialLoad';
import { pageResolver } from '../../utils/pageResolver';
import { Layout } from '../Layout';
import { LazyPageComponent } from './components/LazyPageComponent';

type DynamicPageData = {
	page: IPages;
	translations?: Translations;
	frame?: IFrame;
	dehydratedState: DehydratedState | null;
};

export type DynamicPageProps = {
	hasError: boolean;
	data: DynamicPageData;
};

/**
 * Hooks that must run in the first load / hard refresh
 */
const useInitialization = () => {
	useTranslation();
};

export const DynamicPage = (props: DynamicPageProps) => {
	const router = useRouter();
	const { page } = props.data;
	const { metaTitle, metaTags } = page;
	const isCheckout = page.type === 'p100CheckoutPage';
	useInitialization();

	const { data: basket, setBasket } = useBasket();

	// tracking
	useGenericTracking();
	useVirtualPageViewTracking();
	useRaptorPageView();

	const trackCartItems = useAddOrRemoveProductTracking();
	const isSkippingInitialCartItemsTracking = useRef(false);

	let abandonedBasketAbortController: AbortController | undefined;
	useEffect(() => {
		if (router.query.basketReference) {
			abandonedBasketAbortController = new AbortController();
			const basketId = router.query.basketReference as string;

			getAbandonedBasket(basketId, abandonedBasketAbortController.signal)
				.then((response) => {
					if (!response?.items?.length) {
						return;
					}

					return Promise.all(
						response.items.map((item) => {
							const requestUrl = buildURL(PRODUCT_URL.replace('{{sku}}', item.variation), {
								culture: item.culture,
								market: item.market,
							});

							return fetcher<IVariationDetails>(requestUrl)();
						}),
					).then((variations) => {
						const mutationItems = response.items.reduce((acc, { variation: sku, quantity }) => {
							const variation = variations.filter((variation) => variation.sku === sku)[0];
							if (variation) {
								acc.push({
									variation,
									quantity,
								});
							}

							return acc;
						}, [] as UpsertItemMutationArgs[]);

						return setBasket({ items: mutationItems });
					});
				})
				.finally(() => {
					const newQuery = { ...router.query };
					delete newQuery.basketReference;
					return router.replace(
						{
							pathname: router.pathname,
							query: newQuery,
						},
						undefined,
						{ shallow: true },
					);
				});
		}

		return () => {
			abandonedBasketAbortController?.abort();
		};
	}, []);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		document.documentElement.setAttribute('lang', page.culture);
	}, [page.culture]);

	// this hook is responsible for firing the initial cart_items event
	useEffect(() => {
		if (!basket) {
			// basket is not available yet
			return;
		}

		if (basket.id === '') {
			// basket is empty/a placeholder, so we skip the initial tracking
			isSkippingInitialCartItemsTracking.current = true;

			return;
		}

		if (!isSkippingInitialCartItemsTracking.current) {
			// basket was not a placeholder, so we do the initial tracking
			trackCartItems(basket);
		}
	}, [basket?.id]);

	return (
		<Layout
			title={metaTitle}
			isCheckout={isCheckout}
			page={page}
		>
			<Meta
				pageId={page.id}
				tags={metaTags}
			/>
			<MetaBackground pageId={page.id} />
			<LazyPageComponent
				key={page.id + page.culture + page.market}
				page={page}
			/>
		</Layout>
	);
};

/**
 * Fetch the page and the frame and return as props
 *
 * CacheControl is set based on the response header from the page request
 */
export const getDynamicPageProps: GetServerSideProps<Partial<DynamicPageProps>> = async (context) => {
	const headersFromCMS = ['cache-control', 'etag'];

	const { res } = context;

	try {
		const isInitialLoad = getIsInitialLoad(context);

		const { data: page, headers } = await pageResolver(context);

		// Map headers from page response.
		// Allows the CMS to handle how pages are cached etc.
		headersFromCMS.forEach((headerKey) => {
			const header = headers.get(headerKey);
			header && res.setHeader(headerKey, header);
		});

		if (page.type === 'redirect') {
			const { destination, permanent } = page;
			return { redirect: { destination, permanent } };
		}

		const dehydratedState = isInitialLoad ? await getDehydratedState(page, context) : null;

		const props: DynamicPageProps = {
			hasError: false,
			data: {
				page,
				dehydratedState,
			},
		};

		if (page.statusCode !== 200) {
			res.statusCode = page.statusCode;
		}

		return {
			props,
		};
	} catch {
		res.statusCode = 500;

		return {
			props: {
				hasError: true,
			},
		};
	}
};
