import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { AltSelectorOption } from '~/shared/components/FormElements/components/AltSelector/AltSelector';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { BASE_URL_REGEX } from '~/shared/utils/request/utils/buildURL';
import { isUrl } from '~/shared/utils/url/isUrl';

export const useMarketSelectorControl = () => {
	const { data: frame } = useFrame();
	const { market: defaultMarket, culture: defaultCulture } = useMarket();
	const router = useRouter();
	const markets = frame.marketSelector?.markets ?? [];

	const marketOptions = useMemo(() => {
		return markets.map((market) => ({
			label: market.label,
			value: market.id,
			icon: market.image,
		}));
	}, [markets]);
	const [selectedMarketOption, setSelectedMarketOption] = useState<AltSelectorOption | null>(marketOptions.find(({ value }) => value === defaultMarket)!);

	const localeOptions = useMemo(() => {
		return (
			markets
				.find(({ id }) => id === selectedMarketOption?.value)
				?.languages
				.map((language) => ({
					label: language.text,
					value: language.culture,
				})) || []
		);
	}, [selectedMarketOption?.value]);
	const [selectedLocaleOption, setSelectedLocaleOption] = useState<AltSelectorOption | null>(localeOptions.find(({ value }) => value === defaultCulture)!);

	useUpdateEffect(() => {
		const currentSelectedLocale = selectedLocaleOption?.value;
		const preferred = localeOptions.find(({ value }) => value === currentSelectedLocale);
		setSelectedLocaleOption(preferred || localeOptions[0]);
	}, [localeOptions]);

	const localeUrlQuery = markets.find(({ id }) => id === selectedMarketOption?.value)?.languages.find(({ culture }) => culture === selectedLocaleOption?.value)?.url;

	const baseUrl = useMemo(() => {
		return router.asPath.replace(BASE_URL_REGEX, '');
	}, [router.asPath]);

	const selectedMarketCultureUrl = useMemo(() => {
		if (isUrl(localeUrlQuery)) {
			return localeUrlQuery as string;
		}

		return `${baseUrl}${localeUrlQuery}`;
	}, [baseUrl, localeUrlQuery]);

	const isSameMarketAndCulture = selectedLocaleOption?.value === defaultCulture && selectedMarketOption?.value === defaultMarket;

	return {
		marketOptions,
		selectedMarketOption,
		onMarketOptionChange: setSelectedMarketOption,
		localeOptions,
		selectedLocaleOption,
		onLocaleOptionChange: setSelectedLocaleOption,
		isSameMarketAndCulture,
		selectedMarketCultureUrl,
		currentMarketId: frame.market?.id,
	};
};
