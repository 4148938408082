import { QueryClient } from 'react-query';
import { requestDefaults } from '~/shared/utils/request/utils/requestDefaults';

export const ssrQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: requestDefaults.cacheTime,
			cacheTime: 3600000, // 1 hour for serving stale data and re-fetching in the background
		},
	},
});
