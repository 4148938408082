import { useMemo } from 'react';
import { StockState } from '../../../../lib/data-contract';
import { useStockStatus } from '../../hooks/useStockStatus';
import getStockStateString from './getStockStateString';

export type StockStatusDetermined = {
	stockAvailable: boolean;
	stockState: StockState;
	availabilityDate?: Date;
};

const getStockStatusDetermined = (sku: string, stockAvailable: boolean, stockState?: number, shouldTrack?: boolean) => {
	const { data: stockStatus } = useStockStatus(sku, shouldTrack);

	return useMemo<StockStatusDetermined>(() => {
		const stockStateString = stockStatus?.stockState || getStockStateString(stockAvailable, stockState);
		const availabilityDate = stockStatus?.availabilityDate ? new Date(stockStatus.availabilityDate) : undefined;

		return {
			stockAvailable: stockStateString === 'available',
			stockState: stockStateString,
			availabilityDate,
		};
	}, [stockStatus?.stockState, stockAvailable, stockState]);
};

export default getStockStatusDetermined;
