import { useTheme } from '@emotion/react';
import { FC } from 'react';
import { IMetaMenuItem } from '../../../../lib/data-contract';
import { MetaHeaderItems } from './components/MetaHeaderItems/MetaHeaderItems';
import { StyledWrapper } from './styled';

export interface IN21MetaHeaderProps {
	items: IMetaMenuItem[];
	moduleBackgroundColor?: string;
}

const colorIsDark = (color: string) => {
	return parseInt(color.slice(3, 5), 16) < 127 && parseInt(color.slice(5, 7), 16) < 127;
};

export const N21MetaHeader: FC<IN21MetaHeaderProps> = ({ items, moduleBackgroundColor }) => {
	const { colors: themeColors } = useTheme();

	const backgroundColor = moduleBackgroundColor ?? themeColors.metaMenuDefaultBackgroundColor;
	const textColor = colorIsDark(backgroundColor) ? themeColors.bodyTextLight : themeColors.bodyText;

	return (
		<StyledWrapper
			moduleBackgroundColor={backgroundColor}
			textColor={textColor}
		>
			<MetaHeaderItems items={items} />
		</StyledWrapper>
	);
};
