import { fetcher } from './fetcher';

/**
 * Simpler version of a PUT request, supplies content type and serializes body to json
 */
export const putRequest = <ResponseBody = unknown, RequestBody = unknown>(url: string, body?: RequestBody, abortSignal?: AbortSignal) => {
	const args: Parameters<typeof fetcher>[1] = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json; charset=utf-8' },
		signal: abortSignal,
	};

	if (body) {
		args.body = JSON.stringify(body);
	}

	return fetcher<ResponseBody>(url, args);
};
