import ICampaignTimeframe from '~/shared/components/CampaignTimeframe/CampaignTimeframe.def';
import { StyledCampaignTimeframe } from '~/shared/components/CampaignTimeframe/CampaignTimeframe.styled';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';

const CampaignTimeframe = ({ timeFrame }: { timeFrame?: ICampaignTimeframe }) => {
	const { translate } = useTranslation();
	const { market, culture } = useMarket();

	const currentYear = new Date().getFullYear();
	const locale =`${culture}-${market}`;

	const getLocaleDateString = (dateString: string): string =>{
		const date = new Date(dateString);
		const showYear = date.getFullYear() !== currentYear;

		return date.toLocaleDateString(locale, {
			year: showYear ? '2-digit' : undefined,
			month: 'numeric',
			day: 'numeric',
		});
	}

	return (
		<StyledCampaignTimeframe>
			{timeFrame ? (
				translate('product.campaign-timeframe.date', {
					startDate: timeFrame.fromDateString ? getLocaleDateString(timeFrame.fromDateString) : '',
					endDate: timeFrame.untilDateString ? getLocaleDateString(timeFrame.untilDateString) : '',
				})
			) : translate('product.campaign-timeframe.until-sold-out')}
		</StyledCampaignTimeframe>
	)
};

export default CampaignTimeframe;
