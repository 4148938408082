import { AnimatePresence } from 'framer-motion';
import { StyledList } from '~/shared/components/Notifications/styled';
import { useNotification } from '~/shared/hooks/useNotification/useNotification';
import { Notification } from './components/Notification';

/**
 * Displays UI based on notification state
 * can be used in root or a scoped location
 *
 */
export const Notifications = () => {
	const { notifications, dismiss } = useNotification();

	if (!notifications.length) {
		return null;
	}

	return (
		<StyledList aria-live="polite">
			<AnimatePresence>
				{notifications.map((props) => (
					<li key={props.id}>
						<Notification
							onDismiss={() => dismiss(props.id)}
							{...props}
						/>
					</li>
				))}
			</AnimatePresence>
		</StyledList>
	);
};
