import { IWishlist } from '~/lib/data-contract';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { postRequest } from '~/shared/utils/request/utils/postRequest';
import { WISHLIST_URL } from '../constants';
import { WishlistMarketOptions } from '../model';

export const addToWishlist = async (sku: string, opts: WishlistMarketOptions) => {
	const request = postRequest<IWishlist>(
		buildURL(WISHLIST_URL, {
			...opts,
			sku,
		}),
	);

	const response = await request();

	if (!response) {
		throw new Error();
	}

	return response;
};
