import { RAPTOR_ITEM_CLICK_ENDPOINT, RAPTOR_PAGE_VIEW_ENDPOINT, RAPTOR_SEARCH_ENDPOINT } from '~/features/raptor/constants';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { fetcher } from '~/shared/utils/request/utils/fetcher';

type TrackRaptorGenericOpts = {
	market: string;
	culture: string;
};

type TrackRaptorItemClickOpts = TrackRaptorGenericOpts & {
	sku: string;
	raptorModule: string;
	pageId: string;
};

export const trackRaptorItemClick = ({ sku, raptorModule, pageId, market, culture }: TrackRaptorItemClickOpts) => {
	return fetcher(
		buildURL(RAPTOR_ITEM_CLICK_ENDPOINT, {
			sku,
			market,
			culture,
			module: raptorModule,
			page: pageId,
		}),
	)();
};

type TrackRaptorSearchOpts = TrackRaptorGenericOpts & {
	query: string;
};

export const trackRaptorSearch = ({ query, culture, market }: TrackRaptorSearchOpts) => {
	return fetcher(
		buildURL(RAPTOR_SEARCH_ENDPOINT, {
			culture,
			market,
			q: query,
		}),
	)();
};

type TrackRaptorPageViewOpts = TrackRaptorGenericOpts & {
	page: string;
	contentIds: string[];
};

export const trackRaptorPageView = async ({ contentIds, ...rest }: TrackRaptorPageViewOpts) => {
	try {
		const url = buildURL(RAPTOR_PAGE_VIEW_ENDPOINT, {
			...rest,
			contentIds: contentIds.join(','),
		});

		const request = fetcher(url);
		await request();
	} catch (e: any) {
		// TODO: handle failed Raptor responses
		console.error(e.message);
	}
};
