import { memo } from 'react';
import { INavigationList } from './../MainMenuNavigation.def';
import { StyledNavigationLevel3 } from './NavigationLevel3.styled';
import NavigationLevel3Item from './NavigationLevel3Item/NavigationLevel3Item';

const NavigationLevel3 = memo(({ nodes, trackingHierarchy }: Pick<INavigationList, 'nodes' | 'trackingHierarchy'>) => {
	return (
		<StyledNavigationLevel3>
			{nodes.map(({ id, link }) => (
				<NavigationLevel3Item
					key={id}
					link={link}
					trackingHierarchy={trackingHierarchy}
				/>
			))}
		</StyledNavigationLevel3>
	);
});

export default NavigationLevel3;
